import React from 'react';
import styles from './InfoTableItem.module.css';
export default class InfoTableItem extends React.Component {

    render() {
        return(
            <>
            {this.props.Value &&   
                <tr className={styles.InfoRow}>
                   <td>{this.props.Label}</td>
                   <td className={styles.InfoRowRight}>{this.props.Value}</td>
               </tr>}
            </>
        );
    }
  }