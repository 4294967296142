import React from 'react';
import styles from './Header.module.css';

export default class Header extends React.Component {
    render() {
      return (
        <div className={styles.header} style={{backgroundColor:this.props.Background}}>
          
            {!this.props.IsWhiteLabel && <img alt="Vopay Logo Header" className={styles.headerLogo} src="/images/vopay-logo-white.svg" />}
            {this.props.IsWhiteLabel && !this.props.Image && <div className={styles.headerTextCompanyName}>{this.props.CompanyName}</div>}
            {this.props.IsWhiteLabel && this.props.Image && <div><img alt="Logo" className={styles.headerLogo} src={`data:image/png;base64,${this.props.Image}`} /></div>}
        </div>
      );
    }
  } 