import React from 'react';
import styles from './Row.module.css';
export default class Row extends React.Component {
    render() {
        const {items} = this.props;
        return (
            <div className={styles.sideBySideLayout}>
              {items.map((item, index) => (
                <div
                  key={index}
                  className={styles.sideBySideLayoutItem}
                >
                  {item}
                </div>
              ))}
            </div>
          );
    }
  }