import React from 'react';
import { withTranslation } from 'react-i18next';
import Button from './../../components/Button/Button';
class OverviewContainer extends React.Component {
    render() {
      const { t } = this.props;

      return (
        <div>
          <h1 className="title">{t('overviewContainer.overview')}</h1>
          <p className="description">{t('overviewContainer.greeting', { name: this.props.Name })}</p>
          <p className="description">{t('overviewContainer.information')}</p>
          <p className="description">{t('overviewContainer.summary')}</p>
          <ul className="list">
            <li>{t('overviewContainer.details')}</li>
            <li>{t('overviewContainer.structure')}</li>
            <li>{t('overviewContainer.authorities')}</li>
            <li>{t('overviewContainer.documentation')}</li>
          </ul>
          <p className="description">{t('overviewContainer.contact')}</p>
          <p className="description">{t('overviewContainer.startApplication')}</p>
          <div className="buttonBar buttonBar--inline">
            {this.props.IsParentAccount && <Button title={t('overviewContainer.startButton')} backgroundColor={this.props.PrimaryColor} width="Button100" onClickHandler={this.props.nextHandler} />}
            {!this.props.IsParentAccount && <Button title={t('overviewContainer.startButton')} backgroundColor={this.props.PrimaryColor} width="Button100" onClickHandler={this.props.nextHandler} />}
          </div>
        </div>
      );
    }
  }
  
const TranslatedClassComponent = withTranslation()(OverviewContainer);
export default TranslatedClassComponent;