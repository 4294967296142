import React from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput, {isPossiblePhoneNumber}  from 'react-phone-number-input'
import styles from './PhoneNumberInput.module.css';
import $ from 'jquery';
export default class PhoneNumberInput extends React.Component {
    constructor(props) {
        super(props);
       const value =this.phoneNumberGetValid(this.props.value || "");
        this.state ={
            value:this.props.value ?  value : "",
            valueHidden:this.props.value ? this.props.value.replace(/[^\d]/g, "") : ""
        }
        this.PhoneHiddenInput = React.createRef();
    }
    setValue= (value) => {
        if(value){
            this.setState({
                value: value,
                valueHidden: value.replace(/[^\d]/g, "")
            })
        }
        if(this.props.HandleChange){
            this.props.HandleChange({
                target:{
                    name: this.props.name,
                    value: value ? value.replace(/[^\d]/g, ""): ""
                }
            })
        }
    }
    componentWillReceiveProps= (nextProps) => {
        if((this.props.value !== "" && this.state.valueHidden === "") || nextProps.value ===""){
            const value =this.phoneNumberGetValid(this.props.value || "");
            this.setState({
                value:this.props.value ?  value : "",
                valueHidden:this.props.value ? this.props.value.replace(/[^\d]/g, "") : ""
            })
        }
    }
    phoneNumberGetValid(value){
        if(isPossiblePhoneNumber(value) ){
            return value;
        }
        else if(isPossiblePhoneNumber("+"+value)){
            return "+"+value;
        }
        else if(isPossiblePhoneNumber("+1"+value)){
            return "+1"+value;
        }
        return value;
    }
    componentDidMount(){
        const ref = this.PhoneHiddenInput.current;
        
        $(ref).parsley().on('field:error', function() {
            $(ref).parent().addClass("input--errorPhone");
        })
        $(ref).on("focus",function() {
            
            $(ref).parent().find(".PhoneInputInput")[0].focus();
        });
    }
    render() {
        var stylesNew = ""
        if(this.props.disabled){
            stylesNew = `disabled`;
        }
            return (
                
                <div className={stylesNew}>
                    <PhoneInput
                    defaultCountry="CA"
                    value={this.state.value}
                    onChange={this.setValue} 
                    id={this.props.id}
                    placeholder="Enter phone number"
                    international

                    />
                    <label for={this.props.id+"hidden"} style={{opacity:0, position:"absolute"}}>{this.props.name}</label>
                    <input id={this.props.id+"hidden"} type="text"  ref={this.PhoneHiddenInput} data-parsley-validphonemumber="true"  className={styles.HiddenInput} disabled={this.props.disabled} required={this.props.required}  name={this.props.name} value={this.state.valueHidden} onChange={()=>{}} />
                </div>
            );
        
        
    }
    

}