import React from 'react';
import Button from './../../components/Button/Button';
export default class OrderFormOverviewContainer extends React.Component {
    render() {
        return (
            <div>
                <h1 className="title">Order Form Overview</h1>
                <p className="description">Hello {this.props.Name},</p>
                <p className="description">Thank you for choosing VoPay as your trusted payment services provider. As part of the final stage of your application, we invite you to review the Order Form which includes the following:
                </p>
                <ul className="list">
                    <li>Your selected payment services</li>
                    <li>The pricing for each service</li>
                    <li>The agreed transaction limits</li>
                    <li>Account activation fees</li>
                </ul>
                <p className="description">Please take a moment to review the pricing details and Terms of Service. Once we receive the digitally signed Agreement from you, we will proceed to activate your account.</p>
                <div className="buttonBar buttonBar--inline">
                    <Button title="Review Order Form"  width="Button100" onClickHandler={this.props.nextHandler}/>
                </div>
                <p className="description">All the information provided is stored on a secure server and will never be shared with any third-party service.</p>
                <p className="description">If you have questions or need assistance please contact your account representative.</p>
                 <p className="description">Best Regards,</p>
                <p className="description">VoPay Team</p>
            </div>
        );
        
    }
}