import React from 'react';
import Button from './../../components/Button/Button';
import Input from './../../components/Input/Input';
import Textarea from './../../components/Textarea/Textarea';
import RadioButton from '../../components/RadioButton/RadioButton';
import FormElement from '../../components/FormElement/FormElement';
import { withTranslation } from 'react-i18next';
import { FormContext } from '../../context/FormContext';
import Row from '../../components/Row/Row';

class DonationsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AcceptDonations: false,
      AcceptDonationsCash: false,
    };
  }

  setAcceptDonation(AcceptDonations) {
    this.context.UpdateDonarInformation({ AcceptDonations: AcceptDonations });
  }

  setAcceptDonationsCash(AcceptDonationsCash) {
    this.context.UpdateDonarInformation({ AcceptDonationsCash: AcceptDonationsCash });
  }

  ContextChanged = (e) => {
    this.context.UpdateDonarInformation({ [e.target.name]: e.target.value });
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <h1 className="title">{t('donationsContainer.title')}</h1>
        <FormElement
          label={t('donationsContainer.acceptDonations')}
          feild={(
            <>
              <RadioButton
                checked={this.context.DonarInformation.AcceptDonations}
                id="AcceptDonationsYes"
                name="AcceptDonations"
                data-parsley-errors-container="#parsley-id-multiple-AcceptDonations-container"
                onChange={() => this.setAcceptDonation(true)}
                value={true}
                label={t('donationsContainer.yes')}
                required={true}
              />
              <RadioButton
                checked={!this.context.DonarInformation.AcceptDonations}
                id="AcceptDonationsNo"
                name="AcceptDonations"
                data-parsley-errors-container="#parsley-id-multiple-AcceptDonations-container"
                onChange={() => this.setAcceptDonation(false)}
                value={false}
                label={t('donationsContainer.no')}
                required={true}
              />
              <p id="parsley-id-multiple-AcceptDonations-container" className="filled"></p>
            </>
          )}
          visible={true}
        />
        <Row
          items={[
            <FormElement
              label={t('donationsContainer.nonCashDonationLimit')}
              for="DonationLimitNonCash"
              feild={(
                <Input
                  name="DonationLimitNonCash"
                  id="DonationLimitNonCash"
                  type="number"
                  min={1}
                  required={true}
                  value={this.context.DonarInformation.DonationLimitNonCash}
                />
              )}
              visible={this.context.DonarInformation.AcceptDonations}
            />,
            <></>
          ]}
        />
        <FormElement
          label={t('donationsContainer.acceptCashDonations')}
          feild={(
            <>
              <RadioButton
                checked={this.context.DonarInformation.AcceptDonationsCash}
                id="AcceptDonationsCashYes"
                name="AcceptDonationsCash"
                data-parsley-errors-container="#parsley-id-multiple-AcceptDonationsCash-container"
                onChange={() => this.setAcceptDonationsCash(true)}
                value={true}
                label={t('donationsContainer.yes')}
                required={true}
              />
              <RadioButton
                checked={!this.context.DonarInformation.AcceptDonationsCash}
                id="AcceptDonationsCashNo"
                name="AcceptDonationsCash"
                data-parsley-errors-container="#parsley-id-multiple-AcceptDonationsCash-container"
                onChange={() => this.setAcceptDonationsCash(false)}
                value={false}
                label={t('donationsContainer.no')}
                required={true}
              />
              <p id="parsley-id-multiple-AcceptDonationsCash-container" className="filled"></p>
            </>
          )}
          visible={this.context.DonarInformation.AcceptDonations}
        />
        <Row
          items={[
            <FormElement
              label={t('donationsContainer.cashDonationLimit')}
              for="DonationLimitCash"
              feild={(
                <Input
                  name="DonationLimitCash"
                  id="DonationLimitCash"
                  type="number"
                  min={1}
                  required={true}
                  value={this.context.DonarInformation.DonationLimitCash}
                />
              )}
              visible={this.context.DonarInformation.AcceptDonations && this.context.DonarInformation.AcceptDonationsCash}
            />,
            <FormElement
              label={t('donationsContainer.cashDonationPercentage')}
              for="DonationPercentageCash"
              feild={(
                <Input
                  name="DonationPercentageCash"
                  id="DonationPercentageCash"
                  type="number"
                  min={1}
                  max={100}
                  required={true}
                  value={this.context.DonarInformation.DonationPercentageCash ? this.context.DonarInformation.DonationPercentageCash * 100 : ""}
                />
              )}
              visible={this.context.DonarInformation.AcceptDonations && this.context.DonarInformation.AcceptDonationsCash}
            />
          ]}
        />
        <FormElement
          label={t('donationsContainer.cashDonationProcess')}
          for="DonationCashProcess"
          feild={(
            <Textarea
              required={true}
              id="DonationCashProcess"
              name="DonationCashProcess"
              maxLength="100"
              value={this.context.DonarInformation.DonationCashProcess}
            />
          )}
          visible={this.context.DonarInformation.AcceptDonations && this.context.DonarInformation.AcceptDonationsCash}
        />
        <div className="buttonBar">
          <Button title={t('donationsContainer.back')} width="50Left" backgroundColor={this.props.PrimaryColor} onClickHandler={this.props.prevHandler} />
          <Button title={t('donationsContainer.next')} width="50Right" backgroundColor={this.props.PrimaryColor} onClickHandler={this.props.nextHandler} />
        </div>
      </>
    );
  }
}

DonationsContainer.contextType = FormContext;

export default withTranslation()(DonationsContainer);
