import React from 'react';
import $, { data } from 'jquery';
/* eslint-disable no-unused-vars */
import parsley from 'parsleyjs';
/* eslint-disable no-unused-vars */
import ReactModal from 'react-modal';
import { withTranslation } from 'react-i18next';
import { formObject, convertBase64, title } from "./../../service/tools";
import {
    submitExtendedInfo, submitShareholderInfo, submitSigningAuthoritesInfo,
    submitFiles, generateEmbedUrl, getTokenInfo, getSigningAuthoritesInfo, getShareholderInfo, deleteSigningAuthoritesInfo, getExtendedInfo,
    setDefaultPaymentMethod, setDefaultBankAccount, setDefaultCreditCard, getFiles, deleteShareholderInfo, getDefaultPaymentMethod,
    brandingDetials
} from "./../../service/api";
import Menu from './../../components/Menu/Menu';
import BusinessDetailsContainer from "./../BusinessDetailsContainer/BusinessDetailsContainer";
import SigningAuthoritiesContainer from "./../SigningAuthoritiesContainer/SigningAuthoritiesContainer";
import ShareHolderInformationContainer from "./../ShareHolderInformationContainer/ShareHolderInformationContainer";
import ContactInformationContainer from "./../ContactInformationContainer/ContactInformationContainer";
import LinkBankAccountContainer from "./../LinkBankAccountContainer/LinkBankAccountContainer";
import DonationsContainer from "./../DonationsContainer/DonationsContainer"
import LenderInformationContainer from "./../LenderInformationContainer/LenderInformationContainer";
import styles from './MainContainer.module.css';
import Button from './../../components/Button/Button';
import OverviewContainer from '../OverviewContainer/OverviewContainer';
import Footer from './../../components/Footer/Footer';
import Header from './../../components/Header/Header';
import { isValidPhoneNumber } from 'react-phone-number-input'
import MenuItem from '../../components/MenuItem/MenuItem';
import SubMenuItem from '../../components/SubMenuItem/SubMenuItem';
import ConfermationContainer from "./../ConfermationContainer/ConfermationContainer";
import { FormContext } from './../../context/FormContext';
import { setUser } from '../../service/datadog';
import { translateText } from '../../service/translation';
class MainContainer extends React.Component {
    constructor(props) {
        super(props);
        this.UpdateBusinessData = (data) => {
            this.setState((state) => ({
                FormContext: {
                    ...state.FormContext,
                    BusinessData: {
                        ...state.FormContext.BusinessData,
                        ...data
                    },
                }
            }));
        };
        this.UpdateContactInformation = (data) => {
            this.setState((state) => ({
                FormContext: {
                    ...state.FormContext,
                    ContactInformation: {
                        ...state.FormContext.ContactInformation,
                        ...data
                    },
                }
            }));
        };
        this.UpdateLenderInformation = (data) => {
            this.setState((state) => ({
                FormContext: {
                    ...state.FormContext,
                    LenderInformation: {
                        ...state.FormContext.LenderInformation,
                        ...data
                    },
                }
            }));
        };
        this.UpdateDonarInformation = (data) => {
            this.setState((state) => ({
                FormContext: {
                    ...state.FormContext,
                    DonarInformation: {
                        ...state.FormContext.DonarInformation,
                        ...data
                    },
                }
            }));
        };
        this.UpdateDocuments = (data) => {
            this.setState((state) => ({
                FormContext: {
                    ...state.FormContext,
                    Documents: data,
                }
            }));
        };
        this.UpdateDocumentsBank = (data) => {
            this.setState((state) => ({
                FormContext: {
                    ...state.FormContext,
                    DocumentsBanks: data,
                }
            }));
        };

        this.UpdateBankingInformation = (data) => {
            this.setState((state) => ({
                FormContext: {
                    ...state.FormContext,
                    BankingInformation: {
                        ...state.FormContext.BankingInformation,
                        ...data
                    }
                }
            }));
        }
        this.state = {
            IsDetailsError: false,
            IsDirectorError: false,
            IsSupportError: false,
            IsBankError: false,
            IsLenderInfoError: false,
            IsDonationsError: false,
            IsOwnerError: false,
            IsDetails: false,
            IsOwner: false,
            IsDirector: false,
            IsSupport: false,
            IsBank: false,
            IsLegal: false,
            IsOpen: false,
            IsOverview: true,
            IsOverviewVisited: true,
            IsPricing: false,
            IsThirdParty: false,
            IsAgreement: false,
            IsDonations: false,
            IsLenderInfo: false,
            IsApplicationSubmission: false,
            HasSubmit: false,
            Description: "",
            Name: "",
            Isloaded: false,
            IsConfermation: false,
            AssignedBillingPackage: {},
            ShareHolderIds: [],
            SigninautoritiesIds: [],
            BusinessDetails: {},
            ShareHolders: [],
            SigningAuthorities: [],
            ContactDetails: {},
            PartnerAccountType: "",
            BankingDetails: {},
            DonationsDetails: {},
            LenderInformation: {},
            SigningAuthority: {},
            Shareholder: {},
            IsPartnerToken: false,
            IsWhiteLabel: false,
            PartnerName: "",
            HasUploadedSame: false,
            IsFinancials: false,
            progress: 0,
            FormContext: {
                BusinessData: {
                    RegistrationNumber: "",
                    DateofIncorporation: "",
                    TaxID: "",
                    ProductDescription: "",
                    RegistrationProvince: "",
                    OrganizationalType: "Sole Trader",
                    Address: "",
                    City: "",
                    Province: "",
                    PostalCode: "",
                    Country: "",
                    BusinessTypeName: "",
                    BusinessTypeCategoryName: "",
                    Website: "",
                    PhoneNumber: "",
                    LegalBusinessName: "",
                    BusinessTypeID: 1,
                    BusinessTypeCategoryID: 1,
                    AvgPaychequeAmount: "",
                    NumberOfEmployees: "",
                    PayrollFrequency: "",
                },
                UpdateBusinessData: this.UpdateBusinessData,
                DonarInformation: {
                    DonationCashProcess: "",
                    DonationPercentageCash: "",
                    AcceptDonationsCash: false,
                    DonationLimitCash: "",
                    DonationLimitNonCash: "",
                    AcceptDonations: false,
                },
                UpdateDonarInformation: this.UpdateDonarInformation,
                LenderInformation: {
                    LenderFundsSource: "",
                    LenderApr: "",
                },
                UpdateLenderInformation: this.UpdateLenderInformation,
                ContactInformation: {
                    StatementDescriptor: "",
                    StatementDescriptorShortName: "",
                    CustomerSupportPhoneNumber: "",
                    PrimaryContactFullName: "",
                    PrimaryContactTitle: "",
                    PrimaryContactEmail: "",
                    PrimaryContactPhoneNumber: "",
                    BillingContactFullName: "",
                    BillingContactTitle: "",
                    BillingContactEmail: "",
                    BillingContactPhoneNumber: "",
                    TechnicalContactFullName: "",
                    TechnicalContactTitle: "",
                    TechnicalContactEmail: "",
                    TechnicalContactPhoneNumber: "",
                    SupportContactFullName: "",
                    SupportContactTitle: "",
                    SupportContactEmail: "",
                    SupportContactPhoneNumber: "",
                },
                UpdateContactInformation: this.UpdateContactInformation,
                Documents: [],
                UpdateDocuments: this.UpdateDocuments,
                DocumentsBanks: [],
                UpdateDocumentsBank: this.UpdateDocumentsBank,
                BankingInformation: {
                    institution: "VOPAY TESTING BANK",
                    AccountNumber: "***12332",
                    BankId: 1894,
                    InstitutionNumber: 9999,
                    IQ11TokenCreditCard: "",
                    CreditCardMaskedNumber: "5576",
                    CreditCardBrand: "visa",
                    CreditCardHolderName: "",
                    ConnectionType: "manual"
                },
                UpdateBankingInformation: this.UpdateBankingInformation,

            },
            SubAccountType: "",
            DeletedSigningAuthorities: [],
            DeletedShareHolders: []

        }


    }
    validate(isAll) {

        if (this.state.IsDetailsVisited || isAll) {

            $("#BusinessDetailsContainer").parsley({
                successClass: " ",
                errorClass: "input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            })
            if ($("#BusinessDetailsContainer").parsley().validate()) {
                this.setState({
                    IsDetailsError: false,
                    IsDetailsTriggered: true
                })
            }
            else {
                this.setState({
                    IsDetailsError: true,
                    IsDetailsTriggered: true
                })
            }
        }
        if (this.state.IsLenderInfoVisited || isAll) {
            $("#LenderInformationContainer").parsley({
                successClass: " ",
                errorClass: "input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            })
            if ($("#LenderInformationContainer").parsley().validate()) {
                this.setState({
                    IsLenderInfoError: false,
                    IsLenderInfoTriggered: true
                })
            }
            else {
                this.setState({
                    IsLenderInfoError: true,
                    IsLenderInfoTriggered: true
                })
            }
        }
        if (this.state.IsDonationsVisited || isAll) {
            $("#DonationsContainer").parsley({
                successClass: " ",
                errorClass: "input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            })
            if ($("#DonationsContainer").parsley().validate()) {
                this.setState({
                    IsDonationsError: false,
                    IsDonationsTriggered: true
                })
            }
            else {
                this.setState({
                    IsDonationsError: true,
                    IsDonationsTriggered: true
                })
            }
        }
        if (this.state.IsOwnerVisited || isAll) {
            $("#ShareHolderInformationContainer").parsley({
                successClass: " ",
                errorClass: "input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            })
            if (this.isEveryInputEmpty("ShareHolderInformationContainer")) {
                this.setState({
                    IsOwnerError: false,
                    IsOwnerTriggered: true
                })
            }
            else if ($("#ShareHolderInformationContainer").parsley().validate()) {
                this.setState({
                    IsOwnerError: false,
                    IsOwnerTriggered: true
                })
            }
            else {
                this.setState({
                    IsOwnerError: true,
                    IsOwnerTriggered: true
                })
            }
        }
        if (this.state.IsDirectorVisited || isAll) {
            $("#SigningAuthoritiesContainer").parsley({
                successClass: " ",
                errorClass: "input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            })
            if ($("#SigningAuthoritiesContainer").parsley().validate()) {
                this.setState({
                    IsDirectorError: false,
                    IsDirectorTriggered: true
                })
            }
            else {
                this.setState({
                    IsDirectorError: true,
                    IsDirectorTriggered: true
                })
            }
        }
        if (this.state.IsSupportVisited || isAll) {
            $("#ContactInformationContainer").parsley({
                successClass: " ",
                errorClass: "input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            })
            if ($("#ContactInformationContainer").parsley().validate()) {
                this.setState({
                    IsSupportError: false,
                    IsSupportTriggered: true
                })
            }
            else {
                this.setState({
                    IsSupportError: true,
                    IsSupportTriggered: true
                })
            }
        }
        if (this.state.IsBankVisited || isAll) {
            $("#BankAccountInformation").parsley({
                successClass: " ",
                errorClass: "input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            })
            if ((this.state.FormContext.BankingInformation.IQ11Token === "" || this.state.FormContext.BankingInformation.IQ11Token === null
                || !$("#BankAccountInformation").parsley().validate())) {
                this.setState({
                    IsBankError: true,
                    IsBankTriggered: true
                })
            }
            else {
                this.setState({
                    IsBankError: false,
                    IsBankTriggered: true
                })
            }
        }
        if (isAll) {
            this.setState({
                IsBankVisited: true,
                IsOwnerVisited: true,
                IsSupportVisited: true,
                IsDetailsVisited: true,
                IsDirectorVisited: true,
                IsOverviewVisited: true,
                IsDonationsVisited: true,
                IsLenderInfoVisited: true,
                IsConfermationVisited: true
            })
        }


    }
    MoveToBusinessDetails = (e) => {
        e.preventDefault();
        this.validate(false);
        $("#BusinessDetailsContainer").parsley(
            {
                successClass: " ",
                errorClass: "input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            }
        );

        this.setState({
            IsDetails: true,
            IsDetailsVisited: true,
            IsOwner: false,
            IsDirector: false,
            IsSupport: false,
            IsBank: false,
            IsLegal: false,
            IsOpen: false,
            IsOverview: false,
            IsConfermation: false,
            IsPricing: false,
            IsThirdParty: false,
            IsAgreement: false,
            IsDonations: false,
            IsLenderInfo: false,
            Description: "",
        })
        $("#ContactInformationContainer").fadeOut();
        $("#SigningAuthoritiesContainer").fadeOut();
        $("#LegalTermsContainer").fadeOut();
        $("#ShareHolderInformationContainer").fadeOut();
        $("#BusinessDetailsContainer").fadeOut();
        $("#AgreementDetailsContainer").fadeOut();
        $("#ThirdPartyStatement").fadeOut();
        $("#OverviewContainer").fadeOut();
        $("#PricingContainer").fadeOut();
        $("#BankAccountInformation").fadeOut();
        $("#DonationsContainer").fadeOut();
        $("#LenderInformationContainer").fadeOut();
        $("#ConfermationContainer").fadeOut();
        $("#BusinessDetailsContainer").delay(400).fadeIn();
        this.SaveFormProgress();
        this.getPercentageFilledOut();

    }
    MoveToDonations = (e) => {
        e.preventDefault();
        $("#DonationsContainer").parsley({
            successClass: " ",
            errorClass: "input--error",
            errorsWrapper: "<p></p>",
            errorTemplate: "<span class='form__message'></span>"
        })
        this.validate(false);

        this.setState({
            IsDetails: false,
            IsOwner: false,
            IsDirector: false,
            IsSupport: false,
            IsBank: false,
            IsLegal: false,
            IsOpen: false,
            IsOverview: false,
            IsConfermation: false,
            IsPricing: false,
            IsThirdParty: false,
            IsAgreement: false,
            IsLenderInfo: false,
            IsDonations: true,
            IsDonationsVisited: true,
            Description: "",

        })
        $("#ContactInformationContainer").fadeOut();
        $("#SigningAuthoritiesContainer").fadeOut();
        $("#LegalTermsContainer").fadeOut();
        $("#ShareHolderInformationContainer").fadeOut();
        $("#BusinessDetailsContainer").fadeOut();
        $("#AgreementDetailsContainer").fadeOut();
        $("#ThirdPartyStatement").fadeOut();
        $("#OverviewContainer").fadeOut();
        $("#PricingContainer").fadeOut();
        $("#BankAccountInformation").fadeOut();
        $("#DonationsContainer").fadeOut();
        $("#LenderInformationContainer").fadeOut();
        $("#ConfermationContainer").fadeOut();
        $("#DonationsContainer").delay(400).fadeIn();
        this.SaveFormProgress();
        this.getPercentageFilledOut();
    }
    MoveToLendingInformation = (e) => {
        e.preventDefault();
        $("#LenderInformationContainer").parsley({
            successClass: " ",
            errorClass: "input--error",
            errorsWrapper: "<p></p>",
            errorTemplate: "<span class='form__message'></span>"
        })
        this.validate(false);
        this.setState({
            IsDetails: false,
            IsOwner: false,
            IsDirector: false,
            IsSupport: false,
            IsBank: false,
            IsLegal: false,
            IsOpen: false,
            IsOverview: false,
            IsConfermation: false,
            IsPricing: false,
            IsThirdParty: false,
            IsAgreement: false,
            IsDonations: false,
            IsLenderInfo: true,
            IsLenderInfoVisited: true,
            Description: "",

        })
        $("#ContactInformationContainer").fadeOut();
        $("#SigningAuthoritiesContainer").fadeOut();
        $("#LegalTermsContainer").fadeOut();
        $("#ShareHolderInformationContainer").fadeOut();
        $("#BusinessDetailsContainer").fadeOut();
        $("#AgreementDetailsContainer").fadeOut();
        $("#ThirdPartyStatement").fadeOut();
        $("#OverviewContainer").fadeOut();
        $("#PricingContainer").fadeOut();
        $("#BankAccountInformation").fadeOut();
        $("#DonationsContainer").fadeOut();
        $("#LenderInformationContainer").fadeOut();
        $("#ConfermationContainer").fadeOut();
        $("#LenderInformationContainer").delay(400).fadeIn();
        this.SaveFormProgress();
        this.getPercentageFilledOut();
    }
    MoveToShareHolder = (e) => {
        e.preventDefault();
        $("#ShareHolderInformationContainer").parsley({
            successClass: " ",
            errorClass: "input--error",
            errorsWrapper: "<p></p>",
            errorTemplate: "<span class='form__message'></span>"
        })
        this.validate(false);

        this.setState({
            IsDetails: false,
            IsOwner: true,
            IsDirector: false,
            IsSupport: false,
            IsBank: false,
            IsLegal: false,
            IsOpen: false,
            IsOverview: false,
            IsConfermation: false,
            IsPricing: false,
            IsThirdParty: false,
            IsAgreement: false,
            IsDonations: false,
            IsLenderInfo: false,
            IsOwnerVisited: true,
            Description: "",

        })
        $("#ContactInformationContainer").fadeOut();
        $("#SigningAuthoritiesContainer").fadeOut();
        $("#LegalTermsContainer").fadeOut();
        $("#ShareHolderInformationContainer").fadeOut();
        $("#BusinessDetailsContainer").fadeOut();
        $("#AgreementDetailsContainer").fadeOut();
        $("#ThirdPartyStatement").fadeOut();
        $("#OverviewContainer").fadeOut();
        $("#PricingContainer").fadeOut();
        $("#BankAccountInformation").fadeOut();
        $("#DonationsContainer").fadeOut();
        $("#LenderInformationContainer").fadeOut();
        $("#ConfermationContainer").fadeOut();
        $("#ShareHolderInformationContainer").delay(400).fadeIn();
        this.SaveFormProgress();
        this.getPercentageFilledOut();
    }
    MoveToSigningAuthorities = (e) => {
        e.preventDefault();
        var total = 0;
        $("#ShareHolderInformationContainer .PageContainer").each(function () {
            var Object2 = formObject($(this).find(":input").serializeArray());
            if (Object2.ShareholderOwnership) {
                total = total + parseInt(Object2.ShareholderOwnership);
            }


        });
        $("#SigningAuthoritiesContainer").parsley({
            successClass: " ",
            errorClass: "input--error",
            errorsWrapper: "<p></p>",
            errorTemplate: "<span class='form__message'></span>"
        })
        this.validate(false);

        if (total <= 100) {
            this.setState({
                IsDetails: false,
                IsOwner: false,
                IsDirector: true,
                IsSupport: false,
                IsBank: false,
                IsLegal: false,
                IsOpen: false,
                IsOverview: false,
                IsConfermation: false,
                IsPricing: false,
                IsThirdParty: false,
                IsAgreement: false,
                IsDonations: false,
                IsLenderInfo: false,
                IsDirectorVisited: true,
                Description: "",


            })
            $("#ContactInformationContainer").fadeOut();
            $("#SigningAuthoritiesContainer").fadeOut();
            $("#LegalTermsContainer").fadeOut();
            $("#ShareHolderInformationContainer").fadeOut();
            $("#BusinessDetailsContainer").fadeOut();
            $("#AgreementDetailsContainer").fadeOut();
            $("#ThirdPartyStatement").fadeOut();
            $("#OverviewContainer").fadeOut();
            $("#PricingContainer").fadeOut();
            $("#BankAccountInformation").fadeOut();
            $("#DonationsContainer").fadeOut();
            $("#LenderInformationContainer").fadeOut();
            $("#ConfermationContainer").fadeOut();
            $("#SigningAuthoritiesContainer").delay(400).fadeIn();
            this.SaveFormProgress();
            this.getPercentageFilledOut();
        }
        else {
            const { t } = this.props;
            this.setState({
                IsOpen: true,
                Header: t("mainContainer.alert"),
                Description: t("mainContainer.shareholderOwnershipExceeds"),

            })
        }
    }
    MoveToBankingInfo = (e) => {
        $("#BankAccountInformation").parsley({
            successClass: " ",
            errorClass: "input--error",
            errorsWrapper: "<p></p>",
            errorTemplate: "<span class='form__message'></span>"
        })
        e.preventDefault();
        this.setState({
            IsDetails: false,
            IsOwner: false,
            IsDirector: false,
            IsSupport: false,
            IsBank: true,
            IsLegal: false,
            IsOpen: false,
            IsOverview: false,
            IsConfermation: false,
            IsPricing: false,
            IsThirdParty: false,
            IsAgreement: false,
            IsDonations: false,
            IsLenderInfo: false,
            IsBankVisited: true,
            Description: "",

        })

        $("#ContactInformationContainer").fadeOut();
        $("#SigningAuthoritiesContainer").fadeOut();
        $("#LegalTermsContainer").fadeOut();
        $("#ShareHolderInformationContainer").fadeOut();
        $("#BusinessDetailsContainer").fadeOut();
        $("#AgreementDetailsContainer").fadeOut();
        $("#ThirdPartyStatement").fadeOut();
        $("#OverviewContainer").fadeOut();
        $("#PricingContainer").fadeOut();
        $("#BankAccountInformation").fadeOut();
        $("#DonationsContainer").fadeOut();
        $("#LenderInformationContainer").fadeOut();
        $("#ConfermationContainer").fadeOut();
        $("#BusinessDetailsContainer").delay(400).fadeIn();
        this.SaveFormProgress();
        this.getPercentageFilledOut();
    }
    CloseModal = (e) => {
        e.preventDefault();
        if (this.state.IsApplicationSubmission) {
            if (process.env.REACT_APP_STAGE.trim(" ") === 'local') {
                window.location.href = "https://localhost:4200";
            }
            else if (process.env.REACT_APP_STAGE.trim(" ") === 'prod') {
                window.location.href = "https://dashboard.vopay.com";
            }
            else {
                window.location.href = "https://dashboard-dev.vopay.com";
            }
        }
        else {
            this.setState({
                IsOpen: false
            })
        }
    }
    MoveToContactInfo = (e) => {
        e.preventDefault();
        $("#ContactInformationContainer").parsley({
            successClass: " ",
            errorClass: "input--error",
            errorsWrapper: "<p></p>",
            errorTemplate: "<span class='form__message'></span>"
        })
        this.validate(false);

        this.setState({
            IsDetails: false,
            IsOwner: false,
            IsDirector: false,
            IsSupport: true,
            IsBank: false,
            IsLegal: false,
            IsOverview: false,
            IsPricing: false,
            IsConfermation: false,
            IsThirdParty: false,
            IsDonations: false,
            IsAgreement: false,
            IsLenderInfo: false,
            IsSupportVisited: true,
        })
        $("#ContactInformationContainer").fadeOut();
        $("#SigningAuthoritiesContainer").fadeOut();
        $("#LegalTermsContainer").fadeOut();
        $("#ShareHolderInformationContainer").fadeOut();
        $("#BusinessDetailsContainer").fadeOut();
        $("#AgreementDetailsContainer").fadeOut();
        $("#ThirdPartyStatement").fadeOut();
        $("#OverviewContainer").fadeOut();
        $("#PricingContainer").fadeOut();
        $("#BankAccountInformation").fadeOut();
        $("#DonationsContainer").fadeOut();
        $("#LenderInformationContainer").fadeOut();
        $("#ConfermationContainer").fadeOut();
        $("#ContactInformationContainer").delay(400).fadeIn();
        this.SaveFormProgress();
        this.getPercentageFilledOut();
    }

    MoveToOverview = (e) => {
        e.preventDefault();
        this.setState({
            IsDetails: false,
            IsOwner: false,
            IsDirector: false,
            IsSupport: false,
            IsBank: false,
            IsLegal: false,
            IsOpen: false,
            IsOverview: true,
            IsPricing: false,
            IsThirdParty: false,
            IsAgreement: false,
            IsDonations: false,
            IsLenderInfo: false,
            IsOverviewVisited: true,
            IsConfermation: false,
            Description: "",

        })
        $("#ContactInformationContainer").fadeOut();
        $("#SigningAuthoritiesContainer").fadeOut();
        $("#LegalTermsContainer").fadeOut();
        $("#ShareHolderInformationContainer").fadeOut();
        $("#BusinessDetailsContainer").fadeOut();
        $("#AgreementDetailsContainer").fadeOut();
        $("#ThirdPartyStatement").fadeOut();
        $("#OverviewContainer").fadeOut();
        $("#PricingContainer").fadeOut();
        $("#BankAccountInformation").fadeOut();
        $("#DonationsContainer").fadeOut();
        $("#LenderInformationContainer").fadeOut();
        $("#ConfermationContainer").fadeOut();
        $("#OverviewContainer").delay(400).fadeIn();
        this.SaveFormProgress();
        this.getPercentageFilledOut();
    }
    LinkBankAccount = (e) => {
        e.preventDefault();
        $("#ContactInformationContainer").parsley({
            successClass: " ",
            errorClass: "input--error",
            errorsWrapper: "<p></p>",
            errorTemplate: "<span class='form__message'></span>"
        })
        this.validate(false);

        this.setState({
            IsDetails: false,
            IsOwner: false,
            IsDirector: false,
            IsSupport: false,
            IsBank: true,
            IsLegal: false,
            IsOpen: false,
            IsOverview: false,
            IsPricing: false,
            IsThirdParty: false,
            IsAgreement: false,
            IsDonations: false,
            IsLenderInfo: false,
            IsConfermation: false,
            IsBankVisited: true,
            Description: "",

        })
        $("#ContactInformationContainer").fadeOut();
        $("#SigningAuthoritiesContainer").fadeOut();
        $("#LegalTermsContainer").fadeOut();
        $("#ShareHolderInformationContainer").fadeOut();
        $("#BusinessDetailsContainer").fadeOut();
        $("#AgreementDetailsContainer").fadeOut();
        $("#ThirdPartyStatement").fadeOut();
        $("#OverviewContainer").fadeOut();
        $("#PricingContainer").fadeOut();
        $("#BankAccountInformation").fadeOut();
        $("#DonationsContainer").fadeOut();
        $("#LenderInformationContainer").fadeOut();
        $("#ConfermationContainer").fadeOut();
        $("#BankAccountInformation").delay(400).fadeIn();
        this.SaveFormProgress();
        this.getPercentageFilledOut();
    }
    MoveToConfermation = (e) => {
        e.preventDefault();
        this.validate(true)

        var formObjectBussiness = formObject($('#BusinessDetailsContainer').serializeArray());
        var formObjectContactInfo = formObject($('#ContactInformationContainer').serializeArray());
        var formObjectBanking = formObject($('#BankAccountInformation').serializeArray());
        var formObjectDonations = formObject($('#DonationsContainer').serializeArray());
        var formObjectLenderInformation = formObject($('#LenderInformationContainer').serializeArray());


        var shareholderInfo = [];
        var signingAuthoritiesInfo = [];
        if (!this.isEveryInputEmpty("ShareHolderInformationContainer")) {
            $("#ShareHolderInformationContainer .PageContainer").each(function () {
                var Object2 = formObject($(this).find(":input").serializeArray());

                shareholderInfo.push(Object2);
            });
        }
        $("#SigningAuthoritiesContainer .PageContainer").each(function () {
            var Object2 = formObject($(this).find(":input").serializeArray());
            signingAuthoritiesInfo.push(Object2);
        });
        this.setState({
            IsDetails: false,
            IsOwner: false,
            IsDirector: false,
            IsSupport: false,
            IsBank: false,
            IsLegal: false,
            IsOpen: false,
            IsOverview: false,
            IsPricing: false,
            IsThirdParty: false,
            IsAgreement: false,
            IsDonations: false,
            IsLenderInfo: false,
            IsConfermation: true,
            IsConfermationVisited: true,
            Description: "",
            BusinessDetails: formObjectBussiness,
            ShareHolders: shareholderInfo,
            SigningAuthorities: signingAuthoritiesInfo,
            ContactDetails: formObjectContactInfo,
            BankingDetails: formObjectBanking,
            DonationsDetails: formObjectDonations,
            LenderInformation: formObjectLenderInformation
        })

        $("#ContactInformationContainer").fadeOut();
        $("#SigningAuthoritiesContainer").fadeOut();
        $("#LegalTermsContainer").fadeOut();
        $("#ShareHolderInformationContainer").fadeOut();
        $("#BusinessDetailsContainer").fadeOut();
        $("#AgreementDetailsContainer").fadeOut();
        $("#ThirdPartyStatement").fadeOut();
        $("#OverviewContainer").fadeOut();
        $("#PricingContainer").fadeOut();
        $("#BankAccountInformation").fadeOut();
        $("#DonationsContainer").fadeOut();
        $("#LenderInformationContainer").fadeOut();
        $("#ConfermationContainer").fadeOut();
        $("#ConfermationContainer").delay(400).fadeIn();
        this.SaveFormProgress();
        this.getPercentageFilledOut();
    }
    SaveFormProgress = () => {

        var formObjectBussiness = formObject($('#BusinessDetailsContainer').serializeArray());
        var formObjectContactInfo = formObject($('#ContactInformationContainer').serializeArray());
        var formObjectBanking = formObject($('#BankAccountInformation').serializeArray());
        var formObjectDonations = formObject($('#DonationsContainer').serializeArray());
        var formObjectLenderInformation = formObject($('#LenderInformationContainer').serializeArray());
        var extendedInfo = $.extend({}, formObjectBussiness, formObjectContactInfo);
        extendedInfo = $.extend({}, extendedInfo, formObjectBanking);
        extendedInfo = $.extend({}, extendedInfo, formObjectDonations);
        extendedInfo = $.extend({}, extendedInfo, formObjectLenderInformation);
        var shareholderInfo = [];
        var signingAuthoritiesInfo = [];
        var ShareHolderIds = this.state.ShareHolderIds;
        var SigninautoritiesIds = this.state.SigninautoritiesIds;
        if (!this.isEveryInputEmpty("ShareHolderInformationContainer")) {
            $("#ShareHolderInformationContainer .PageContainer").each(function () {
                var Object2 = formObject($(this).find(":input").serializeArray());
                if (ShareHolderIds[Object2.Key]) {
                    Object2.Id = ShareHolderIds[Object2.Key]
                }
                shareholderInfo.push(Object2);
            });
        }
        $("#SigningAuthoritiesContainer .PageContainer").each(function () {
            var Object2 = formObject($(this).find(":input").serializeArray());
            if (SigninautoritiesIds[Object2.Key]) {
                Object2.Id = SigninautoritiesIds[Object2.Key]
            }
            if (Object2.AuthorizedFullLegalName || Object2.AuthorizedFullLegalName || Object2.AuthorizedDOB || Object2.AuthorizedAddress1 || Object2.AuthorizedCity || Object2.AuthorizedProvince || Object2.AuthorizedCountry || Object2.AuthorizedPostalCode || Object2.Id) {
                signingAuthoritiesInfo.push(Object2);
            }

        });
        let self = this;
        let deletedSigningAuthorities = this.state.DeletedSigningAuthorities;
        let deletedShareHolders = this.state.DeletedShareHolders;
        const setDefault = new Promise((resolve, reject) => {

            if (extendedInfo.DefaultPaymentMethod === "credit") {
                setDefaultCreditCard({ CreditCardToken: extendedInfo.IQ11TokenCreditCard }).then(
                    () => {
                        setDefaultPaymentMethod({ PaymentMethod: extendedInfo.DefaultPaymentMethod }).then(
                            () => {
                                resolve()
                            },
                            error => {
                                reject(error);
                            });
                    },
                    error => {
                        reject(error);
                    }
                );
            }
            else {
                setDefaultBankAccount({ Iq11Token: extendedInfo.Iq11Token }).then(
                    () => {
                        setDefaultPaymentMethod({ PaymentMethod: "bank" }).then(
                            () => {
                                resolve()
                            },
                            error => {
                                reject(error);
                            });
                    },
                    error => {
                        reject(error);
                    }
                );
            }

        });

        submitExtendedInfo(extendedInfo, true).then(() => {
            var promises = [];
            shareholderInfo.forEach(element => {
                promises.push(submitShareholderInfo(element, true))
            });
            signingAuthoritiesInfo.forEach(element => {
                promises.push(submitSigningAuthoritesInfo(element, true))
            });
            deletedSigningAuthorities.forEach(element => {
                promises.push(deleteSigningAuthoritesInfo({ SigningAuthorityID: element }))
            });
            deletedShareHolders.forEach(element => {
                promises.push(deleteShareholderInfo({ ShareholderID: element }))
            });

            this.setState({
                HasSubmit: true
            });

            Promise.all(promises).then((values) => {

                var HasError = false;
                var ErrorMessage = ""

                values.forEach(function (item) {
                    if (item) {

                        if (item.Success === false) {
                            HasError = true;
                            ErrorMessage = item.ErrorMessage;
                        }
                        else {
                            if (item.Key) {

                                if (item.Type === "Shareholder") {
                                    if (item.ShareholderID) {
                                        var parentContainer2 = $('#ShareHolderInformationContainer [data-id="' + item.Key + '"]');
                                        var IdInput2 = parentContainer2.find('input[name="Id"]');
                                        IdInput2.val(item.ShareholderID)
                                        ShareHolderIds[item.Key] = item.ShareholderID;
                                    }

                                }
                                if (item.Type === "SigningAuthority") {
                                    if (item.SigningAuthorityID) {
                                        var parentContainer = $('#SigningAuthoritiesContainer [data-id="' + item.Key + '"]');
                                        var IdInput = parentContainer.find('input[name="Id"]');
                                        IdInput.val(item.SigningAuthorityID)
                                        SigninautoritiesIds[item.Key] = item.SigningAuthorityID;
                                    }
                                }
                            }
                        }
                    }
                })
                this.setState({
                    ShareHolderIds: ShareHolderIds,
                    SigninautoritiesIds: SigninautoritiesIds,
                    DeletedSigningAuthorities: [],
                    DeletedShareHolders: []
                });

                this.setState({
                    LastSaved: (new Date()).toLocaleString('en-US')
                });
            }).catch(error => {


            });

        },
            error => {

            });
        var count = 0;
        let filesPomises = []
        $("input[type=file]").each(function () {

            var filePromise = new Promise((resolve, reject) => {

                if ($(this)[0].files[0] != null) {
                    let name = $(this).attr('data-name');

                    const Key = $(this).attr('data-parent-Id');
                    const documentType = $(this).attr('data-document-type');
                    if (name === "IdFront" || name === "IdBack") {
                        var parentContainer = $('#SigningAuthoritiesContainer [data-id="' + Key + '"]');
                        var IdInput = parentContainer.find('input[name="Id"]');

                        name = name + IdInput.val();
                        if (SigninautoritiesIds[Key] && (IdInput.val() === "" || !IdInput.val())) {
                            name = name + SigninautoritiesIds[Key];
                        }
                    }
                    if (name === "IdFrontShareholder" || name === "IdBackShareholder") {
                        var parentContainer2 = $('#ShareHolderInformationContainer [data-id="' + Key + '"]');
                        var IdInput2 = parentContainer2.find('input[name="Id"]');

                        name = name + IdInput2.val();
                        if (ShareHolderIds[Key] && (IdInput2.val() === "" || !IdInput2.val())) {
                            name = name + ShareHolderIds[Key];
                        }
                    }
                    convertBase64($(this)[0]).then(
                        data => {
                            count = count + 1;
                            var options = {
                                DocumentName: (name + "-" + count + "-" + $(this)[0].files[0].name),
                                DocumentContent: data,
                                DocumentType: documentType
                            }
                            $(this).attr('required', false);
                            $(this).val("");
                            resolve(submitFiles(options));
                        },
                        error => {
                            console.log.error('base64 Conversion Failed:', error)
                            reject(error)
                        }
                    );
                }
                else {

                    resolve()
                }
            });
            filesPomises.push(filePromise);
        });
        getFiles().then(
            data2 => {
                const DocumentsArray = Object.values(data2.Documents).filter(file => file.DocumentType === "kyc");
                const Documents = DocumentsArray.reduce(function (prev, current) {
                    const key = current.DocumentName.split("-").shift();
                    prev[key] = current;
                    return prev;
                }, [])
                const DocumentsArray2 = Object.values(data2.Documents).filter(file => file.DocumentType === "bankinfo");
                const Documents2 = DocumentsArray2.map(function (current) {
                    return current.DocumentName;
                })
                this.state.FormContext.UpdateDocuments(Documents);
                //this.state.FormContext.UpdateDocumentsBank(Documents2);

            },
            error => {

            }
        );

    }
    getTotalErrorCount() {
        const forms = Array.from(document.querySelectorAll('form'));
        let totalErrorCount = 0;

        forms.forEach((form) => {
            const parsleyInstance = $(form).parsley();
            if (parsleyInstance) {
                if (!parsleyInstance.isValid()) {
                    const errorMessages = [];
                    for (var idx in parsleyInstance.fields) {
                        // Use $element to access the jQuery element of each field
                        if (parsleyInstance.fields[idx].getErrorsMessages().length > 0) {
                            errorMessages.push("error");
                        }
                    }
                    totalErrorCount += errorMessages.length;
                }
            }
        });

        return totalErrorCount;
    }
    MoveToComplete = (e) => {
        e.preventDefault();
        const { t, i18n } = this.props;
        if (!this.state.IsBankError && !this.state.IsDetailsError && !this.state.IsDirectorError && !this.state.IsDonationsError &&
            !this.state.IsLenderInfoError && !this.state.IsSupportError && !this.state.IsOwnerError) {
            $("#submitting").fadeIn();

            var formObjectBussiness = formObject($('#BusinessDetailsContainer').serializeArray());
            var formObjectContactInfo = formObject($('#ContactInformationContainer').serializeArray());
            var formObjectBanking = formObject($('#BankAccountInformation').serializeArray());
            var formObjectDonations = formObject($('#DonationsContainer').serializeArray());
            var formObjectLenderInformation = formObject($('#LenderInformationContainer').serializeArray());
            var extendedInfo = $.extend({}, formObjectBussiness, formObjectContactInfo);
            extendedInfo = $.extend({}, extendedInfo, formObjectBanking);
            extendedInfo = $.extend({}, extendedInfo, formObjectDonations);
            extendedInfo = $.extend({}, extendedInfo, formObjectLenderInformation);
            var shareholderInfo = [];
            var signingAuthoritiesInfo = [];
            var ShareHolderIds = this.state.ShareHolderIds;
            var SigninautoritiesIds = this.state.SigninautoritiesIds;
            if (!this.isEveryInputEmpty("ShareHolderInformationContainer")) {
                $("#ShareHolderInformationContainer .PageContainer").each(function () {
                    var Object2 = formObject($(this).find(":input").serializeArray());
                    if (ShareHolderIds[Object2.Key]) {
                        Object2.Id = ShareHolderIds[Object2.Key]
                    }
                    shareholderInfo.push(Object2);
                });
            }
            $("#SigningAuthoritiesContainer .PageContainer").each(function () {
                var Object2 = formObject($(this).find(":input").serializeArray());
                if (SigninautoritiesIds[Object2.Key]) {
                    Object2.Id = SigninautoritiesIds[Object2.Key]
                }
                signingAuthoritiesInfo.push(Object2);
            });

            let self = this;
            let deletedSigningAuthorities = this.state.DeletedSigningAuthorities;
            let deletedShareHolders = this.state.DeletedShareHolders;
            const setDefault = new Promise((resolve, reject) => {
                if (extendedInfo.DefaultPaymentMethod === "credit") {
                    setDefaultCreditCard({ CreditCardToken: extendedInfo.IQ11TokenCreditCard }).then(
                        () => {
                            setDefaultPaymentMethod({ PaymentMethod: extendedInfo.DefaultPaymentMethod }).then(
                                () => {
                                    resolve()
                                },
                                error => {
                                    reject(error);
                                });
                        },
                        error => {
                            reject(error);
                        }
                    );
                }
                else {
                    setDefaultBankAccount({ Iq11Token: extendedInfo.Iq11Token }).then(
                        () => {
                            setDefaultPaymentMethod({ PaymentMethod: "bank" }).then(
                                () => {
                                    resolve()
                                },
                                error => {
                                    reject(error);
                                });
                        },
                        error => {
                            reject(error);
                        }
                    );
                }

            });
            setDefault.then(() => {
                submitExtendedInfo(extendedInfo, false).then(() => {
                    var promises = [];
                    shareholderInfo.forEach(element => {
                        promises.push(submitShareholderInfo(element, false))
                    });
                    signingAuthoritiesInfo.forEach(element => {
                        promises.push(submitSigningAuthoritesInfo(element, false))
                    });
                    deletedSigningAuthorities.forEach(element => {
                        promises.push(deleteSigningAuthoritesInfo({ SigningAuthorityID: element }))
                    });
                    deletedShareHolders.forEach(element => {
                        promises.push(deleteShareholderInfo({ ShareholderID: element }))
                    });

                    var count = 0;
                    $("input[type=file]").each(function () {
                        var filePromise = new Promise((resolve, reject) => {

                            if ($(this)[0].files[0] != null) {
                                let name = $(this).attr('data-name');

                                const Key = $(this).attr('data-parent-Id');
                                const documentType = $(this).attr('data-document-type');
                                if (name === "IdFront" || name === "IdBack") {
                                    var parentContainer = $('#SigningAuthoritiesContainer [data-id="' + Key + '"]');
                                    var IdInput = parentContainer.find('input[name="Id"]');

                                    name = name + IdInput.val();
                                    if (SigninautoritiesIds[Key] && (IdInput.val() === "" || !IdInput.val())) {
                                        name = name + SigninautoritiesIds[Key];
                                    }
                                }
                                if (name === "IdFrontShareholder" || name === "IdBackShareholder") {
                                    var parentContainer2 = $('#ShareHolderInformationContainer [data-id="' + Key + '"]');
                                    var IdInput2 = parentContainer2.find('input[name="Id"]');

                                    name = name + IdInput2.val();
                                    if (ShareHolderIds[Key] && (IdInput2.val() === "" || !IdInput2.val())) {
                                        name = name + ShareHolderIds[Key];
                                    }
                                }
                                convertBase64($(this)[0]).then(
                                    data => {
                                        count = count + 1;
                                        var options = {
                                            DocumentName: (name + "-" + count + "-" + $(this)[0].files[0].name),
                                            DocumentContent: data,
                                            DocumentType: documentType
                                        }

                                        resolve(submitFiles(options));
                                    },
                                    error => {
                                        console.log.error('base64 Conversion Failed:', error)
                                        reject(error)
                                    }
                                );
                            }
                            else {

                                resolve()
                            }
                        });
                        promises.push(filePromise);
                    });

                    this.setState({
                        HasSubmit: true
                    });

                    Promise.all(promises).then((values) => {

                        var HasError = false;
                        var ErrorMessage = ""
                        var shareHShareHolderIdsolders = this.state.ShareHolderIds;
                        var SigninautoritiesIds = this.state.SigninautoritiesIds;
                        values.forEach(function (item) {
                            if (item) {
                                if (item.Success === false) {
                                    HasError = true;
                                    ErrorMessage = item.ErrorMessage;

                                }
                                else {
                                    if (item.Key) {

                                        if (item.Type === "Shareholder") {
                                            var parentContainer2 = $('#ShareHolderInformationContainer [data-id="' + data.Key + '"]');
                                            var IdInput2 = parentContainer2.find('input[name="Id"]');
                                            IdInput2.val(item.ShareholderID)
                                        }
                                        if (item.Type === "SigningAuthority") {
                                            var parentContainer = $('#SigningAuthoritiesContainer [data-id="' + data.Key + '"]');
                                            var IdInput = parentContainer.find('input[name="Id"]');
                                            IdInput.val(item.SigningAuthorityID)
                                        }
                                    }
                                }
                            }
                        })
                        this.setState({
                            ShareHolderIds: shareHShareHolderIdsolders,
                            SigninautoritiesIds: SigninautoritiesIds
                        })

                        if (HasError === false) {
                            $("#complete").fadeIn();


                        }
                        else {
                            $("#complete").fadeIn();
                            // $("#submitting").fadeOut();
                            // this.setState({
                            //     IsOpen:true,
                            //     Description:`${ErrorMessage}`
                            // })
                        }
                    }).catch(error => {
                        $("#submitting").fadeOut();
                        this.setState({
                            IsOpen: true,
                            Header: t("mainContainer.alert"),

                            Description: `${translateText(error, i18n.language)}`
                        })

                    });
                },
                    error => {
                        $("#submitting").fadeOut();
                        self.setState({
                            IsOpen: true,
                            Header: t("mainContainer.alert"),

                            Description: `${translateText(error, i18n.language)}`
                        })
                    });
            },
                error => {
                    $("#submitting").fadeOut();
                    self.setState({
                        IsOpen: true,
                        Header: t("mainContainer.alert"),

                        Description: `${translateText(error, i18n.language)}`
                    })
                });
        }
        else {
            const errorsCount = this.getTotalErrorCount();
            this.setState({
                IsOpen: true,
                Header: t('mainContainer.errors.unableToSubmitForm'),
                Description: <div style={{ marginTop: "24px" }}>
                    {t('mainContainer.errors.formSubmissionFailed', { errorsCount: errorsCount })}
                    <div style={{ marginBottom: "24px" }}>
                        <ul style={{ display: "inline-block", textAlign: "left" }}>
                            {this.state.IsDetailsError && <li>{t('mainContainer.errors.businessDetails')}</li>}
                            {this.state.IsLenderInfoError && <li>{t('mainContainer.errors.lenderInformation')}</li>}
                            {this.state.IsDonationsError && <li>{t('mainContainer.errors.antiMoneyLaundering')}</li>}
                            {this.state.IsOwnerError && <li>{t('mainContainer.errors.ownershipStructure')}</li>}
                            {this.state.IsDirectorError && <li>{t('mainContainer.errors.signingAuthorities')}</li>}
                            {this.state.IsSupportError && <li>{t('mainContainer.errors.supportingContactInformation')}</li>}
                            {this.state.IsBankError && <li>{t('mainContainer.errors.bankAccountBilling')}</li>}
                        </ul>
                    </div>
                    {t('mainContainer.errors.goBack')}
                </div>
            })
        }

    }
    includesWord(text, word) {
        // Create a regular expression pattern to match the word as a standalone word
        const pattern = new RegExp(`\\b${word}\\b`, 'i');

        // Use the test() method to check if the word exists in the text
        return pattern.test(text);
    }
    componentWillMount() {
        var path = new URL(window.location.href).pathname;
        var paths = path.split("/")
        var token = paths[paths.length - 1];
        const { i18n } = this.props;
        const languagesSupported = ['en', 'fr'];
        const userLanguage = navigator.language;

        let languageToUse = 'en';

        if (languagesSupported.includes(userLanguage)) {
            languageToUse = userLanguage;
        } else if (userLanguage.startsWith('fr-')) {
            // If the user's language starts with 'fr-', consider it as French
            languageToUse = 'fr';
        }
        i18n.changeLanguage(languageToUse);

        sessionStorage.setItem("Token", token);
        let self = this;
        var options = {
            ApplicationType: "registration",
        }
        const { t } = this.props;
        setUser({
            ApplicationType: "registration",
            Token: token
        })
        getTokenInfo(options).then(
            data => {
                window.IsPartnerToken = data.IsPartnerToken
                if (data.AccountStatus === "Application Pending") {

                    brandingDetials().then(
                        brandingInfo => {
                            self.setState({
                                IsWhiteLabel: data.PartnerAccountType !== "Inside Sales" && data.IsPartnerToken === false && data.PartnerAccountType !== "",
                                PartnerName: data.PartnerName || "",
                                SecondaryColor:
                                    data.PartnerAccountType !== "Inside Sales" && data.IsPartnerToken === false && data.PartnerAccountType !== "" ?
                                        brandingInfo.BrandingDetails ? "" + brandingInfo.BrandingDetails.SecondaryColor : 'white'
                                        : 'black',
                                PrimaryColor: data.PartnerAccountType !== "Inside Sales" && data.IsPartnerToken === false && data.PartnerAccountType !== "" && brandingInfo.BrandingDetails ? "" + brandingInfo.BrandingDetails.PrimaryColor : "#FFD147",
                                Image: brandingInfo.Logo,
                                Isloaded: true,
                                Name: data.Name,
                                IsFinancials: self.includesWord((data.PartnerName || "").toLowerCase(), "sage"),
                                IsPartnerToken: data.IsPartnerToken,
                                PartnerAccountType: data.PartnerAccountType,
                                SubAccountType: data.SubAccountType,
                            })
                        },
                        error => {
                            self.setState({
                                Isloaded: true,
                                Name: data.Name,
                                IsPartnerToken: data.IsPartnerToken,
                                PartnerAccountType: data.PartnerAccountType,
                                PartnerName: data.PartnerName || "",
                                IsFinancials: self.includesWord((data.PartnerName || "").toLowerCase(), "sage"),
                                IsWhiteLabel: data.PartnerAccountType !== "Inside Sales" && data.IsPartnerToken === false && data.PartnerAccountType !== "",
                                SubAccountType: data.SubAccountType,
                                SecondaryColor: data.PartnerAccountType !== "Inside Sales" && data.IsPartnerToken === false && data.PartnerAccountType !== "" ? 'white' : 'black',
                                PrimaryColor: data.PartnerAccountType !== "Inside Sales" && data.IsPartnerToken === false && data.PartnerAccountType !== "" ? "#FFD147" : "#FFD147",
                            })
                        })
                        .finally(() => {
                            const extended = getExtendedInfo().then(
                                extendedInfo => {

                                    self.state.FormContext.UpdateBusinessData({
                                        Address: extendedInfo.AddressResponse.Address1,
                                        City: extendedInfo.AddressResponse.City,
                                        Province: extendedInfo.AddressResponse.ADRProvinces_ID,
                                        PostalCode: extendedInfo.AddressResponse.PostalCode,
                                        Country: extendedInfo.AddressResponse.ADRCountries_ID,
                                        BusinessTypeName: extendedInfo.AccountInformationResponse.BusinessTypeID,
                                        BusinessTypeCategoryName: extendedInfo.AccountInformationResponse.BusinessTypeCategoryID,
                                        OrganizationalType: extendedInfo.AccountInformationResponse.OrganizationalType,
                                        Website: extendedInfo.AccountInformationResponse.Website,
                                        PhoneNumber: extendedInfo.AccountInformationResponse.PrimaryPhone,
                                        LegalBusinessName: extendedInfo.AccountInformationResponse.LegalBusinessName,
                                        RegistrationNumber: extendedInfo.AccountInformationResponse.RegistrationNumber,
                                        DateofIncorporation: extendedInfo.AccountInformationResponse.DateIncorporation,
                                        TaxID: extendedInfo.AccountInformationResponse.TaxID,
                                        ProductDescription: extendedInfo.AccountInformationResponse.ProductDescription,
                                        RegistrationProvince: extendedInfo.AccountInformationResponse.RegistrationProvince,
                                        AvgPaychequeAmount: extendedInfo.AccountInformationResponse.AvgPaychequeAmount,
                                        NumberOfEmployees: extendedInfo.AccountInformationResponse.NumberOfEmployees,
                                        PayrollFrequency: extendedInfo.AccountInformationResponse.PayrollFrequency
                                    })
                                    self.state.FormContext.UpdateContactInformation({
                                        StatementDescriptor: extendedInfo.AccountInformationResponse.StatementDescriptor,
                                        StatementDescriptorShortName: extendedInfo.AccountInformationResponse.StatementDescriptorShortName,
                                        CustomerSupportPhoneNumber: extendedInfo.AccountInformationResponse.CustomerSupportPhoneNumber,
                                        PrimaryContactFullName: extendedInfo.AccountInformationResponse.PrimaryContactFullName,
                                        PrimaryContactTitle: extendedInfo.AccountInformationResponse.PrimaryContactTitle,
                                        PrimaryContactEmail: extendedInfo.AccountInformationResponse.PrimaryContactEmail,
                                        PrimaryContactPhoneNumber: extendedInfo.AccountInformationResponse.PrimaryContactPhoneNumber,
                                        BillingContactFullName: extendedInfo.AccountInformationResponse.BillingContactFullName,
                                        BillingContactTitle: extendedInfo.AccountInformationResponse.BillingContactTitle,
                                        BillingContactEmail: extendedInfo.AccountInformationResponse.BillingContactEmail,
                                        BillingContactPhoneNumber: extendedInfo.AccountInformationResponse.BillingContactPhoneNumber,
                                        TechnicalContactFullName: extendedInfo.AccountInformationResponse.TechnicalContactFullName,
                                        TechnicalContactTitle: extendedInfo.AccountInformationResponse.TechnicalContactTitle,
                                        TechnicalContactEmail: extendedInfo.AccountInformationResponse.TechnicalContactEmail,
                                        TechnicalContactPhoneNumber: extendedInfo.AccountInformationResponse.TechnicalContactPhoneNumber,
                                        SupportContactFullName: extendedInfo.AccountInformationResponse.SupportContactFullName,
                                        SupportContactTitle: extendedInfo.AccountInformationResponse.SupportContactTitle,
                                        SupportContactEmail: extendedInfo.AccountInformationResponse.SupportContactEmail,
                                        SupportContactPhoneNumber: extendedInfo.AccountInformationResponse.SupportContactPhoneNumber,
                                    })
                                    self.state.FormContext.UpdateLenderInformation({
                                        LenderFundsSource: extendedInfo.AccountInformationResponse.LenderFundsSource,
                                        LenderApr: extendedInfo.AccountInformationResponse.LenderApr,
                                    })
                                    self.state.FormContext.UpdateDonarInformation({
                                        DonationCashProcess: extendedInfo.AccountInformationResponse.DonationCashProcess,
                                        DonationPercentageCash: extendedInfo.AccountInformationResponse.DonationPercentageCash,
                                        AcceptDonationsCash: extendedInfo.AccountInformationResponse.AcceptDonationsCash === "1",
                                        DonationLimitCash: extendedInfo.AccountInformationResponse.DonationLimitCash,
                                        DonationLimitNonCash: extendedInfo.AccountInformationResponse.DonationLimitNonCash,
                                        AcceptDonations: extendedInfo.AccountInformationResponse.AcceptDonations === "1",
                                    })
                                    const CreditCard = Object.values(extendedInfo.CreditCards || {}).filter((card) => card.IsActive === "1").pop();

                                    const BankAccount = Object.values(extendedInfo.BankAccountResponse).pop();
                                    self.state.FormContext.UpdateBankingInformation({
                                        institution: BankAccount?.InstitutionName || "VOPAY TESTING BANK",
                                        AccountNumber: BankAccount?.AccountNumber || "99999999",
                                        BankId: BankAccount?.BankId || 1894,
                                        InstitutionNumber: BankAccount?.FinancialInstitutionNumber || "9999",
                                        IQ11Token: BankAccount?.Token || "",
                                        IQ11TokenCreditCard: CreditCard?.CreditCardToken || "",
                                        CreditCardMaskedNumber: CreditCard?.CreditCardNumber || "5576",
                                        CreditCardBrand: CreditCard?.CreditCardBrand || "visa",
                                        CreditCardHolderName: CreditCard?.CreditCardHolderName || "",
                                        ConnectionType: BankAccount?.ConnectionType || "manual"
                                    })


                                },
                                error => {
                                    self.setState({
                                        Isloaded: true
                                    })
                                }
                            )
                            const defaultPayment = getDefaultPaymentMethod().then(
                                data2 => {
                                    self.state.FormContext.UpdateBankingInformation({
                                        DefaultPaymentMethod: data2.DefaultPaymentMethod
                                    })
                                },
                                error => {

                                }
                            )
                            const signingAuthorities = getSigningAuthoritesInfo().then(
                                data2 => {
                                    self.setState({
                                        SigningAuthority: data2.SigningAuthority
                                    });
                                },
                                error => {
                                }
                            );
                            const shareHolder = getShareholderInfo().then(
                                data2 => {

                                    self.setState({
                                        Shareholder: data2.ShareHolder
                                    });
                                },
                                error => {
                                }
                            );
                            const Files = getFiles().then(
                                data2 => {
                                    const DocumentsArray = Object.values(data2.Documents).filter(file => file.DocumentType === "kyc");
                                    const Documents = DocumentsArray.reduce(function (prev, current) {
                                        const key = current.DocumentName.split("-").shift();
                                        prev[key] = current;
                                        return prev;
                                    }, [])
                                    const DocumentsArray2 = Object.values(data2.Documents).filter(file => file.DocumentType === "bankinfo");
                                    const Documents2 = DocumentsArray2.map(function (current) {
                                        return current.DocumentName;
                                    })
                                    self.state.FormContext.UpdateDocuments(Documents);
                                    self.state.FormContext.UpdateDocumentsBank(Documents2);
                                },
                                error => {

                                }
                            );
                            var startDate = new Date();
                            sessionStorage.setItem("SessionTime", startDate.getTime());
                            setInterval(function () {
                                var d2 = new Date();
                                if ((d2.getTime() - sessionStorage.getItem("SessionTime")) <= 1800000) {
                                    self.SaveFormProgress();
                                }
                            }, 60000)

                            $("body").on('click', function () {
                                var d = new Date();
                                sessionStorage.setItem("SessionTime", d.getTime());
                            });
                            Promise.all([extended, defaultPayment, signingAuthorities, shareHolder, Files]).then((values) => {
                                self.setState({
                                    Isloaded: true,
                                })
                                self.getPercentageFilledOut();

                            });
                        }
                        )


                }
                else {

                    self.setState({

                        IsOpen: true,
                        Header: t("mainContainer.applicationSubmission"),
                        IsApplicationSubmission: true,
                        Description: <div>{t("mainContainer.formSubmittedOrExpired")}</div>
                    })
                }
                if (data.IsParentAccount) {
                    title({
                        title: t("mainContainer.partnerApplication")
                    });
                }
                else {
                    title({
                        title: t("mainContainer.clientApplication")
                    });
                }

            },
            error => {
                self.setState({
                    IsOpen: true,
                    Header: t("mainContainer.alert"),
                    Description: error
                })
            });
        generateEmbedUrl({ PaymentSelectionMethod: "bank" }).then(
            data => {
                self.setState({

                    IframeUrl: data.EmbedURL,
                })
            },
            error => {


            });


    }
    componentDidMount() {
        window.Parsley
            .addValidator('filemimetypes', {
                requirementType: 'string',
                validateString: function (value, requirement, parsleyInstance) {
                    var file = parsleyInstance.$element[0].files;

                    if (file.length === 0) {
                        return true;
                    }

                    var allowedMimeTypes = requirement.replace(/\s/g, "").split(',');
                    return allowedMimeTypes.indexOf(file[0].type) !== -1;

                },
                messages: {
                    en: 'File mime type not allowed',
                    fr: 'Type de fichier non autorisé'
                }
            });
        window.Parsley.addValidator('validphonemumber', {
            requirementType: 'string',
            validateString: function (value, requirement, parsleyInstance) {
                var value2 = parsleyInstance.$element.val();
                var isvalid = isValidPhoneNumber("+" + value2);
                return isvalid;
            },
            messages: {
                en: 'Invalid Phone Number',
                fr: "Numéro de téléphone invalide"
            }
        });
        window.Parsley.addValidator('length', {
            requirementType: 'number',
            validateString: function (value, requirement, parsleyInstance) {
                return value.length === requirement;
            },
            messages: {
                en: 'Invalid length',
                fr: 'Longueur invalide'

            }
        });
        // Validation errors messages for Parsley
        // Load this after Parsley

        window.Parsley.addMessages('fr', {
            defaultMessage: "Cette valeur semble non valide.",
            type: {
                email: "Cette valeur n'est pas une adresse email valide.",
                url: "Cette valeur n'est pas une URL valide.",
                number: "Cette valeur doit être un nombre.",
                integer: "Cette valeur doit être un entier.",
                digits: "Cette valeur doit être numérique.",
                alphanum: "Cette valeur doit être alphanumérique."
            },
            notblank: "Cette valeur ne peut pas être vide.",
            required: "Ce champ est requis.",
            pattern: "Cette valeur semble non valide.",
            min: "Cette valeur ne doit pas être inférieure à %s.",
            max: "Cette valeur ne doit pas excéder %s.",
            range: "Cette valeur doit être comprise entre %s et %s.",
            minlength: "Cette chaîne est trop courte. Elle doit avoir au minimum %s caractères.",
            maxlength: "Cette chaîne est trop longue. Elle doit avoir au maximum %s caractères.",
            length: "Cette valeur doit contenir entre %s et %s caractères.",
            mincheck: "Vous devez sélectionner au moins %s choix.",
            maxcheck: "Vous devez sélectionner %s choix maximum.",
            check: "Vous devez sélectionner entre %s et %s choix.",
            equalto: "Cette valeur devrait être identique."
        });
        window.Parsley.addMessages('fr-CA', {
            defaultMessage: "Cette valeur semble non valide.",
            type: {
                email: "Cette valeur n'est pas une adresse email valide.",
                url: "Cette valeur n'est pas une URL valide.",
                number: "Cette valeur doit être un nombre.",
                integer: "Cette valeur doit être un entier.",
                digits: "Cette valeur doit être numérique.",
                alphanum: "Cette valeur doit être alphanumérique."
            },
            notblank: "Cette valeur ne peut pas être vide.",
            required: "Ce champ est requis.",
            pattern: "Cette valeur semble non valide.",
            min: "Cette valeur ne doit pas être inférieure à %s.",
            max: "Cette valeur ne doit pas excéder %s.",
            range: "Cette valeur doit être comprise entre %s et %s.",
            minlength: "Cette chaîne est trop courte. Elle doit avoir au minimum %s caractères.",
            maxlength: "Cette chaîne est trop longue. Elle doit avoir au maximum %s caractères.",
            length: "Cette valeur doit contenir entre %s et %s caractères.",
            mincheck: "Vous devez sélectionner au moins %s choix.",
            maxcheck: "Vous devez sélectionner %s choix maximum.",
            check: "Vous devez sélectionner entre %s et %s choix.",
            equalto: "Cette valeur devrait être identique."
        });
        const { i18n } = this.props;

        window.Parsley.setLocale(i18n.language);

        $('body').on('input', '.PhoneInputInput', function () {
            if ($(this).parent().parent().find("input").last().parsley()._failedOnce) {
                $(this).parent().parent().find("input").last().parsley().validate()
                if ($(this).parent().parent().find("input").last().parsley().isValid()) {
                    $(this).parent().parent().removeClass("input--errorPhone");
                }
                else {
                    $(this).parent().parent().addClass("input--errorPhone");
                }
            }
            //do something
        });

        $('body').on('blur', '.PhoneInputInput', function () {
            $(this).parent().parent().find("input").last().parsley().validate()
            if ($(this).parent().parent().find("input").last().parsley().isValid()) {

                $(this).parent().parent().removeClass("input--errorPhone");
            }
            else {

                $(this).parent().parent().addClass("input--errorPhone");
            }
            //do something
        });


    }
    isEveryInputEmpty(id) {
        var allEmpty = true;
        $('#' + id + ' input[type=text]').each(function () {
            if ($(this).val() !== '') {
                allEmpty = false;
                return false; // we've found a non-empty one, so stop iterating
            }
        });
        $('#' + id + ' input[type=number]').each(function () {

            if ($(this).val() !== '') {
                allEmpty = false;
                return false; // we've found a non-empty one, so stop iterating
            }
        });

        return allEmpty;
    }
    updateRemovedSigningAuthorites = (data) => {
        this.setState({
            DeletedSigningAuthorities: data
        });
    }
    updateRemovedShareHolders = (data) => {
        this.setState({
            DeletedShareHolders: data
        });

    }
    getNonHiddenRequiredFields(form) {
        const formElements = form.elements;
        const requiredFields = Array.from(formElements).filter((element) => {
            if (element.tagName.toLowerCase() === 'input') {
                return element.required && element.type !== 'hidden';
            }
            return false
        });
        return requiredFields;
    }
    getNonHiddenUnfilledRequiredFields(form) {
        const formElements = form.elements;
        const unfilledRequiredFields = Array.from(formElements).filter((element) => {
            if (element.tagName.toLowerCase() === 'input') {
                if (element.type === 'checkbox') {

                    return element.required && !element.checked;
                }
                return element.required && element.type !== 'hidden' && element.value.trim() === '';
            }
            return false;
        });
        return unfilledRequiredFields;
    }
    getNonHiddenRequiredFieldsFromAllForms() {
        const forms = document.getElementsByTagName('form');
        const nonHiddenUnfilledRequiredFields = [];

        for (let i = 0; i < forms.length; i++) {
            const form = forms[i];
            const formElements = form.elements;
            const unfilledRequiredFields = Array.from(formElements).filter((element) => {
                if ((element.tagName.toLowerCase() === 'input' || element.tagName.toLowerCase() === 'select')) {
                    return element.required;
                }
                return false;
            });

            nonHiddenUnfilledRequiredFields.push(...unfilledRequiredFields);
        }

        return nonHiddenUnfilledRequiredFields;
    }
    getNonHiddenUnfilledRequiredFieldsFromAllForms() {
        const forms = document.getElementsByTagName('form');
        const nonHiddenUnfilledRequiredFields = [];

        for (let i = 0; i < forms.length; i++) {
            const form = forms[i];
            const formElements = form.elements;
            const unfilledRequiredFields = Array.from(formElements).filter((element) => {

                if ((element.tagName.toLowerCase() === 'input' || element.tagName.toLowerCase() === 'select')) {
                    if (element.type === 'checkbox') {
                        return element.required && !element.checked;
                    }
                    return element.required && element.value.trim() === '';
                }
                return false;
            });

            nonHiddenUnfilledRequiredFields.push(...unfilledRequiredFields);
        }


        return nonHiddenUnfilledRequiredFields;
    }
    getPercentageFilledOut() {
        const NonHiddenReqiredFeilds = this.getNonHiddenRequiredFieldsFromAllForms();
        const UnfilledRequiredFields = this.getNonHiddenUnfilledRequiredFieldsFromAllForms();

        const PercentageCompleted = 1 - UnfilledRequiredFields.length / NonHiddenReqiredFeilds.length;
        this.setState({ progress: Math.floor(PercentageCompleted * 100) });
    }

    render() {
        const { t, i18n } = this.props;
        var IsApplication = false
        if (this.state.IsDetails || this.state.IsOwner || this.state.IsDirector || this.state.IsSupport || this.state.IsBank) {
            IsApplication = true;
        }

        if (!this.state.Isloaded) {
            return (
                <main role="main">
                    <div className={styles.ContentContainer}>
                        <ReactModal
                            isOpen={this.state.IsOpen}>
                            <img alt="icon" src="/images/fail-icon.svg" />
                            <h1 id="heading">{this.state.Header}</h1>
                            <div id="full_description">
                                <p>
                                    {translateText(this.state.Description, i18n.language)}

                                </p>
                                <Button title={t('mainContainer.okay')} buttonColor="white" containerDisplay="minor" onClickHandler={this.CloseModal} />
                            </div>
                        </ReactModal>
                    </div>
                </main>
            )
        }
        else {
            return (
                <FormContext.Provider value={this.state.FormContext}>
                    <header>
                        <Header title="Onboarding Application" CompanyName={this.state.PartnerName} IsWhiteLabel={this.state.IsWhiteLabel} Background={this.state.SecondaryColor} Image={this.state.Image} />
                    </header>
                    <main role="main">
                        <div className={styles.ContentContainer}>
                            <ReactModal
                                isOpen={this.state.IsOpen}>
                                <img alt="icon" src="/images/fail-icon.svg" />
                                <h1 id="heading">{this.state.Header}</h1>
                                <div id="full_description">
                                    <p>
                                        {this.state.Description}
                                    </p>
                                    <Button title={t('mainContainer.okay')} buttonColor="white" containerDisplay="minor" onClickHandler={this.CloseModal} />
                                </div>
                            </ReactModal>
                            <Menu IsWhiteLabel={this.state.IsWhiteLabel} progress={this.state.progress} PrimaryColor={this.state.PrimaryColor}>
                                <MenuItem Active={this.state.IsOverview}>
                                    <img alt="building" src="/images/building.svg" />
                                    <button onClick={(e) => { this.MoveToOverview(e) }}>{t('menu.overview')}</button>
                                </MenuItem>
                                <MenuItem >
                                    <img alt="building" src="/images/pen.svg" />
                                    <button onClick={(e) => { this.MoveToBusinessDetails(e) }}>{t('menu.clientApplication')}</button>
                                </MenuItem>
                                <SubMenuItem Active={this.state.IsDetails}>
                                    {this.state.IsDetailsError && <img alt="building" src="/images/warning-icon.svg" />}
                                    {!this.state.IsDetailsError && this.state.IsDetailsTriggered && <img alt="building" src="/images/success.svg" />}
                                    <button onClick={(e) => { this.MoveToBusinessDetails(e) }}>{t('menu.businessDetails')}</button>
                                </SubMenuItem>
                                {this.state.FormContext.BusinessData.BusinessTypeCategoryName === "Loans or lending" &&
                                    <SubMenuItem Active={this.state.IsLenderInfo}>
                                        {this.state.IsLenderInfoError && <img alt="building" src="/images/warning-icon.svg" />}
                                        {!this.state.IsLenderInfoError && this.state.IsLenderInfoTriggered && <img alt="building" src="/images/success.svg" />}
                                        <button onClick={(e) => { this.MoveToLendingInformation(e) }}>{t('menu.lenderInformation')}</button>
                                    </SubMenuItem>
                                }
                                {this.state.FormContext.BusinessData.OrganizationalType === "Registered Charity" &&
                                    <SubMenuItem Active={this.state.IsDonations} >
                                        {this.state.IsDonationsError && <img alt="building" src="/images/warning-icon.svg" />}
                                        {!this.state.IsDonationsError && this.state.IsDonationsTriggered && <img alt="building" src="/images/success.svg" />}
                                        <button onClick={(e) => { this.MoveToDonations(e) }}>{t('menu.antiMoneyLaundering')}</button>
                                    </SubMenuItem>
                                }
                                {this.state.FormContext.BusinessData.OrganizationalType !== "Registered Charity" &&
                                    <SubMenuItem Active={this.state.IsOwner}  >
                                        {this.state.IsOwnerError && <img alt="building" src="/images/warning-icon.svg" />}
                                        {!this.state.IsOwnerError && this.state.IsOwnerTriggered && <img alt="building" src="/images/success.svg" />}
                                        <button onClick={(e) => { this.MoveToShareHolder(e) }}>{t('menu.ownershipStucture')}</button>
                                    </SubMenuItem>
                                }
                                <SubMenuItem Active={this.state.IsDirector}  >

                                    {this.state.IsDirectorError && <img alt="building" src="/images/warning-icon.svg" />}
                                    {!this.state.IsDirectorError && this.state.IsDirectorTriggered && <img alt="building" src="/images/success.svg" />}
                                    <button onClick={(e) => { this.MoveToSigningAuthorities(e) }}>{t('menu.signingAuthorites')}</button>
                                </SubMenuItem>
                                <SubMenuItem Active={this.state.IsSupport} >
                                    {this.state.IsSupportError && <img alt="building" src="/images/warning-icon.svg" />}
                                    {!this.state.IsSupportError && this.state.IsSupportTriggered && <img alt="building" src="/images/success.svg" />}
                                    <button onClick={(e) => { this.MoveToContactInfo(e) }}>{t('menu.supportingAndContactInformation')}</button>
                                </SubMenuItem>
                                {(() => {

                                    return (
                                        <SubMenuItem Active={this.state.IsBank} >
                                            {this.state.IsBankError && <img alt="building" src="/images/warning-icon.svg" />}
                                            {!this.state.IsBankError && this.state.IsBankTriggered && <img alt="building" src="/images/success.svg" />}
                                            <button onClick={(e) => { this.LinkBankAccount(e) }}>{t('menu.bankAndBilling')}</button>
                                        </SubMenuItem>
                                    );

                                })()}
                                <MenuItem Active={this.state.IsConfermation}>
                                    <img alt="building" src="/images/badge.svg" />
                                    <button onClick={(e) => { this.MoveToConfermation(e) }}>{t('menu.confirmation')}</button>
                                </MenuItem>
                            </Menu>
                            <div className={styles.ContentArea}>

                                <div id="OverviewContainer">
                                    <OverviewContainer PrimaryColor={this.state.PrimaryColor} IsParentAccount={this.state.IsPartnerToken} nextHandler={this.MoveToBusinessDetails} Name={this.state.Name} />
                                </div>
                                <form id="BusinessDetailsContainer">
                                    <BusinessDetailsContainer
                                        PrimaryColor={this.state.PrimaryColor}
                                        IsFinancials={this.state.IsFinancials}
                                        nextHandler={(e) => {
                                            if (this.state.FormContext.BusinessData.BusinessTypeCategoryName === "Loans or lending") {
                                                this.MoveToLendingInformation(e);
                                            }
                                            else if (this.state.FormContext.BusinessData.OrganizationalType === "Registered Charity") {
                                                this.MoveToDonations(e);
                                            }
                                            else {
                                                this.MoveToShareHolder(e);
                                            }
                                        }}
                                        prevHandler={this.MoveToOverview} />
                                </form>
                                <form id="LenderInformationContainer">
                                    {this.state.FormContext.BusinessData.BusinessTypeCategoryName === "Loans or lending" &&
                                        <LenderInformationContainer
                                            PrimaryColor={this.state.PrimaryColor}
                                            nextHandler={(e) => {
                                                if (this.state.FormContext.BusinessData.OrganizationalType === "Registered Charity") {
                                                    this.MoveToDonations(e);
                                                }
                                                else {
                                                    this.MoveToShareHolder(e);
                                                }
                                            }}
                                            prevHandler={this.MoveToBusinessDetails} />
                                    }
                                </form>
                                <form id="DonationsContainer">
                                    {this.state.FormContext.BusinessData.OrganizationalType === "Registered Charity" &&
                                        <DonationsContainer
                                            nextHandler={this.MoveToSigningAuthorities}
                                            PrimaryColor={this.state.PrimaryColor}
                                            prevHandler={(e) => {
                                                if (this.state.FormContext.BusinessData.BusinessTypeCategoryName === "Loans or lending") {
                                                    this.MoveToLendingInformation(e);
                                                }
                                                else {
                                                    this.MoveToBusinessDetails(e);
                                                }
                                            }} />
                                    }
                                </form>
                                <form id="ShareHolderInformationContainer">
                                    {this.state.FormContext.BusinessData.OrganizationalType !== "Registered Charity" &&
                                        <ShareHolderInformationContainer
                                            updateRemovedShareHolders={this.updateRemovedShareHolders}
                                            nextHandler={this.MoveToSigningAuthorities}
                                            Shareholders={this.state.Shareholder}
                                            PrimaryColor={this.state.PrimaryColor}
                                            prevHandler={(e) => {
                                                if (this.state.FormContext.BusinessData.BusinessTypeCategoryName === "Loans or lending") {
                                                    this.MoveToLendingInformation(e);
                                                }
                                                else {
                                                    this.MoveToBusinessDetails(e);
                                                }
                                            }} />
                                    }
                                </form>
                                <form id="SigningAuthoritiesContainer">
                                    <SigningAuthoritiesContainer PrimaryColor={this.state.PrimaryColor} updateRemovedSigningAuthorites={this.updateRemovedSigningAuthorites} SigningAuthorities={this.state.SigningAuthority} nextHandler={this.MoveToContactInfo} prevHandler={
                                        (e) => {
                                            if (this.state.FormContext.BusinessData.OrganizationalType === "Registered Charity") {
                                                this.MoveToDonations(e);
                                            }
                                            else {
                                                this.MoveToShareHolder(e);
                                            }
                                        }}
                                    />
                                </form>

                                {(() => {
                                    return (
                                        <form id="ContactInformationContainer">
                                            <ContactInformationContainer PrimaryColor={this.state.PrimaryColor} SubAccountType={this.state.SubAccountType} PartnerAccountType={this.state.PartnerAccountType} nextHandler={this.LinkBankAccount} prevHandler={this.MoveToSigningAuthorities} />
                                        </form>
                                    );
                                })()}
                                <form id="BankAccountInformation">
                                    {
                                        <LinkBankAccountContainer PrimaryColor={this.state.PrimaryColor} IframeUrl={this.state.IframeUrl} nextHandler={this.MoveToConfermation} prevHandler={this.MoveToContactInfo} SaveFormProgress={this.SaveFormProgress} />
                                    }
                                </form>
                                {(() => {
                                    return (
                                        <form id="ConfermationContainer">
                                            <ConfermationContainer IsFinancials={this.state.IsFinancials} PrimaryColor={this.state.PrimaryColor} SubAccountType={this.state.SubAccountType} PartnerAccountType={this.state.PartnerAccountType} prevHandler={this.LinkBankAccount} nextHandler={this.MoveToComplete} BusinessDetails={this.state.BusinessDetails} ContactDetails={this.state.ContactDetails} BankingDetails={this.state.BankingDetails} Shareholders={this.state.ShareHolders} SigningAuthorities={this.state.SigningAuthorities} DonationsDetails={this.state.DonationsDetails} LenderInformation={this.state.LenderInformation} />
                                        </form>
                                    );
                                })()}
                                {this.state.LastSaved && !this.state.IsOverview &&
                                    <div className={styles.LastSaved}>
                                        {t('mainContainer.lastSaved')} {this.state.LastSaved}
                                    </div>}

                                <div id="submitting" className={styles.Complete}>
                                    <div className={styles.CompleteContainer}>
                                        <h2 className={styles.CompleteHeader}>{t('mainContainer.submittingFiles')}</h2>
                                        <div className={styles.loader}></div>
                                    </div>
                                </div>
                                <div id="complete" className={styles.Complete}>
                                    <div className={styles.CompleteContainer}>
                                        <h2 className={styles.CompleteHeader}>{t('mainContainer.thankYouMessage')}</h2>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </main>
                    <Footer IsWhiteLabel={this.state.IsWhiteLabel} />
                </FormContext.Provider>
            );
        }
    }
}
const TranslatedClassComponent = withTranslation()(MainContainer);
export default TranslatedClassComponent;