import React from 'react';
import styles from './DigitalSignature.module.css';
export default class DigitalSignature extends React.Component {

    render() {
      return (
        <div className={styles.SignatureBox}>
            <h3 className={styles.SignatureHeader}>Digital Signature</h3>
            <ul className={styles.SignatureItems}>
                <li>{this.props.SignerName}</li>
                <li>{this.props.TimeStamp}</li>
                <li>{this.props.IPAddress}</li>
                <li>{this.props.Location}</li>
                <li>{this.props.Platform}</li>
                <li>{this.props.OS}</li>
                <li>{this.props.Browser}</li>
            </ul>
        </div>
      );
    }
  }