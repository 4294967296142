import React from 'react';
import styles from '../LinkBankAccountContainer/LinkBankAccountContainer.module.css';
import { FormContext } from '../../context/FormContext';
import { withTranslation } from 'react-i18next';

class BankingDetailsInfo extends React.Component {
  imgError(e) {
    // this will step up to get the parent of the image's parent
    var image = e.target;
    image.remove();
    return true;
  }

  render() {
    const { t } = this.props;
    var image = "https://embed-dev.vopay.com/images/logos-banks/1835.svg";
    if (this.props.Data.BankId >= 1681 && this.props.Data.BankId <= 1729) {
      image = "https://embed-dev.vopay.com/images/logos-banks/uni.svg";
    } else if (this.props.Data.BankId >= 1823 && this.props.Data.BankId <= 1835) {
      image = "https://embed-dev.vopay.com/images/logos-banks/1835.svg";
    } else {
      image = "https://embed-dev.vopay.com/images/logos-banks/" + this.props.Data.BankId + ".svg";
    }
    const imageCreditCard = "https://embed-dev.vopay.com/images/" + this.props.Data.CreditCardBrand + ".png";

    return (
      this.props.Data &&
      <div>
        <h3 className="title__minor">{t('bankingDetailsInfo.securityDeposit')}</h3>
        {this.props.Data.Iq11Token &&
          <div className={styles.Card}>
            <div className={styles.CardBankingInfo}>
              {this.props.Data.BankId && <img className={styles.CardLogo} src={image} onError={e => this.imgError(e)} alt="logo" />}
              {this.props.Data.institution} {this.props.Data.AccountNumber}
            </div>
          </div>
        }

        <h3 className="title__minor">{t('bankingDetailsInfo.setUpMonthlyFee')}</h3>
        {this.props.Data.DefaultPaymentMethod === "bank" &&
          <div className={styles.Card}>
            <div className={styles.CardBankingInfo}>
              {this.props.Data.BankId && <img className={styles.CardLogo} src={image} onError={e => this.imgError(e)} alt="logo" />}
              {this.props.Data.institution} {this.props.Data.AccountNumber}
            </div>
          </div>
        }
        {this.props.Data.DefaultPaymentMethod === "credit" &&
          <div className={styles.Card}>
            <div className={styles.CardBankingInfo}>
              <img className={styles.CardLogo} onError={e => this.imgError(e)} src={imageCreditCard} alt="logo" />
              {t('bankingDetailsInfo.endingIn')} {this.props.Data.CreditCardMaskedNumber.slice(-4)}
            </div>
          </div>
        }
        {this.props.Data.ConnectionType === "manual" &&
          <div>
            <h3 className="title__minor">{t('bankingDetailsInfo.uploadedBankStatements')}</h3>
            <ul className={styles.BankStatmentList}>
              {this.context.DocumentsBanks.map(function (item) {
                return <li>{item}</li>
              })}
            </ul>
          </div>
        }
      </div>
    );
  }
}

BankingDetailsInfo.contextType = FormContext;

export default withTranslation()(BankingDetailsInfo);