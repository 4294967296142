import React from 'react';
import { withTranslation } from 'react-i18next';
import Input from './../../components/Input/Input';
import PhoneNumberInput from '../../components/PhoneNumberInput/PhoneNumberInput';
import Checkbox from '../../components/Checkbox/Checkbox';
import FormElement from '../../components/FormElement/FormElement';
import styles from './ContactInformation.module.css';
import { FormContext } from '../../context/FormContext';
import Row from '../../components/Row/Row';
import $ from 'jquery';

class ContactInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      IsNotPrimary: true,
      ClearValue: '',
    };
  }

  PrimaryChange = (e) => {
    $("#" + this.props.FullNameFeild).parsley().reset();
    $("#" + this.props.TitleFeild).parsley().reset();
    $("#" + this.props.EmailFeild).parsley().reset();
    $("#" + this.props.PhoneFeild + "hidden").parsley().reset();
    if (e.target.checked) {
      this.setState({
        IsNotPrimary: false,
      });
    } else {
      this.setState({
        IsNotPrimary: true,
      });
    }
  };

  ContextChanged = (e) => {
    this.context.UpdateContactInformation({ [e.target.name]: e.target.value });
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.context.ContactInformation[this.props.FullNameFeild] ===
        this.context.ContactInformation['PrimaryContactFullName'] &&
      this.context.ContactInformation[this.props.TitleFeild] ===
        this.context.ContactInformation['PrimaryContactTitle'] &&
      this.context.ContactInformation[this.props.EmailFeild] ===
        this.context.ContactInformation['PrimaryContactEmail'] &&
      this.context.ContactInformation[this.props.PhoneFeild] ===
        this.context.ContactInformation['PrimaryContactPhoneNumber'] &&
      (this.context.ContactInformation['PrimaryContactFullName'] ||
        this.context.ContactInformation['PrimaryContactTitle'] ||
        this.context.ContactInformation['PrimaryContactEmail'] ||
        this.context.ContactInformation['PrimaryContactEmail'])
    ) {
      this.setState({
        IsNotPrimary: false,
      });
      this.context.UpdateContactInformation({
        [this.props.FullNameFeild]: '',
        [this.props.TitleFeild]: '',
        [this.props.EmailFeild]: '',
        [this.props.PhoneFeild]: '',
      });
    }
  };

  render() {
    const { t } = this.props;

    if (this.state.IsNotPrimary) {
      return (
        <div id={this.props.FullNameFeild + 'Container'}>
          <h2 className="form__sectionHeader">{this.props.name}</h2>
          <div className="form__element">
            <label className={styles.cheboxText} htmlFor={this.props.FullNameFeild + 'Checkbox'}>
              {t('contactInformation.sameAsPrimary')}
            </label>{' '}
            <Checkbox onChangeCheck={this.PrimaryChange} id={this.props.FullNameFeild + 'Checkbox'} />
          </div>
          <FormElement
            label={t('contactInformation.fullNameLabel')}
            htmlFor={this.props.FullNameFeild}
            feild={
              <Input
                name={this.props.FullNameFeild}
                id={this.props.FullNameFeild}
                value={this.context.ContactInformation[this.props.FullNameFeild]}
                HandleChange={this.ContextChanged}
                pattern="^(?!.*(.{100}|[^a-zA-ZÀÂÉÊÈËÌÏÎÔÙÛÇÆŒàâéêèëìïîôùûçæœ 0-9=$,& \)\(\.\*\-\'\/])).*"
                required={true}
                placeholder={t('contactInformation.fullNamePlaceholder')}
              />
            }
            visible={true}
          />

          <Row
            items={[
              <FormElement
                label={t('contactInformation.titleLabel')}
                htmlFor={this.props.TitleFeild}
                feild={
                  <Input
                    name={this.props.TitleFeild}
                    id={this.props.TitleFeild}
                    value={this.context.ContactInformation[this.props.TitleFeild]}
                    HandleChange={this.ContextChanged}
                    pattern="^[a-zA-Z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\)\(.\-!@#$%^&*()_\+={}[\]\|;:?']{1,40}$"
                    required={true}
                    placeholder={t('contactInformation.titlePlaceholder')}
                  />
                }
                visible={true}
              />,
              <FormElement
                label={t('contactInformation.emailLabel')}
                htmlFor={this.props.EmailFeild}
                feild={
                  <Input
                    name={this.props.EmailFeild}
                    id={this.props.EmailFeild}
                    value={this.context.ContactInformation[this.props.EmailFeild]}
                    HandleChange={this.ContextChanged}
                    required={true}
                    type="email"
                    placeholder={t('contactInformation.emailPlaceholder')}
                  />
                }
                visible={true}
              />,
            ]}
          />
          <Row
            items={[
              <FormElement
                label={t('contactInformation.phoneLabel')}
                htmlFor={this.props.PhoneFeild}
                feild={
                  <PhoneNumberInput
                    required={true}
                    value={this.context.ContactInformation[this.props.PhoneFeild]}
                    HandleChange={this.ContextChanged}
                    name={this.props.PhoneFeild}
                    id={this.props.PhoneFeild}
                  />
                }
                visible={true}
              />,
              <></>,
            ]}
          />
        </div>
      );
    } else {
      return (
        <div id={this.props.FullNameFeild + 'Container'}>
          <h2 className="form__sectionHeader">{this.props.name}</h2>
          <div className="form__element">
            <label className={styles.cheboxText} htmlFor={this.props.FullNameFeild + 'Checkbox'}>
              {t('contactInformation.sameAsPrimary')}
            </label>{' '}
            <Checkbox checked="checked" id={this.props.FullNameFeild + 'Checkbox'} onChangeCheck={this.PrimaryChange} />
          </div>
          <FormElement
            label={t('contactInformation.fullNameLabel')}
            htmlFor={this.props.FullNameFeild}
            feild={
              <Input
                name={this.props.FullNameFeild}
                id={this.props.FullNameFeild}
                disabled="disabled"
                value={this.state.ClearValue}
                pattern="^(?!.*(.{100}|[^a-zA-ZÀÂÉÊÈËÌÏÎÔÙÛÇÆŒàâéêèëìïîôùûçæœ 0-9=$,& \)\(\.\*\-\'\/])).*"
                placeholder={t('contactInformation.fullNamePlaceholder')}
              />
            }
            visible={true}
          />

          <Row
            items={[
              <FormElement
                label={t('contactInformation.titleLabel')}
                htmlFor={this.props.TitleFeild}
                feild={
                  <Input
                    name={this.props.TitleFeild}
                    id={this.props.TitleFeild}
                    disabled="disabled"
                    value={this.state.ClearValue}
                    pattern="^[a-zA-Z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\)\(.\-!@#$%^&*()_\+={}[\]\|;:?']{1,40}$"
                    placeholder={t('contactInformation.titlePlaceholder')}
                  />
                }
                visible={true}
              />,
              <FormElement
                label={t('contactInformation.emailLabel')}
                htmlFor={this.props.EmailFeild}
                feild={
                  <Input
                    name={this.props.EmailFeild}
                    id={this.props.EmailFeild}
                    disabled="disabled"
                    value={this.state.ClearValue}
                    type="email"
                    placeholder={t('contactInformation.emailPlaceholder')}
                  />
                }
                visible={true}
              />,
            ]}
          />

          <Row
            items={[
              <FormElement
                label={t('contactInformation.phoneLabel')}
                htmlFor={this.props.PhoneFeild}
                feild={
                  <PhoneNumberInput
                    required={false}
                    id={this.props.PhoneFeild}
                    disabled={true}
                    name={this.props.PhoneFeild}
                    value={this.state.ClearValue}
                  />
                }
                visible={true}
              />,
              <></>,
            ]}
          />
        </div>
      );
    }
  }
}

ContactInformation.contextType = FormContext;

export default withTranslation()(ContactInformation);
