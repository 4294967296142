import React from 'react';
import styles from './InfoTable.module.css';
export default class InfoTable extends React.Component {
    
    render() {
        return(
           <table className={styles.Table}>
               {this.props.children}
           </table>
        );
    }
  }