
import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import styles from './Progress.module.css';
export default class Input extends React.Component {
 
  render() {
    const { progress = 0, width = "256px", height = "252px", pathStrokeColor, trailStrokeColor = "#C4C4C4", arrowPosition } = this.props;

    return (
        
        <div className={styles.progressContainer} style={{width: width, height: height}}> 

        <div className={styles.textContainer}>{progress}%</div>

        <div className={styles.iconContainer} style={{top: arrowPosition}}>
            <img  src="/images/arrow-progress.svg" alt="arrow right"/>
        </div>
        <CircularProgressbar 
            value={progress} 
            strokeWidth={15} 
            styles={{
                path: {
                    stroke: `${pathStrokeColor}`,
                    strokeLinecap: 'round',
                },
                trail: {
                stroke: trailStrokeColor,
                strokeLinecap: 'round',
                },
                text: {
                fill: '#000',
                fontSize: '16px',
                },
            }}
        />
        </div>
    );
  }
}