import React from 'react';
import styles from './SubMenuItem.module.css';
export default class SubMenuItem extends React.Component {
    render() {

        return (
            <li className={styles.menuItemSub}>
                {(() => {
                    if(this.props.Active) {
                        return (<span className={[styles.menuItemActive, this.props.Error ? styles.menuItemError : ""].join(" ")} >
                           {this.props.children}</span>);
                        
                    }
                    else{
                        return (<span className={[this.props.Error ? styles.menuItemError : ""].join(" ")} >
                                {this.props.children}
                            </span>);
                    }
                })()}
                
            </li>
        );
    }
  }