import React from 'react';
import Button from './../../components/Button/Button';
import styles from './AgreementDetailsContainer.module.css';
import {GetBillingPackages} from "./../../service/api";
export default class AgreementDetailsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state ={AssignedBillingPackage: {}}
        
    }
    componentWillMount(){
        let self = this;
        GetBillingPackages().then(
            data =>{
                self.setState({
                    AssignedBillingPackage: data.AssignedBillingPackage,
        
                })
            },
            error =>{
              
            });
    }
    render() {

        return (
            <div>
                <h1 className="title">Agreement Details </h1>
              
                <p className="description">
                    This Order Form is entered into between the Customer identified below and VoPay International Inc. (“VoPay”) as of the effective date set out below (the “Effective Date”).  This Order Form, together with the VoPay Terms of Service, which is available at <a href="https://vopay.com/ca/legal/tos" rel="noreferrer" target="_blank">VoPay TOS</a> (the “Terms of Service”), are collectively referred to as this “Agreement”. 
                </p>
               


                <ul className={styles.AgreementList}>
                    <li>Customer: <span className={styles.AgreementListAmount}>{this.props.Name}</span></li>
                    <li>Effective Date: <span className={styles.AgreementListAmount}>Upon account activation</span></li>
                    <li>Subscription Term: <span className={styles.AgreementListAmount}>1 year from the Effective Date</span></li>
                    {(() =>{
                        if(this.state.AssignedBillingPackage.SetupFee &&  this.state.AssignedBillingPackage.SetupFee  !== null && this.state.AssignedBillingPackage.SetupFee  !== "0.00"){
                            return(<li>Setup Fee: <span className={styles.AgreementListAmount}>${this.state.AssignedBillingPackage.SetupFee}</span></li>);
                        }
                    })()}
                    <li>Minimum Monthly Transaction Fee:<sup>[1]</sup> <span className={styles.AgreementListAmount}>${this.state.AssignedBillingPackage.MinimumProgramFee}</span></li>
                    <li>Monthly Platform Licence Fee: <span className={styles.AgreementListAmount}>${this.state.AssignedBillingPackage.PlatformLicenceFee}</span></li>
                    <li>Security Deposit:<sup>[2]</sup> <span className={styles.AgreementListAmount}>${this.state.AssignedBillingPackage.SecurityDeposit}</span></li>
                    {(() =>{
                         // eslint-disable-next-line
                        if( this.state.AssignedBillingPackage.InvoiceDueDate  == "1"){ 
                            return(
                                <li>Payment Terms: <span className={styles.AgreementListAmount}>Due upon receipt</span></li>
                            )
                        }
                        else{
                            return(
                                <li>Payment Terms: <span className={styles.AgreementListAmount}>Net {this.state.AssignedBillingPackage.InvoiceDueDate}</span></li>
                            )
                        }
                    })()}
                    {(() =>{
                        if( this.state.AssignedBillingPackage.NoMinimumProgramFeePeriod && this.state.AssignedBillingPackage.NoMinimumProgramFeePeriod  !== null && this.state.AssignedBillingPackage.NoMinimumProgramFeePeriod  !== "0"){
                            if(this.state.AssignedBillingPackage.NoMinimumProgramFeePeriod  === "1"){
                                return(<li>No Minimum Program Fee Period: <span className={styles.AgreementListAmount}>{this.state.AssignedBillingPackage.NoMinimumProgramFeePeriod} Month</span></li>);
                            }
                            else{
                                return(<li>No Minimum Program Fee Period: <span className={styles.AgreementListAmount}>{this.state.AssignedBillingPackage.NoMinimumProgramFeePeriod} Months</span></li>);
                            }
                            
                        }
                    })()}
                    {(() =>{
                        if(  this.state.AssignedBillingPackage.NoPlatformLicenceFeePeriod && this.state.AssignedBillingPackage.NoPlatformLicenceFeePeriod  !== null && this.state.AssignedBillingPackage.NoPlatformLicenceFeePeriod  !== "0"){
                            if(this.state.AssignedBillingPackage.NoPlatformLicenceFeePeriod  === "1"){
                                return(<li>No Platform Licence Fee Period: <span className={styles.AgreementListAmount}>{this.state.AssignedBillingPackage.NoPlatformLicenceFeePeriod} Month</span></li>);
                            }
                            else{
                                return(<li>No Platform Licence Fee Period: <span className={styles.AgreementListAmount}>{this.state.AssignedBillingPackage.NoPlatformLicenceFeePeriod} Months</span></li>);
                            
                            }
                        }
                    })()}
                </ul>
                <p className={styles.TermsFooter}>
                    1 The greater of ( a ) the sum of the transaction fee and ( b ) the monthly minimum fee is billed to the customer on a monthly basis. 
                </p>
                <p className={styles.TermsFooter}>
                    2 Security Deposit is determined by VoPay as a function of the volume, value and type of transactions. This amount may change from time to time if the transaction profile changes.
                </p>
                <div className="buttonBar">
                <Button title="Back" width="50Left" onClickHandler={this.props.prevHandler}/>
                <Button title="Next" width="50Right"  onClickHandler={this.props.nextHandler}/>
                </div>
            </div>
        );
    }
  }