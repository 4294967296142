import React from 'react';
import Button from './../../components/Button/Button';
import SigningAuthoritiesForm from  './../SigningAuthorityForm/SigningAuthoritiesForm';
import { withTranslation } from 'react-i18next';

class SigningAuthoritiesContainer extends React.Component {
    constructor(props) {
        super(props);
        if(Object.keys(props.SigningAuthorities || {}).length > 0 ){
            this.state ={
                FormNum: Object.keys(props.SigningAuthorities || {}).length,
                FormIds: [...Array(Object.keys(props.SigningAuthorities || {}).length).keys()],
                SigningAuthorities: {...props.SigningAuthorities},
                RemovedIds: [],
            }
        }
        else{
            this.state ={
                FormNum: 1,
                FormIds: [0],
                SigningAuthorities: {0:{ID: '', FullName: '', DOB: '', Occupation: '', EmailAddress: '', HomeAddress:''}},
                RemovedIds: [],
            }
        }
       
    }
    componentWillReceiveProps = (nextProps) => {
        if(nextProps.SigningAuthorities !== this.props.SigningAuthorities){
            if(Object.keys(nextProps.SigningAuthorities || {}).length > 0 ){
                this.setState({
                    FormNum: Object.keys(nextProps.SigningAuthorities || {}).length,
                    SigningAuthorities: {...nextProps.SigningAuthorities},
                    FormIds: [...Array(Object.keys(nextProps.SigningAuthorities || {}).length).keys()]
                });
            }
            else{
                this.setState({
                    FormNum: 1,
                    SigningAuthorities: {0:{ID: '', FullName: '', DOB: '', Occupation: '', EmailAddress: '', HomeAddress:''}},
                    FormIds: [0]
                })
            }
            this.props.updateRemovedSigningAuthorites([]);
        }
    }
    filterObject(obj){
        const asArray = Object.entries(obj);
        const filtered = asArray.filter(([key, value]) => !this.state.RemovedIds.includes(value.ID));
        return Object.fromEntries(filtered);
    }
    DataChanged =(Itemkey, ObjectKey, value) => {
        const SigningAuthorities = {...this.state.SigningAuthorities};
        SigningAuthorities[Itemkey][ObjectKey] = value;
        this.setState({
            SigningAuthorities: SigningAuthorities
        })
    }
    render() {
        let self = this;
        function AddAnotherForm(e){
            e.preventDefault();
            const SigningAuthorities = {...self.state.SigningAuthorities}
            SigningAuthorities[self.state.FormNum] = {ID: '', FullName: '', DOB: '', Occupation: '', EmailAddress: '', HomeAddress:''};
            self.setState({
                FormNum: (self.state.FormNum+1),
                FormIds:  [...self.state.FormIds, self.state.FormNum],
                SigningAuthorities: SigningAuthorities
            })
            
            return false;
        }
        function RemoveAnotherForm(key, Id){
            const splicedArray = [...self.state.FormIds].filter(v => v !== parseInt(key))
            const removedArray = Id ? [...self.state.RemovedIds, Id] : [...self.state.RemovedIds];
            const SigningAuthorities = {...self.state.SigningAuthorities};
            delete SigningAuthorities[key]
            self.props.updateRemovedSigningAuthorites(removedArray);
            self.setState({
                FormIds: splicedArray,
                RemovedIds: removedArray,
                SigningAuthorities: SigningAuthorities
            })
            return false;
        }
        let rows = [];
        let SigningAuthorities = this.state.SigningAuthorities;
        const { t } = this.props;
        for (let i in this.state.FormIds){
            // note: we are adding a key prop here to allow react to uniquely identify each
            // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
            
            rows.push(<SigningAuthoritiesForm ContactNumber={i} ItemKey={this.state.FormIds[i]} DataChanged={this.DataChanged} RemoveAnotherForm={RemoveAnotherForm} AddAnotherForm={AddAnotherForm} length={this.state.FormIds.length} SigningData={SigningAuthorities ? (SigningAuthorities[this.state.FormIds[i]] ?   SigningAuthorities[this.state.FormIds[i]] : {}) : {}} />);
        }
        return (
            <div>
                 <h1 className="title">{t('signingAuthoritiesContainer.title')}</h1>
                <p className="description">{t('signingAuthoritiesContainer.description')}</p>
                {rows}
                <div className="buttonBar">
                <Button title={t('button.back')} width="50Left" backgroundColor={this.props.PrimaryColor} onClickHandler={this.props.prevHandler}/>
                <Button title={t('button.next')} width="50Right" backgroundColor={this.props.PrimaryColor}  onClickHandler={this.props.nextHandler}/>
                </div>
            </div>
        );
    }
  }
  
  export default withTranslation()(SigningAuthoritiesContainer);
