import React from 'react';
import Input from './../../components/Input/Input';
import RadioButton from './../../components/RadioButton/RadioButton';
import FormElement from '../../components/FormElement/FormElement';
import FileUpload from './../../components/FileUpload/FileUpload';
import { initializeGoogleAddress } from "./../../service/tools";
import AddAnotherButton from './../../components/AddAnotherButton/AddAnotherButton';
import $ from 'jquery';
import Row from '../../components/Row/Row';
import { FormContext } from '../../context/FormContext';
/* eslint-disable no-unused-vars */
import parsley from 'parsleyjs';
/* eslint-disable no-unused-vars */
import { withTranslation } from 'react-i18next';
class ShareHolderForm extends React.Component {
    constructor(props) {
        super(props);
        var key = this.props.ItemKey
        this.state = {
            OwnerType: 'Individual',
            CountryId: "ShareCountry" + key,
            ProvinceId: "ShareProvince" + key,
            CityId: "ShareCity" + key,
            PostalCodeId: "SharePostalCode" + key,
            AddressId: "ShareAddress" + key,
            accountTypeName: "accountType" + key,
            IsBlank: true
        }

    }
    MoveToIndividual = (e) => {
        var key = this.props.ItemKey
        this.props.DataChanged(this.props.ItemKey, "OwnerType", 'Individual');
        this.setState({
            OwnerType: 'Individual',
            CountryId: "ShareCountry" + key,
            ProvinceId: "ShareProvince" + key,
            CityId: "ShareCity" + key,
            PostalCodeId: "SharePostalCode" + key,
            AddressId: "ShareAddress" + key,
            accountTypeName: "accountType" + key,
        })
    }
    MoveToBusiness = (e) => {
        var key = this.props.ItemKey
        this.props.DataChanged(this.props.ItemKey, "OwnerType", 'Company');
        this.setState({
            OwnerType: 'Company',
            CountryId: "ShareCountry" + key,
            ProvinceId: "ShareProvince" + key,
            CityId: "ShareCity" + key,
            PostalCodeId: "SharePostalCode" + key,
            AddressId: "ShareAddress" + key,
            accountTypeName: "accountType" + key,
        })
        initializeGoogleAddress("ShareAddress" + key, "ShareCountry" + key, "ShareProvince" + key, "ShareCity" + key, "SharePostalCode" + key);
        //this.props.IsBusiness =e.target.checked;
    }
    onFocusOutAddress = (e) => {
        var key = this.props.ItemKey;
        setTimeout(function () {
            let address = $("#ShareAddress" + key).val();
            let city = $("#ShareCity" + key).val();
            let state = $("#ShareProvince" + key).val();
            let postcode = $("#SharePostalCode" + key).val();
            let country = $("#ShareCountry" + key).val();

            if (address) {
                $("#ShareAddress" + key).parsley().reset();
            }
            if (city) {
                $("#ShareCity" + key).parsley().reset();
            }
            if (state) {
                $("#ShareProvince" + key).parsley().reset();
            }
            if (postcode) {
                $("#SharePostalCode" + key).parsley().reset();
            }
            if (country) {
                $("#ShareCountry" + key).parsley().reset();
            }

        }, 500);
    }
    componentDidMount() {
        var key = this.props.ItemKey
        initializeGoogleAddress("ShareAddress" + key, "ShareCountry" + key, "ShareProvince" + key, "ShareCity" + key, "SharePostalCode" + key);
    }
    HandleChangeRequired = () => {

        if (this.props.Total === 1 && this.isEveryInputEmpty("ShareHolderInformationContainer")) {
            $("#ShareHolderInformationContainer").parsley().reset();

        }
        else {
            $("#ShareHolderInformationContainer").parsley({
                successClass: " ",
                errorClass: "input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            });
        }
        this.setState({
            IsBlank: this.isEveryInputEmpty("ShareHolderInformationContainer")
        });


    }
    onChangeShareHolderData = (key, value) => {
        const stateValue = { ...this.state };
        stateValue[key] = value;
        if (key === "Country" || key === "Province" || key === "City" || key === "PostalCode" || key === "Address" || key === "Address2") {
            this.props.DataChanged(this.props.ItemKey, "HomeAddress", stateValue.Address + "," + stateValue.Address2 + "," + stateValue.City + "," + stateValue.Province + "," + stateValue.PostalCode + ", " + stateValue.Country);
        }
        else if (key === "Ownership") {
            this.props.DataChanged(this.props.ItemKey, key, value + "%");
        }
        else {
            this.props.DataChanged(this.props.ItemKey, key, value);
        }
        this.HandleChangeRequired()

    }
    isEveryInputEmpty(id) {
        var allEmpty = true;

        $('#' + id + ' input[type=text]').each(function () {

            if ($(this).val() !== '') {
                allEmpty = false;
                return false; // we've found a non-empty one, so stop iterating
            }
        });
        $('#' + id + ' input[type=number]').each(function () {

            if ($(this).val() !== '') {
                allEmpty = false;
                return false; // we've found a non-empty one, so stop iterating
            }
        });
        return allEmpty;
    }
    componentWillReceiveProps = (nextProps) => {
        const addressSplit = nextProps.ShareholderData.HomeAddress ? nextProps.ShareholderData.HomeAddress.split(/, /) : {};

        this.setState({
            ...nextProps.ShareholderData,
            Ownership: nextProps.ShareholderData.Ownership?.slice(0, -1) || "",
            Country: addressSplit[5] || "",
            Province: addressSplit[3] || "",
            City: addressSplit[2] || "",
            PostalCode: addressSplit[4] || "",
            Address: addressSplit[0] || "",
            Address2: addressSplit[1] || "",
            IsBlank: this.isEveryInputEmpty("ShareHolderInformationContainer"),
        });
    }
    RemoveForm = (e) => {
        e.preventDefault();
        this.props.RemoveAnotherForm(this.props.ItemKey, this.state.ID)
    }
    render() {
        var isRequired = true;
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }

        today = yyyy + '-' + mm + '-' + dd;
        if (this.props.Total === 1 && this.state.IsBlank) {
            isRequired = false;
        }
        const { t } = this.props;
        if (this.state.OwnerType === 'Company') {
            return (
                <>
                    <div className="PageContainer" data-id={this.props.ItemKey}>
                        <Input name="Key" type="hidden" value={this.props.ItemKey} />
                        <Input name="Id" type="hidden" value={this.state.ID || null} />
                        <Input
                            name="ShareholderOwnerType"
                            id={"ShareholderOwnerType" + this.props.ItemKey}
                            type="hidden"
                            value={this.state.OwnerType || "Company"}
                        />
                        <div className="form__element">
                            {t("shareHolderForm.selectLabel")}:&nbsp;&nbsp;&nbsp;
                            <RadioButton
                                name={this.state.accountTypeName}
                                id={this.state.accountTypeName + "Individual"}
                                value="Individual"
                                onChange={this.MoveToIndividual}
                                checked={false}
                                label={t("shareHolderForm.individualLabel")}
                            ></RadioButton>
                            <RadioButton
                                name={this.state.accountTypeName}
                                id={this.state.accountTypeName + "Business"}
                                value="Business"
                                onChange={this.MoveToBusiness}
                                checked={true}
                                label={t("shareHolderForm.businessLabel")}
                            ></RadioButton>
                        </div>
                        <div className="form__section">
                            <p className="form__sectionHeader">{t("shareHolderForm.detailsHeader")}</p>

                            <FormElement
                                label={t("shareHolderForm.companyNameLabel")}
                                for={"ShareholderFullName" + this.props.ItemKey}
                                feild={
                                    <>
                                        <Input
                                            name="ShareholderFullName"
                                            id={"ShareholderFullName" + this.props.ItemKey}
                                            type="text"
                                            HandleChange={(e) => {
                                                this.onChangeShareHolderData("FullName", e.target.value);
                                            }}
                                            value={this.state.FullName || ""}
                                            pattern="^(?!.*(.{100}|[^a-zA-ZÀÂÉÊÈËÌÏÎÔÙÛÇÆŒàâéêèëìïîôùûçæœ 0-9=$,& \)\(\.\*\-\'\/])).*"
                                            placeholder={t("shareHolderForm.businessPlaceholder")}
                                            required={isRequired}
                                        />
                                        <p className="input__description">{t("shareHolderForm.companyNameDescription")}</p>
                                    </>
                                }
                                visible={true}
                            />
                            <Row
                                items={[

                                    <FormElement
                                        label={t("shareHolderForm.ownershipLabel")}
                                        for={"ShareholderOwnership" + this.props.ItemKey}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderOwnership"
                                                    id={"ShareholderOwnership" + this.props.ItemKey}
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData("Ownership", e.target.value);
                                                    }}
                                                    value={this.state.Ownership || ""}
                                                    type="number"
                                                    min={25}
                                                    max={100}
                                                    placeholder={t("shareHolderForm.ownershipPlaceholder")}
                                                    required={isRequired}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,

                                    <FormElement
                                        label={t("shareHolderForm.occupationLabel")}
                                        for={"ShareholderOccupation" + this.props.ItemKey}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderOccupation"
                                                    id={"ShareholderOccupation" + this.props.ItemKey}
                                                    type="text"
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData("Occupation", e.target.value);
                                                    }}
                                                    value={this.state.Occupation || ""}
                                                    placeholder={t("shareHolderForm.occupationPlaceholder")}
                                                    required={isRequired}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,


                                ]}
                            />

                        </div>
                        <div className="form__section">
                            <p className="form__sectionHeader">{t("shareHolderForm.registeredBusinessAddressHeader")}</p>
                            <Row
                                items={[
                                    <FormElement
                                        label={t("shareHolderForm.addressLine1Label")}
                                        for={this.state.AddressId}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderAddress1"
                                                    type="text"
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData("ShareholderAddress1", e.target.value);
                                                    }}
                                                    onFocusOut={this.onFocusOutAddress}
                                                    value={this.state.ShareholderAddress1 || ""}
                                                    placeholder={t("shareHolderForm.addressLine1Placeholder")}
                                                    id={this.state.AddressId}
                                                    required={isRequired}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                    <FormElement
                                        label={t("shareHolderForm.addressLine2Label")}
                                        for={"ShareholderAddress2" + this.props.ItemKey}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderAddress2"
                                                    id={"ShareholderAddress2" + this.props.ItemKey}
                                                    type="text"
                                                    placeholder={t("shareHolderForm.addressLine2Placeholder")}
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData("ShareholderAddress2", e.target.value);
                                                    }}
                                                    value={this.state.ShareholderAddress2 || ""}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                ]}
                            />
                            <Row
                                items={[
                                    <FormElement
                                        label={t("shareHolderForm.cityLabel")}
                                        for={this.state.CityId}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderCity"
                                                    type="text"
                                                    placeholder={t("shareHolderForm.cityPlaceholder")}
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData("ShareholderCity", e.target.value);
                                                    }}
                                                    value={this.state.ShareholderCity || ""}
                                                    id={this.state.CityId}
                                                    required={isRequired}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                    <FormElement
                                        label={t("shareHolderForm.provinceLabel")}
                                        for={this.state.ProvinceId}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderProvince"
                                                    type="text"
                                                    placeholder={t("shareHolderForm.provincePlaceholder")}
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData("ShareholderProvince", e.target.value);
                                                    }}
                                                    value={this.state.ShareholderProvince || ""}
                                                    id={this.state.ProvinceId}
                                                    required={isRequired}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                ]}
                            />
                            <Row
                                items={[
                                    <FormElement
                                        label={t("shareHolderForm.countryLabel")}
                                        for={this.state.CountryId}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderCountry"
                                                    type="text"
                                                    placeholder={t("shareHolderForm.countryPlaceholder")}
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData("ShareholderCountry", e.target.value);
                                                    }}
                                                    value={this.state.ShareholderCountry || ""}
                                                    id={this.state.CountryId}
                                                    required={isRequired}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                    <FormElement
                                        label={t("shareHolderForm.postalCodeLabel")}
                                        for={this.state.PostalCodeId}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderPostalCode"
                                                    type="text"
                                                    placeholder={t("shareHolderForm.postalCodePlaceholder")}
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData("ShareholderPostalCode", e.target.value);
                                                    }}
                                                    value={this.state.ShareholderPostalCode || ""}
                                                    id={this.state.PostalCodeId}
                                                    required={isRequired}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                ]}
                            />
                        </div>
                    </div>
                    <AddAnotherButton onClick={this.props.AddAnotherForm} onClickRemove={this.RemoveForm} current={parseInt(this.props.ContactNumber) + 1} count={this.props.length} max={4} />
                </>
            );
        }
        else {
            return (
                <>
                    <div className="PageContainer" data-id={this.props.ItemKey}>
                        <Input name="Key" type="hidden" value={this.props.ItemKey} />
                        <Input name="Id" type="hidden" value={this.state.ID || null} />
                        <Input
                            name="ShareholderOwnerType"
                            id={"ShareholderOwnerType" + this.props.ItemKey}
                            type="hidden"
                            value={this.state.OwnerType || "Individual"}
                        />
                        <div className="form__element">
                            {t('shareHolderForm.selectLabel')}
                            &nbsp;&nbsp;&nbsp;
                            <RadioButton
                                name={this.state.accountTypeName}
                                id={this.state.accountTypeName + 'Individual'}
                                value="Individual"
                                onChange={this.MoveToIndividual}
                                checked={true}
                                label={t('shareHolderForm.individualLabel')}
                            ></RadioButton>
                            <RadioButton
                                name={this.state.accountTypeName}
                                id={this.state.accountTypeName + 'Business'}
                                value="Business"
                                onChange={this.MoveToBusiness}
                                checked={false}
                                label={t('shareHolderForm.businessLabel')}
                            ></RadioButton>
                        </div>

                        <div className="form__section">
                            <p className="form__sectionHeader">
                                {t('shareHolderForm.detailsHeader')}
                            </p>
                            <FormElement
                                label={t('shareHolderForm.fullNameLabel')}
                                for={'ShareholderFullName' + this.props.ItemKey}
                                feild={
                                    <>
                                        <Input
                                            name="ShareholderFullName"
                                            id={'ShareholderFullName' + this.props.ItemKey}
                                            type="text"
                                            HandleChange={(e) => {
                                                this.onChangeShareHolderData('FullName', e.target.value);
                                            }}
                                            value={this.state.FullName || ''}
                                            pattern="^(?!.*(.{100}|[^a-zA-ZÀÂÉÊÈËÌÏÎÔÙÛÇÆŒàâéêèëìïîôùûçæœ 0-9=$,& \)\(\.\*\-\'\/])).*"
                                            placeholder={t('shareHolderForm.fullNamePlaceholder')}
                                            required={isRequired}
                                        />
                                    </>
                                }
                                visible={true}
                            />
                            <Row
                                items={[
                                    <FormElement
                                        label={t('shareHolderForm.dateOfBirthLabel')}
                                        for={'ShareholderDOB' + this.props.ItemKey}
                                        feild={
                                            <>
                                                <Input
                                                    required={isRequired}
                                                    id={'ShareholderDOB' + this.props.ItemKey}
                                                    type={this.state.DOB ? 'date' : 'text'}
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData('DOB', e.target.value);
                                                    }}
                                                    name="ShareholderDOB"
                                                    value={this.state.DOB || ''}
                                                    max={today}
                                                    placeholder={t('shareHolderForm.dateOfBirthPlaceholder')}
                                                    onFocus={(e) => (e.target.type = 'date')}
                                                    onFocusOut={(e) =>
                                                        e.target.value === ''
                                                            ? (e.target.type = 'text')
                                                            : (e.target.type = 'date')
                                                    }
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                    <FormElement
                                        label={t('shareHolderForm.ownershipLabel')}
                                        for={'ShareholderOwnership' + this.props.ItemKey}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderOwnership"
                                                    id={'ShareholderOwnership' + this.props.ItemKey}
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData('Ownership', e.target.value);
                                                    }}
                                                    value={this.state.Ownership || ''}
                                                    type="number"
                                                    min={25}
                                                    max={100}
                                                    placeholder={t('shareHolderForm.ownershipPlaceholder')}
                                                    required={isRequired}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                ]}
                            />
                            <Row
                                items={[
                                    <FormElement
                                        label={t('shareHolderForm.occupationLabel')}
                                        for={'ShareholderOccupation' + this.props.ItemKey}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderOccupation"
                                                    id={'ShareholderOccupation' + this.props.ItemKey}
                                                    type="text"
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData('Occupation', e.target.value);
                                                    }}
                                                    value={this.state.Occupation || ''}
                                                    placeholder={t('shareHolderForm.occupationPlaceholder')}
                                                    required={isRequired}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                    <></>,
                                ]}
                            />
                        </div>
                        <div className="form__section">
                            <p className="form__sectionHeader">
                                {t('shareHolderForm.addressHeader')}
                            </p>
                            <Row
                                items={[
                                    <FormElement
                                        label={t('shareHolderForm.addressLine1Label')}
                                        for={this.state.AddressId}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderAddress1"
                                                    type="text"
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData(
                                                            'ShareholderAddress1',
                                                            e.target.value
                                                        );
                                                    }}
                                                    onFocusOut={this.onFocusOutAddress}
                                                    value={this.state.ShareholderAddress1 || ''}
                                                    placeholder={t('shareHolderForm.addressLine1Placeholder')}
                                                    id={this.state.AddressId}
                                                    required={isRequired}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                    <FormElement
                                        label={t('shareHolderForm.addressLine2Label')}
                                        for={'ShareholderAddress2' + this.props.ItemKey}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderAddress2"
                                                    id={'ShareholderAddress2' + this.props.ItemKey}
                                                    type="text"
                                                    placeholder={t('shareHolderForm.addressLine2Placeholder')}
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData(
                                                            'ShareholderAddress2',
                                                            e.target.value
                                                        );
                                                    }}
                                                    value={this.state.ShareholderAddress2 || ''}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                ]}
                            />
                            <Row
                                items={[
                                    <FormElement
                                        label={t('shareHolderForm.cityLabel')}
                                        for={this.state.CityId}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderCity"
                                                    type="text"
                                                    placeholder={t('shareHolderForm.cityPlaceholder')}
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData(
                                                            'ShareholderCity',
                                                            e.target.value
                                                        );
                                                    }}
                                                    value={this.state.ShareholderCity || ''}
                                                    id={this.state.CityId}
                                                    required={isRequired}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                    <FormElement
                                        label={t('shareHolderForm.provinceLabel')}
                                        for={this.state.ProvinceId}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderProvince"
                                                    type="text"
                                                    placeholder={t('shareHolderForm.provincePlaceholder')}
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData(
                                                            'ShareholderProvince',
                                                            e.target.value
                                                        );
                                                    }}
                                                    value={this.state.ShareholderProvince || ''}
                                                    id={this.state.ProvinceId}
                                                    required={isRequired}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                ]}
                            />
                            <Row
                                items={[
                                    <FormElement
                                        label={t('shareHolderForm.countryLabel')}
                                        for={this.state.CountryId}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderCountry"
                                                    type="text"
                                                    placeholder={t('shareHolderForm.countryPlaceholder')}
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData(
                                                            'ShareholderCountry',
                                                            e.target.value
                                                        );
                                                    }}
                                                    value={this.state.ShareholderCountry || ''}
                                                    id={this.state.CountryId}
                                                    required={isRequired}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                    <FormElement
                                        label={t('shareHolderForm.postalCodeLabel')}
                                        for={this.state.PostalCodeId}
                                        feild={
                                            <>
                                                <Input
                                                    name="ShareholderPostalCode"
                                                    type="text"
                                                    placeholder={t('shareHolderForm.postalCodePlaceholder')}
                                                    HandleChange={(e) => {
                                                        this.onChangeShareHolderData(
                                                            'ShareholderPostalCode',
                                                            e.target.value
                                                        );
                                                    }}
                                                    value={this.state.ShareholderPostalCode || ''}
                                                    id={this.state.PostalCodeId}
                                                    required={isRequired}
                                                />
                                            </>
                                        }
                                        visible={true}
                                    />,
                                ]}
                            />
                        </div>
                        <div className="form__section">
                            <p className="form__sectionHeader">{t('signingAuthorityForm.supportingDocuments')}</p>
                            <p className="description">{t('signingAuthorityForm.attachIDDescription')}</p>
                            <div className="form__element">
                                <p className="description__Inside">{t('signingAuthorityForm.identityDocumentFront')}</p>
                                <FileUpload
                                    name={`IdFrontShareholder`}
                                    required={true}
                                    parentId={this.props.ItemKey}
                                    FileName={this.context.Documents[`IdFrontShareholder${this.state.ID}`]?.DocumentName || ""}
                                />
                            </div>
                            <div className="form__element">
                                <p className="description__Inside">{t('signingAuthorityForm.identityDocumentBack')}</p>
                                <FileUpload
                                    name={`IdBackShareholder`}
                                    required={true}
                                    parentId={this.props.ItemKey}
                                    FileName={this.context.Documents[`IdBackShareholder${this.state.ID}`]?.DocumentName || ""}
                                />
                            </div>
                        </div>
                    </div>

                    <AddAnotherButton onClick={this.props.AddAnotherForm} onClickRemove={this.RemoveForm} current={parseInt(this.props.ContactNumber) + 1} count={this.props.length} max={4} />
                </>
            );
        }
    }
}
ShareHolderForm.contextType = FormContext;
export default withTranslation()(ShareHolderForm);