import React from 'react';
import Button from './../../components/Button/Button';
export default class LegalTermsContainer extends React.Component {
    CheckboxOnchange = (e) => {
     
    }
    render() {
        return (
            <div>
                <h1 className="title">Legal Terms and Agreements</h1>
                <p className="description">By submitting this form, you acknowledge that you have read and understood the terms of this Agreement as set out above and in the <a target="_blank" rel="noreferrer" href="https://vopay.com/ca/legal/tos">Terms of Service</a> and, for good and valuable consideration, agrees to be bound by this Agreement.</p>
                <div className="buttonBar">
                <Button title="Prev" width="50Left" onClickHandler={this.props.prevHandler}/>
                <Button title="Agree" width="50Right"  onClickHandler={this.props.nextHandler}/>
                </div>
            </div>
        );
    }
  }