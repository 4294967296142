import React from 'react';
import styles from './Menu.module.css';
import Progress from '../Progress/Progress';
import { withTranslation } from 'react-i18next';

class Menu extends React.Component {
    render() {
        const { t, progress = 0 } = this.props;
        return (
            <div className={styles.menu}>
                <h1 className={styles.menuHeader}>{t('menuComponent.applicationFormProgress')}</h1>
                <div className={styles.ProgressContainer}>
                    <Progress
                        pathStrokeColor={this.props.PrimaryColor}
                        arrowPosition="7px"
                        width="231px"
                        height="231px"
                        progress={progress}
                    />
                </div>
                <ul className={styles.menuList}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

export default withTranslation()(Menu);
