import React from 'react';
import { withTranslation } from 'react-i18next';
import InfoTable from '../../components/InfoTable/InfoTable';
import InfoTableItem from '../../components/InfoTableItem/InfoTableItem';

class LenderInfo extends React.Component {
    render() {
        const { t } = this.props; // Access the translation function
        
        return (
            <InfoTable>
               <InfoTableItem Label={t('lenderInfo.fundsSourceLabel')} Value={this.props.Data.LenderFundsSource} />
               <InfoTableItem Label={t('lenderInfo.aprLabel')} Value={this.props.Data.LenderApr} />
               <InfoTableItem Label={t('lenderInfo.registrationLabel')} Value={this.props.Data.LenderRegistration} />
            </InfoTable>
        );
    }
}

export default withTranslation()(LenderInfo);
