import React from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Textarea from '../../components/Textarea/Textarea';
import FileUpload from '../../components/FileUpload/FileUpload';
import FormElement from '../../components/FormElement/FormElement';
import Row from '../../components/Row/Row';
import { withTranslation } from 'react-i18next';
import { FormContext } from '../../context/FormContext';

class LenderInformationContainer extends React.Component {
  ContextChanged = (e) => {
    this.context.UpdateLenderInformation({ [e.target.name]: e.target.value });
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <h1 className="title">{t('lenderInformationContainer.title')}</h1>
        <FormElement
          label={t('lenderInformationContainer.fundsSource')}
          for="LenderFundsSource"
          feild={(
            <Textarea
              required={true}
              id="LenderFundsSource"
              value={this.context.LenderInformation.LenderFundsSource}
              maxLength="500"
              HandleChange={this.ContextChanged}
              name="LenderFundsSource"
            />
          )}
          visible={true}
        />
        <Row
          items={[
            <FormElement
              label={t('lenderInformationContainer.aprLoans')}
              helpText={t('lenderInformationContainer.aprRange')}
              for="LenderApr"
              feild={(
                <Input
                  name="LenderApr"
                  id="LenderApr"
                  value={this.context.LenderInformation.LenderApr}
                  HandleChange={this.ContextChanged}
                  type="text"
                  required={true}
                />
              )}
              visible={true}
            />,
            <></>
          ]}
        />
        <FormElement
          feild={(
            <div>
              <p className="description__Inside">{t('lenderInformationContainer.lenderRegistration')}</p>
              <p className="description__helpText">{t('lenderInformationContainer.uploadCopy')}</p>
              <FileUpload
                name="LenderRegistration"
                FileName={this.context.Documents["LenderRegistration"]?.DocumentName || ""}
                required={true}
              />
            </div>
          )}
          visible={true}
        />
        <div className="buttonBar">
          <Button title={t('lenderInformationContainer.back')} width="50Left" backgroundColor={this.props.PrimaryColor} onClickHandler={this.props.prevHandler} />
          <Button title={t('lenderInformationContainer.next')} width="50Right" backgroundColor={this.props.PrimaryColor} onClickHandler={this.props.nextHandler} />
        </div>
      </>
    );
  }
}

LenderInformationContainer.contextType = FormContext;

export default withTranslation()(LenderInformationContainer);
