import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {initialize} from './service/datadog';
import MainContainer from './containers/MainContainer/MainContainer';
import TermsAndPricing from './containers/TermsAndPricing/TermsAndPricing';
function App() {
  initialize();
  return (
    <div className="App">
       <Router>
          <Switch>
            <Route path="/agreement/:token">
              <TermsAndPricing/>
            </Route>
            <Route path="/registrations/:token">
              <MainContainer />
            </Route>
            <Route path="/registration/:token">
              <MainContainer />
            </Route>
            <Route path="/">
              <MainContainer />
            </Route>
          </Switch>
      </Router>
    
    </div>
  );
}

export default App;
