import React from 'react';
import styles from './AddAnotherButton.module.css';
import { withTranslation } from 'react-i18next';

class AddAnotherButton extends React.Component {
    render() {
      const { t } = this.props;
  
      return (
        <div className={styles.container}>
          {(() => {
            if (this.props.count !== this.props.current) {
              return (
                <button type="button" className={styles.button} onClick={e => this.props.onClickRemove(e)}>
                  <img className={styles.image} alt="-" src="/images/button-excluded--.svg" />
                </button>
              );
            } else {
              return (
                <button type="button" className={styles.button} onClick={e => this.props.onClick(e)}>
                  <img className={styles.image} alt="+" src="/images/button-add-+.svg" />
                </button>
              );
            }
          })()}
  
          {(() => {
            if (this.props.count > 1 && this.props.count === this.props.current) {
              return (
                <button className={styles.button} type="button" onClick={e => this.props.onClickRemove(e)}>
                  <img className={styles.image} alt="-" src="/images/button-excluded--.svg" />
                </button>
              );
            }
          })()}
  
          {(() => {
            if (this.props.count > 1 && this.props.count === this.props.current) {
              return <span className={styles.text}>{t('AddAnotherButton.addRemoveOther')}</span>;
            } else if (this.props.count !== this.props.current) {
              return <span className={styles.text}>{t('AddAnotherButton.remove')}</span>;
            } else {
              return <span className={styles.text}>{t('AddAnotherButton.addOther')}</span>;
            }
          })()}
        </div>
      );
    }
  }
  
  export default withTranslation()(AddAnotherButton);
  
