import React from 'react';
/* eslint-disable no-unused-vars */
import parsley from 'parsleyjs';
/* eslint-disable no-unused-vars */
import ReactModal from 'react-modal';
import LegalTermsContainer from "./../LegalTermsContainer/LegalTermsContainer";
import styles from './TermsAndPricingPrint.module.css';
import Button from './../../components/Button/Button';
import PricingContainer from '../PricingContainer/PricingContainer';
import ThirdPartyStatement from '../ThirdPartyStatementContainer/ThirdPartySatement';
import AgreementDetailsContainer from '../AgreementDetailsContainer/AgreementDetailsContainer';
import Footer from'./../../components/Footer/Footer';
import Header from'./../../components/Header/Header';
import {getTokenInfo,GetBillingPackages} from "./../../service/api";
import Bowser from "bowser"; 
import {CapitalizeFirstLetter} from "./../../service/tools";

import DigitalSignature from '../../components/DigitalSignature/DigitalSignature';

export default class TermsAndPricingPrint extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            Browser:"",
            Platform:"",
            OS:"",
            Isloaded: true,
            Description: "",
            AssignedBillingPackage: {}
        }
      
    }
    
    
    
    
   
    CloseModal = (e) => {
        e.preventDefault();
        this.setState({
            IsOpen:false
           
        })
    }
   

    componentWillMount(){
        var path = new URL(window.location.href).pathname;
        var paths = path.split("/")
        var token = paths[paths.length-1];
       
        localStorage.setItem("Token", token);
        let self = this;
        var options = {
            ApplicationType: "agreement",
            //ApplicationType: "agreement"
           }
        getTokenInfo(options).then(
            data =>{
                window.IsPartnerToken = data.IsPartnerToken
                self.setState({
                   
                    Name: data.Name,
                    IsPartnerToken:data.IsPartnerToken,
                    ApplicationType: "agreement"
                 
                })
                GetBillingPackages().then(
                    data =>{
 
                        self.setState({
                            AssignedBillingPackage: data.AssignedBillingPackage,
                            Isloaded: true
                        })
                    },
                    error =>{
                      
                    });
            },
            error =>{
                self.setState({
                   
                    IsOpen:true,
                    Description:error
                })
                
            });
       
           
        
    }
    componentDidMount(){
        var BrowserInformation = Bowser.parse(window.navigator.userAgent);
        this.setState({
            Browser:BrowserInformation.browser.name+" "+BrowserInformation.browser.version,
            Platform:CapitalizeFirstLetter(BrowserInformation.platform.type)+" "+BrowserInformation.platform.vendor,
            OS:BrowserInformation.os.name+" "+BrowserInformation.os.version,
        })
    }
    render() {
        var IsBillingPackage = false;
        if(this.state.AssignedBillingPackage.hasOwnProperty("BillingSubscriptions") ){
            IsBillingPackage = true;
        }

  
        if ( !this.state.Isloaded ) {
            return  (
                <div className={styles.ContentContainer}> 
                    <ReactModal
                    isOpen={this.state.IsOpen}>
                    <h1 id="heading">Alert</h1>
                    <div id="full_description">
                        <p>
                            {this.state.Description}
                        </p>
                        <Button title="Close"  onClickHandler={this.CloseModal}/>
                    </div>
                    </ReactModal>
                </div>
            )
        }
        else{
        return (
            <div id="PriceingPrint" className={styles.pricingPrint}>
            <Header title="Agreement" />
           <div  className={styles.ContentContainer}> 
               
                <div className={styles.ContentArea}>
                   
                    {(() =>{
                        if(IsBillingPackage){
                            return(
                                
                                    <div>
                                        <AgreementDetailsContainer Name={this.props.Name}  AssignedBillingPackage={this.state.AssignedBillingPackage} />
                                    </div>
                     
                            )
                        }
                       
                    })()}
                       {(() =>{
                        if(IsBillingPackage){
                            return(
                                <div className="newPage"></div>
                            )
                        }
                       
                    })()}
                   {(() =>{
                        if(IsBillingPackage){
                            return(
                          
                                <div>
                                <PricingContainer isPrint={true} AssignedBillingPackage={this.state.AssignedBillingPackage}/>
                                </div>
                        
                            )
                        }
                       
                    })()}
                   
                 
                   
                  
                   
                   {(() =>{
                        if(IsBillingPackage){
                            return(
                                <div className="newPage"></div>
                            )
                        }
                       
                    })()}
                    <div  className={styles.removeMarginPrint}>
                        <div id="ThirdPartyStatmentPrint">

                        </div>
                      
                    </div>
                    
                    <form className={styles.removeMarginPrint}  >
                        <LegalTermsContainer   />
                    </form>
                    <DigitalSignature SignerName={this.props.SignerName} TimeStamp={this.props.TimeStamp} IPAddress={this.props.IPAddress} Location={this.props.Location} Browser={this.state.Browser} Platform={this.state.Platform} OS={this.state.OS} />
                </div>
           </div>
           <Footer/>
          </div>
        );
         }
    }
  }