import React from 'react';
import styles from './MenuItem.module.css';
export default class MenuItem extends React.Component {
    render() {

        return (
            <li className={styles.menuItem}>
                {(() => {
                    if(this.props.Active) {
                        return (<span className={styles.menuItemActive} >
                           {this.props.children}</span>);
                        
                    }
                    else{
                        return (<span>
                            {this.props.children}
                        </span>);
                    }
                })()}
                
            </li>
        );
    }
  }