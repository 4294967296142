import React from 'react';
import styles from './RadioButton.module.css';
export default class RadioButton extends React.Component {
    render() {
      return (
        <div className={styles.container}><label for={this.props?.id}>{this.props.label}</label>
         <input id={this.props?.id} type="radio" data-parsley-errors-container={this.props["data-parsley-errors-container"]} value={this.props.value} checked={this.props.checked} onChange={e => this.props.onChange(e)} name={this.props.name} required={this.props.required} pattern={this.props.pattern} placeholder={this.props.placeholder} />
            <span className={styles.checkmark}></span>
        </div>
      
       
      );
    }
  }