const request = require('sync-request');

export function translateText(text, targetLanguage) {
  // Check if the translation is already cached in local storage
  const cacheKey = `translation_${text}_${targetLanguage}`;
  const cachedTranslation = localStorage.getItem(cacheKey);
  if (cachedTranslation) {
    return cachedTranslation;
  }

  if (targetLanguage !== "en") {
    const apiKey = 'AIzaSyCz0451-GVNV2410rpMXFdP54WYsAplaGg';
    const baseUrl = 'https://translation.googleapis.com/language/translate/v2';

    const url = `${baseUrl}?q=${encodeURIComponent(text)}&target=${targetLanguage}&key=${apiKey}`;

    const res = request('GET', url);

    if (res.statusCode === 200) {
      const response = JSON.parse(res.getBody('utf8'));
      const translatedText = response.data.translations[0].translatedText;

      // Cache the translated text in local storage
      localStorage.setItem(cacheKey, translatedText);

      return translatedText;
    } else {
      throw new Error('Translation request failed.');
    }
  } else {
    return text;
  }
}
