import React from 'react';
import { withTranslation } from 'react-i18next';
import styles from './FileUpload.module.css';
import $ from 'jquery';
/* eslint-disable no-unused-vars */
import parsley from 'parsleyjs';
/* eslint-disable no-unused-vars */
class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      FileName: props.FileName ? props.FileName.split("-").slice(2).join("-") : "",
      required: props.FileName ? false : props.required,
      value: props.value,
      rerender: false,
    }
    
  }
  ActivateFile(e){
    e.preventDefault();
    $(e.target).parent().parent().find("input").first().trigger("click")
  }
  componentWillReceiveProps = (nextProps) => {
    if(nextProps.FileName !== this.props.FileName){
      const FileName = nextProps.FileName.split("-").slice(2).join("-");
      this.setState({
        FileName: FileName,
        required: false,
      });
    }
  }
  Drop(e){
    e.preventDefault();
    var fileExtension = e.dataTransfer.files[0].name.split(".")[1];
    let self = this;

    if(fileExtension.toLowerCase() === "png" || fileExtension.toLowerCase() === "pdf" || fileExtension.toLowerCase() === "jpeg" || fileExtension.toLowerCase() === "jpg" ){
      $(e.target).parent().parent().find("input").first().prop("files", e.dataTransfer.files).trigger("change");
      setTimeout(function(){
        if($(e.target).parent().parent().find("input").first().parsley()){
          $(e.target).parent().parent().find("input").first().parsley().validate();
        }
      },300)
      this.setState({FileName: e.dataTransfer.files[0].name});
    }
    else{

      $(e.target).parent().parent().find("input").first().prop("files", e.dataTransfer.files).trigger("change");
      let self = this;
      setTimeout(function(){
        if($(e.target).parent().parent().find("input").first().parsley()){
          $(e.target).parent().parent().find("input").first().parsley().validate();
        }
        $(e.target).parent().parent().find("input").val("");
        if(self.state.required){
          self.setState({
            FileName:""
          })
        }
      },300)
    }
  }
  onDragOver = (e) => {
    let event = e ;
    event.preventDefault();
  }
  FileChange = (e) => {
    e.preventDefault();
    var fileExtension = e.target.files[0].name.split(".")[1];
  
    if(fileExtension.toLowerCase() === "png" || fileExtension.toLowerCase() === "pdf" || fileExtension.toLowerCase() === "jpeg" || fileExtension.toLowerCase() === "jpg" ){
      this.setState({FileName: e.target.files[0].name});
      if(this.props.handleChange){
        this.props.handleChange(e)
      }
    }
    else{
      if($(e.target).parent().parent().find("input").first().parsley()){
        $(e.target).parent().parent().find("input").first().parsley().validate();
      }
      $(e.target).val("");
      if(this.state.required){
        this.setState({
          FileName:""
        })
      }
    }
    
  
  }
    render() {
      const { t } = this.props;
  
      return (
        <div className={styles.FileUploadContainer} onDragOver={e => this.onDragOver(e)} onDrop={e => this.Drop(e)}>
          <p className={styles.FileUploadText}>
            {t('FileUpload.dropFile')} 
            <button onClick={e => this.ActivateFile(e)}>{t('FileUpload.browse')}</button>
            <span className={styles.Files}>{this.state.FileName}</span>
          </p>
          <input
            data-rerender={this.state.rerender}
            data-parent-Id={this.props.parentId}
            data-document-type={this.props.documentType || "kyc"}
            type="file"
            data-name={this.props.name}
            data-parsley-filemimetypes="image/jpeg,image/png,application/pdf"
            value={this.state.value}
            onChange={e => this.FileChange(e)}
            accept="image/jpeg,image/png,application/pdf"
            id={this.props.id}
            required={this.state.required}
            data-parsley-trigger="change"
          />
          <input type="hidden" name={this.props.name} value={this.state.FileName} />
        </div>
      );
  }
}
export default withTranslation()(FileUpload);