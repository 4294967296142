import React from 'react';
import Input from './../../components/Input/Input';
import FormElement from '../../components/FormElement/FormElement';
import FileUpload from './../../components/FileUpload/FileUpload';
import AddAnotherButton from './../../components/AddAnotherButton/AddAnotherButton';
import { FormContext } from '../../context/FormContext';
import { initializeGoogleAddress } from "./../../service/tools";
import Row from '../../components/Row/Row';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
/* eslint-disable no-unused-vars */
import parsley from 'parsleyjs';
/* eslint-disable no-unused-vars */
class SigningAuthoritiesForm extends React.Component {
    constructor(props) {
        super(props);
        var key = this.props.ItemKey
        const addressSplit = this.props.SigningData.HomeAddress ? this.props.SigningData.HomeAddress.split(/, /) : {};
        this.state = {
            CountryId: "AuthorityCountry" + key,
            ProvinceId: "AuthorityProvince" + key,
            CityId: "AuthorityCity" + key,
            PostalCodeId: "AuthorityPostalCode" + key,
            AddressId: "AuthorityAddress" + key,
            Country: addressSplit[4],
            Province: addressSplit[2],
            City: addressSplit[1],
            PostalCode: addressSplit[3],
            Address: addressSplit[0],
            ...this.props.SigningData
        }
    }
    componentWillReceiveProps = (nextProps) => {
        const addressSplit = nextProps.SigningData.HomeAddress ? nextProps.SigningData.HomeAddress.split(/, /) : {};

        this.setState({
            ...nextProps.SigningData,
            ID: nextProps.SigningData.ID !== "" ? nextProps.SigningData.ID : this.state.ID,
            Country: addressSplit[5] || "",
            Province: addressSplit[3] || "",
            City: addressSplit[2] || "",
            PostalCode: addressSplit[4] || "",
            Address2: addressSplit[1] || "",
            Address: addressSplit[0] || "",
        });
    }
    componentDidMount() {
        var key = this.props.ItemKey
        initializeGoogleAddress("AuthorityAddress" + key, "AuthorityCountry" + key, "AuthorityProvince" + key, "AuthorityCity" + key, "AuthorityPostalCode" + key);
    }
    onFocusOutAddress = (e) => {
        var key = this.props.ItemKey;

        setTimeout(function () {
            let address = $("#AuthorityAddress" + key).val();
            let city = $("#AuthorityCity" + key).val();
            let state = $("#AuthorityProvince" + key).val();
            let postcode = $("#AuthorityPostalCode" + key).val();
            let country = $("#AuthorityCountry" + key).val();
            if (address) {
                $("#AuthorityAddress" + key).parsley().reset();
            }
            if (city) {
                $("#AuthorityCity" + key).parsley().reset();
            }
            if (state) {
                $("#AuthorityProvince" + key).parsley().reset();
            }
            if (postcode) {
                $("#AuthorityPostalCode" + key).parsley().reset();
            }
            if (country) {
                $("#AuthorityCountry" + key).parsley().reset();
            }

        }, 500);
    }
    onChangeSigningData = (key, value) => {
        const stateValue = { ...this.state };
        stateValue[key] = value;
        if (key === "Country" || key === "Province" || key === "City" || key === "PostalCode" || key === "Address" || key === "Address2") {
            this.props.DataChanged(this.props.ItemKey, "HomeAddress", stateValue.Address + "," + stateValue.Address2 + ", " + stateValue.City + ", " + stateValue.Province + ", " + stateValue.PostalCode + ", " + stateValue.Country);
        }
        else {
            this.props.DataChanged(this.props.ItemKey, key, value);
        }

    }
    RemoveForm = (e) => {
        e.preventDefault();
        this.props.RemoveAnotherForm(this.props.ItemKey, this.state.ID)
    }
    render() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }

        today = yyyy + '-' + mm + '-' + dd;
        const { t } = this.props;
        return (
            <>
                <div className="PageContainer" data-id={this.props.ItemKey}>
                    <Input name="Key" type="hidden" value={this.props.ItemKey} />
                    <Input name="Id" type="hidden" value={this.state.ID} />
                    <h2 className="title__secondary ">
                        {t('signingAuthorityForm.contact')} {parseInt(this.props.ContactNumber) + 1}
                    </h2>
                    <div className="form__section">
                        <p className="form__sectionHeader">{t('signingAuthorityForm.contactInformation')}</p>
                        <FormElement
                            label={t('signingAuthorityForm.fullName')}
                            for={"AuthorizedFullLegalName" + this.props.ItemKey}
                            feild={
                                <>
                                    <Input
                                        name="AuthorizedFullLegalName"
                                        id={"AuthorizedFullLegalName" + this.props.ItemKey}
                                        pattern="^(?!.*(.{100}|[^a-zA-ZÀÂÉÊÈËÌÏÎÔÙÛÇÆŒàâéêèëìïîôùûçæœ 0-9=$,& \)\(\.\*\-\'\/])).*"
                                        value={this.state.FullName || ""}
                                        HandleChange={(e) => {
                                            this.onChangeSigningData("FullName", e.target.value);
                                        }}
                                        placeholder={t('signingAuthorityForm.fullNamePlaceholder')}
                                        required={true}
                                    />
                                </>
                            }
                            visible={true}
                        />

                        <Row
                            items={[
                                <FormElement
                                    label={t('signingAuthorityForm.dateOfBirth')}
                                    for={"AuthorizedDOB" + this.props.ItemKey}
                                    feild={
                                        <>
                                            <Input
                                                required={true}
                                                id={"AuthorizedDOB" + this.props.ItemKey}
                                                type={this.state.DOB ? "date" : "text"}
                                                name="AuthorizedDOB"
                                                max={today}
                                                value={this.state.DOB || ""}
                                                HandleChange={(e) => {
                                                    this.onChangeSigningData("DOB", e.target.value);
                                                }}
                                                placeholder={t('signingAuthorityForm.dateOfBirthPlaceholder')}
                                                onFocus={(e) => (e.target.type = "date")}
                                                onFocusOut={(e) =>
                                                    e.target.value === ""
                                                        ? (e.target.type = "text")
                                                        : (e.target.type = "date")
                                                }
                                            />
                                        </>
                                    }
                                    visible={true}
                                />,
                                <FormElement
                                    label={t('signingAuthorityForm.occupation')}
                                    for={"AuthorizedOccupation" + this.props.ItemKey}
                                    feild={
                                        <>
                                            <Input
                                                name="AuthorizedOccupation"
                                                id={"AuthorizedOccupation" + this.props.ItemKey}
                                                pattern="^[a-zA-Z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\)\(.\-!@#$%^&*()_\+={}[\]\|;:?']{1,40}$"
                                                value={this.state.Occupation || ""}
                                                placeholder={t('signingAuthorityForm.occupationPlaceholder')}
                                                HandleChange={(e) => {
                                                    this.onChangeSigningData("Occupation", e.target.value);
                                                }}
                                                required={true}
                                            />
                                        </>
                                    }
                                    visible={true}
                                />,
                            ]}
                        />

                        <Row
                            items={[
                                <FormElement
                                    label={t('signingAuthorityForm.emailAddress')}
                                    for={"AuthorizedEmailAddress" + this.props.ItemKey}
                                    feild={
                                        <>
                                            <Input
                                                name="AuthorizedEmailAddress"
                                                id={"AuthorizedEmailAddress" + this.props.ItemKey}
                                                placeholder={t('signingAuthorityForm.emailAddressPlaceholder')}
                                                value={this.state.EmailAddress || ""}
                                                type="email"
                                                HandleChange={(e) => {
                                                    this.onChangeSigningData("EmailAddress", e.target.value);
                                                }}
                                                required={true}
                                            />
                                        </>
                                    }
                                    visible={true}
                                />,
                                <></>
                            ]}
                        />
                    </div>

                    <div className="form__section">
                        <p className="form__sectionHeader">{t('signingAuthorityForm.address')}</p>
                        <Row
                            items={[
                                <FormElement
                                    label={t('signingAuthorityForm.addressLine1')}
                                    for={this.state.AddressId}
                                    feild={
                                        <>
                                            <Input
                                                name="AuthorizedAddress1"
                                                onFocusOut={this.onFocusOutAddress}
                                                value={this.state.AuthorizedAddress1 || ""}
                                                placeholder={t('signingAuthorityForm.addressLine1Placeholder')}
                                                id={this.state.AddressId}
                                                HandleChange={(e) => {
                                                    this.onChangeSigningData("AuthorizedAddress1", e.target.value);
                                                }}
                                                required={true}
                                            />
                                        </>
                                    }
                                    visible={true}
                                />,
                                <FormElement
                                    label={t('signingAuthorityForm.addressLine2')}
                                    for={"AuthorizedAddress2" + this.props.ItemKey}
                                    feild={
                                        <>
                                            <Input
                                                name="AuthorizedAddress2"
                                                id={"AuthorizedAddress2" + this.props.ItemKey}
                                                placeholder={t('signingAuthorityForm.addressLine2Placeholder')}
                                                value={this.state.AuthorizedAddress2 || ""}
                                                HandleChange={(e) => {
                                                    this.onChangeSigningData("AuthorizedAddress2", e.target.value);
                                                }}
                                            />
                                        </>
                                    }
                                    visible={true}
                                />,
                            ]}
                        />

                        <Row
                            items={[
                                <FormElement
                                    label={t('signingAuthorityForm.city')}
                                    for={this.state.CityId}
                                    feild={
                                        <>
                                            <Input
                                                name="AuthorizedCity"
                                                placeholder={t('signingAuthorityForm.cityPlaceholder')}
                                                id={this.state.CityId}
                                                value={this.state.AuthorizedCity || ""}
                                                HandleChange={(e) => {
                                                    this.onChangeSigningData("AuthorizedCity", e.target.value);
                                                }}
                                                required={true}
                                            />
                                        </>
                                    }
                                    visible={true}
                                />,
                                <FormElement
                                    label={t('signingAuthorityForm.province')}
                                    for={this.state.ProvinceId}
                                    feild={
                                        <>
                                            <Input
                                                name="AuthorizedProvince"
                                                placeholder={t('signingAuthorityForm.provincePlaceholder')}
                                                id={this.state.ProvinceId}
                                                value={this.state.AuthorizedProvince || ""}
                                                HandleChange={(e) => {
                                                    this.onChangeSigningData("AuthorizedProvince", e.target.value);
                                                }}
                                                required={true}
                                            />
                                        </>
                                    }
                                    visible={true}
                                />,
                            ]}
                        />

                        <Row
                            items={[
                                <FormElement
                                    label={t('signingAuthorityForm.country')}
                                    for={this.state.CountryId}
                                    feild={
                                        <>
                                            <Input
                                                name="AuthorizedCountry"
                                                placeholder={t('signingAuthorityForm.countryPlaceholder')}
                                                id={this.state.CountryId}
                                                value={this.state.AuthorizedCountry || ""}
                                                HandleChange={(e) => {
                                                    this.onChangeSigningData("AuthorizedCountry", e.target.value);
                                                }}
                                                required={true}
                                            />
                                        </>
                                    }
                                    visible={true}
                                />,
                                <FormElement
                                    label={t('signingAuthorityForm.postalCode')}
                                    for={this.state.PostalCodeId}
                                    feild={
                                        <>
                                            <Input
                                                name="AuthorizedPostalCode"
                                                placeholder={t('signingAuthorityForm.postalCodePlaceholder')}
                                                id={this.state.PostalCodeId}
                                                value={this.state.AuthorizedPostalCode || ""}
                                                HandleChange={(e) => {
                                                    this.onChangeSigningData("AuthorizedPostalCode", e.target.value);
                                                }}
                                                required={true}
                                            />
                                        </>
                                    }
                                    visible={true}
                                />,
                            ]}
                        />
                    </div>

                    <div className="form__section">
                        <p className="form__sectionHeader">{t('signingAuthorityForm.supportingDocuments')}</p>
                        <p className="description">{t('signingAuthorityForm.attachIDDescription')}</p>
                        <div className="form__element">
                            <p className="description__Inside">{t('signingAuthorityForm.identityDocumentFront')}</p>
                            <FileUpload
                                name={`IdFront`}
                                required={true}
                                parentId={this.props.ItemKey}
                                FileName={this.context.Documents[`IdFront${this.state.ID}`]?.DocumentName || ""}
                            />
                        </div>
                        <div className="form__element">
                            <p className="description__Inside">{t('signingAuthorityForm.identityDocumentBack')}</p>
                            <FileUpload
                                name={`IdBack`}
                                required={true}
                                parentId={this.props.ItemKey}
                                FileName={this.context.Documents[`IdBack${this.state.ID}`]?.DocumentName || ""}
                            />
                        </div>
                    </div>
                </div>
                <AddAnotherButton
                    onClick={this.props.AddAnotherForm}
                    onClickRemove={this.RemoveForm}
                    current={parseInt(this.props.ContactNumber) + 1}
                    count={this.props.length}
                    max={5}
                />
            </>
        );
    }
}
SigningAuthoritiesForm.contextType = FormContext;
export default withTranslation()(SigningAuthoritiesForm);