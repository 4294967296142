import React from 'react';
import styles from './Input.module.css';
export default class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
        value:this.props.value,
    }
    
    
  }
  componentWillReceiveProps = (nextProps) => {
      if(nextProps.value !== this.props.value){
        this.setState({
          value:nextProps.value,
        });
      }
  }
  handleChange = (event) => {
      if(this.props.HandleChange){
        this.props.HandleChange(event)
      }
      this.setState({
        value:event.target.value,
      });
  }
  render() {
    
    return (
        <input className={styles.input} 
        data-parsley-trigger="focusout"
        onChange={(e)=>this.handleChange(e) } 
        disabled={this.props.disabled} 
        onBlur={this.props.onFocusOut} 
        onFocus={this.props.onFocus}
        max={this.props.max} 
        min={this.props.min} 
        minlength={this.props.minLength} 
        maxlength={this.props.maxLength} 
        data-parsley-length={this.props.length}
        data-parsley-length-message={this.props.lengthMessage}
        type={this.props.type} 
        value={this.state.value} 
        name={this.props.name} 
        id={this.props.id} 
        step={this.props.step}
        required={this.props.required} 
        pattern={this.props.pattern} 
        placeholder={this.props.placeholder} />

    );
  }
}