import React from 'react';

export default class FormELement extends React.Component {
    render() {
      return (
        <>
          {this.props.visible &&  <div className="form__element">
            {this.props.label && <label for={this.props?.for}>{this.props.label}</label>}
            {this.props.helpText && <p className="description__helpText">{this.props.helpText}</p>}
            {this.props.feild}
          </div>}
        </>

      );
    }
  }