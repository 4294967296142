import React from 'react';
import Input from './../../components/Input/Input';
import Textarea from './../../components/Textarea/Textarea';
import Button from './../../components/Button/Button';
import FileUpload from './../../components/FileUpload/FileUpload';
import FormElement from '../../components/FormElement/FormElement';
import Checkbox from '../../components/Checkbox/Checkbox';
import Row from '../../components/Row/Row';
import styles from './BusinessDetailsContainer.module.css';
import { initializeGoogleAddress } from "./../../service/tools";
import PhoneNumberInput from '../../components/PhoneNumberInput/PhoneNumberInput';
import { getBusnessTypes } from "./../../service/api";
import { withTranslation } from 'react-i18next';
import { FormContext } from '../../context/FormContext';
import { translateText } from '../../service/translation';
import $ from 'jquery';
/* eslint-disable no-unused-vars */
import dateInput from 'date-input-polyfill-react';
import parsley from 'parsleyjs';
/* eslint-disable no-unused-vars */


class BusinessDetailsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            RegistrationProvince: "AB",
            BusinessTypes: {},
            BusinessTypesSubCategory: {},
            IndustryName: "",
            SubIndustryName: "",
            OrganizationalTypeName: "Individual or sole proprietorship",
            OrganizationalType: "Sole Trader",
            DocumentBRequired: sessionStorage.getItem(sessionStorage.getItem("Token") + "DocumentBRequired") !== "false",

        }

    }
    componentDidMount() {
        initializeGoogleAddress("Address", "Country", "Province", "City", "PostalCode", "CA");
        //Required parameter(s) missing: , , BusinessTypeID
    }
    onFocusOutAddress = (e) => {
        setTimeout(function () {
            let address = $("#Address").val();
            let city = $("#City").val();
            let state = $("#Province").val();
            let postcode = $("#PostalCode").val();
            let country = $("#Country").val();

            if (address) {
                $("#Address").parsley().reset();
            }
            if (city) {
                $("#City").parsley().reset();
            }
            if (state) {
                $("#Province").parsley().reset();
            }
            if (postcode) {
                $("#PostalCode").parsley().reset();
            }
            if (country) {
                $("#Country").parsley().reset();
            }

        }, 500);
    }
    componentWillMount() {
        let self = this;
        getBusnessTypes().then(
            data => {
                self.setState({
                    BusinessTypes: data.BusinessTypes,
                    BusinessTypesSubCategory: data.BusinessTypes[0].Categories,
                    IndustryName: data.BusinessTypes[0].Industry,
                    SubIndustryName: data.BusinessTypes[0].Categories[0].Category
                })

                setTimeout(() => {
                    if (self.context.BusinessData.BusinessTypeName !== "" && self.state.IndustryName !== self.context.BusinessData.BusinessTypeName) {
                        self.IndustryChangeInital(self.context.BusinessData);
                    }
                }, 500)

            },
            error => {
                self.setState({

                    IsOpen: true,
                    Description: error
                })
            });
    }
    IndustryChangeInital = (BusinessData) => {
        console.log(this.state.BusinessTypes);
        const BusinessType = Object.values(this.state.BusinessTypes).find((businessType) => {
            return businessType.Industry === BusinessData.BusinessTypeName;
        })
        var NewValue = BusinessType ? BusinessType?.BusinessTypeID : 1;
        var keysBusinessTypes = Object.keys(this.state.BusinessTypes);
        var BusinessTypesSubCategory = {};
        var FindIndustryName = "";
        var FindIndustrySubName = "";
        var FindIndustrySubId = "";
        var KeyFound = 0;
        keysBusinessTypes.forEach((key, index) => {
            if (this.state.BusinessTypes[key].BusinessTypeID === NewValue) {
                KeyFound = key;
                BusinessTypesSubCategory = this.state.BusinessTypes[key].Categories;
                FindIndustryName = this.state.BusinessTypes[key].Industry;
                FindIndustrySubName = this.state.BusinessTypes[key].Categories[0].Category;
                FindIndustrySubId = this.state.BusinessTypes[key].Categories[0].BusinessTypeCategoryID;
            }
        });
        const Category = Object.values(this.state.BusinessTypes[KeyFound].Categories).find((category) => {
            return category.Category === BusinessData.BusinessTypeCategoryName;
        })
        if (Category) {
            FindIndustrySubName = Category.Category;
            FindIndustrySubId = Category.BusinessTypeCategoryID;
        }
        this.context.UpdateBusinessData({
            BusinessTypeID: NewValue,
            BusinessTypeName: FindIndustryName,
            BusinessTypeCategoryID: FindIndustrySubId,
            BusinessTypeCategoryName: FindIndustrySubName,
        });
        this.setState({
            BusinessTypesSubCategory: BusinessTypesSubCategory,
            IndustryName: FindIndustryName,
            SubIndustryName: FindIndustrySubName
        });
    }
    IndustryChange = (e) => {
        var NewValue = e.target.value;
        var keysBusinessTypes = Object.keys(this.state.BusinessTypes);
        var BusinessTypesSubCategory = {};
        var FindIndustryName = "";
        var FindIndustrySubName = "";
        var FindIndustrySubId = "";
        keysBusinessTypes.forEach((key, index) => {
            if (this.state.BusinessTypes[key].BusinessTypeID === NewValue) {
                BusinessTypesSubCategory = this.state.BusinessTypes[key].Categories;
                FindIndustryName = this.state.BusinessTypes[key].Industry;
                FindIndustrySubName = this.state.BusinessTypes[key].Categories[0].Category;
                FindIndustrySubId = this.state.BusinessTypes[key].Categories[0].BusinessTypeCategoryID;
            }
        });
        this.context.UpdateBusinessData({
            BusinessTypeID: NewValue,
            BusinessTypeName: FindIndustryName,
            BusinessTypeCategoryID: FindIndustrySubId,
            BusinessTypeCategoryName: FindIndustrySubName,
        });

        this.setState({
            BusinessTypesSubCategory: BusinessTypesSubCategory,
            IndustryName: FindIndustryName,
            SubIndustryName: FindIndustrySubName
        });

    }
    SubIndustryChange = (e) => {

        var NewValue = e.target.value;
        var keysBusinessTypes = Object.keys(this.state.BusinessTypesSubCategory);
        var FindIndustryName = "";
        keysBusinessTypes.forEach((key, index) => {
            if (this.state.BusinessTypesSubCategory[key].BusinessTypeCategoryID === NewValue) {
                FindIndustryName = this.state.BusinessTypesSubCategory[key].Category;
            }
        });
        this.context.UpdateBusinessData({
            BusinessTypeCategoryID: NewValue,
            BusinessTypeCategoryName: FindIndustryName,
        });
        this.setState({

            SubIndustryName: FindIndustryName
        });

    }
    OrganizationalTypeChanged = (e) => {
        var NewValue = e.target.value;
        var NewLabel = NewValue;

        switch (NewValue) {
            case "Sole Trader":
                NewLabel = "Individual or sole proprietorship"
                break;
            case "Registered Charity":
                NewLabel = "Associations, Charities and Not for Profits"
                break;
            case "Incorporated Partnership":
                NewLabel = "Partnership"
                break;
            default:
                NewLabel = NewValue;
        }
        this.setState({
            OrganizationalTypeName: NewLabel,
            OrganizationalType: NewValue
        });
        this.context.UpdateBusinessData({ OrganizationalType: e.target.value });
    }
    registrationProvinceChanged = (e) => {
        this.context.UpdateBusinessData({ RegistrationProvince: e.target.value });
        this.setState({
            RegistrationProvince: e.target.value
        })
    }
    PrimaryChange = (e) => {
        if (e.target.checked) {
            this.setState({
                DocumentBRequired: false,
            });
            sessionStorage.setItem(sessionStorage.getItem("Token") + "DocumentBRequired", false);
            $("#ByLaws").parsley().reset();
        }
        else {
            this.setState({
                DocumentBRequired: true,
            })
            sessionStorage.setItem(sessionStorage.getItem("Token") + "DocumentBRequired", true);
        }
    }
    ContextChanged = (e) => {
        this.context.UpdateBusinessData({ [e.target.name]: e.target.value });
    }
    componentWillReceiveProps(nextProps) {

    }
    render() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        const { t, i18n } = this.props;
        today = yyyy + '-' + mm + '-' + dd;
        var keysBusinessTypes = Object.keys(this.state.BusinessTypes);
        var BusinessTypes = [];
        keysBusinessTypes.forEach((key) => {
            BusinessTypes.push(<option key={key} value={this.state.BusinessTypes[key].BusinessTypeID} >{translateText(this.state.BusinessTypes[key].Industry, i18n.language)} </option>);
        });
        var keysBusinessTypesSubCategory = Object.keys(this.state.BusinessTypesSubCategory);
        var BusinessTypesSubCategory = [];
        keysBusinessTypesSubCategory.forEach((key) => {
            BusinessTypesSubCategory.push(<option key={key} value={this.state.BusinessTypesSubCategory[key].BusinessTypeCategoryID} >{translateText(this.state.BusinessTypesSubCategory[key].Category, i18n.language)} </option>);
        });
        var RegistrationNumberLength = 9

        const RegistrationNumberMessage = translateText("Business number should be " + RegistrationNumberLength + " characters", i18n.language);
        const { BusinessData } = this.context;
        if (BusinessData.OrganizationalType !== "" && BusinessData.OrganizationalType !== this.state.OrganizationalType) {

            this.OrganizationalTypeChanged({ target: { value: BusinessData.OrganizationalType } })
        }
        if (BusinessData.Country === "") {
            this.context.UpdateBusinessData({ Country: "Canada" });
        }
        if (BusinessData.RegistrationProvince === "QC") {
            RegistrationNumberLength = 10;
        }

        return (
            <div>
                <h1 className="title">{t('businessDetailsContainer.title')}</h1>
                <p className="description desktopOnly">{t('businessDetailsContainer.verificationReportText')}</p>
                <p className="description mobileOnly">{t('businessDetailsContainer.informationRequestedText')}</p>
                <FormElement
                    label={t('businessDetailsContainer.legalBusinessNameLabel')}
                    for="LegalBusinessName"
                    feild={
                        <>
                            <Input
                                required={true}
                                id="LegalBusinessName"
                                name="LegalBusinessName"
                                type="text"
                                placeholder={t('businessDetailsContainer.companyPlaceholder')}
                                value={BusinessData.LegalBusinessName}
                                HandleChange={this.ContextChanged}
                            />
                            <p className="input__description">{t('businessDetailsContainer.businessNameDescription')}</p>
                        </>
                    }
                    visible={true}
                />
                <Row
                    items={[
                        <FormElement
                            label={t('businessDetailsContainer.businessRegistrationProvinceLabel')}
                            for="RegistrationProvince"
                            feild={
                                <select
                                    name="RegistrationProvince"
                                    id="RegistrationProvince"
                                    value={BusinessData.RegistrationProvince}
                                    onChange={(e) => this.registrationProvinceChanged(e)}
                                >
                                    <option value="AB">{t('businessDetailsContainer.albertaOption')}</option>
                                    <option value="BC">{t('businessDetailsContainer.britishColumbiaOption')}</option>
                                    <option value="MB">{t('businessDetailsContainer.manitobaOption')}</option>
                                    <option value="NB">{t('businessDetailsContainer.newBrunswickOption')}</option>
                                    <option value="NL">{t('businessDetailsContainer.newfoundlandAndLabradorOption')}</option>
                                    <option value="NT">{t('businessDetailsContainer.northwestTerritoriesOption')}</option>
                                    <option value="NS">{t('businessDetailsContainer.novaScotiaOption')}</option>
                                    <option value="NU">{t('businessDetailsContainer.nunavutOption')}</option>
                                    <option value="ON">{t('businessDetailsContainer.ontarioOption')}</option>
                                    <option value="PE">{t('businessDetailsContainer.princeEdwardIslandOption')}</option>
                                    <option value="QC">{t('businessDetailsContainer.quebecOption')}</option>
                                    <option value="SK">{t('businessDetailsContainer.saskatchewanOption')}</option>
                                    <option value="YT">{t('businessDetailsContainer.yukonOption')}</option>
                                </select>
                            }
                            visible={true}
                        />,
                        <FormElement
                            label={t('businessDetailsContainer.businessRegistrationNumberLabel')}
                            for="RegistrationNumber"
                            feild={
                                <>
                                    <Input
                                        required={true}
                                        type="number"
                                        id="RegistrationNumber"
                                        name="RegistrationNumber"
                                        placeholder={t('businessDetailsContainer.registrationNumberPlaceholder')}
                                        value={BusinessData.RegistrationNumber}
                                        lengthMessage={RegistrationNumberMessage}
                                        length={RegistrationNumberLength}
                                    />
                                    <p className="input__description">{t('businessDetailsContainer.businessRegistrationNumberDescription')}</p>
                                </>
                            }
                            visible={true}
                        />
                    ]}
                />
                <Row
                    items={[
                        <FormElement
                            label={t('businessDetailsContainer.dateOfIncorporationLabel')}
                            for="DateofIncorporation"
                            feild={
                                <Input
                                    required={true}
                                    type="date"
                                    id="DateofIncorporation"
                                    name="DateofIncorporation"
                                    value={BusinessData.DateofIncorporation}
                                    HandleChange={this.ContextChanged}
                                    max={today}
                                    placeholder={t('businessDetailsContainer.dateOfIncorporationPlaceholder')}
                                />
                            }
                            visible={true}
                        />,
                        <FormElement
                            label={t('businessDetailsContainer.taxIDLabel')}
                            for="TaxID"
                            feild={
                                <Input
                                    required={true}
                                    type="text"
                                    id="TaxID"
                                    name="TaxID"
                                    value={BusinessData.TaxID}
                                    HandleChange={this.ContextChanged}
                                    placeholder={t('businessDetailsContainer.taxIDPlaceholder')}
                                />
                            }
                            visible={true}
                        />

                    ]}
                />
                <FormElement
                    label={t('businessDetailsContainer.businessTypeLabel')}
                    for="OrganizationalType"
                    feild={
                        <>
                            <select
                                name="OrganizationalType"
                                id="OrganizationalType"
                                value={BusinessData.OrganizationalType}
                                required={true}
                                onChange={(e) => this.OrganizationalTypeChanged(e)}
                            >
                                <option value="Sole Trader">{t('businessDetailsContainer.soleTraderOption')}</option>
                                <option value="Corporation">{t('businessDetailsContainer.corporationOption')}</option>
                                <option value="Registered Charity">{t('businessDetailsContainer.registeredCharityOption')}</option>
                                <option value="Incorporated Partnership">{t('businessDetailsContainer.incorporatedPartnershipOption')}</option>
                                <option value="Public Limited Company">{t('businessDetailsContainer.publicLimitedCompanyOption')}</option>
                                <option value="Other">{t('businessDetailsContainer.otherOption')}</option>
                            </select>
                            <input type="hidden" name="OrganizationalTypeName" value={this.state.OrganizationalTypeName} />
                        </>
                    }
                    visible={true}
                />
                <Row
                    items={[
                        <FormElement
                            label={t('businessDetailsContainer.industryLabel')}
                            for="BusinessTypeID"
                            feild={
                                <>
                                    <select
                                        name="BusinessTypeID"
                                        id="BusinessTypeID"
                                        value={BusinessData.BusinessTypeID}
                                        onChange={(e) => this.IndustryChange(e)}
                                        required={true}
                                    >
                                        {BusinessTypes}
                                    </select>
                                </>
                            }
                            visible={true}
                        />,
                        <FormElement
                            label={t('businessDetailsContainer.industrySubCategoryLabel')}
                            for="BusinessTypeCategoryID"
                            feild={
                                <>
                                    <select
                                        name="BusinessTypeCategoryID"
                                        id="BusinessTypeCategoryID"
                                        value={BusinessData.BusinessTypeCategoryID}
                                        onChange={(e) => this.SubIndustryChange(e)}
                                        required={true}
                                    >
                                        {BusinessTypesSubCategory}
                                    </select>
                                </>
                            }
                            visible={true}
                        />
                    ]}
                />
                <input type="hidden" name="IndustryName" value={this.state.IndustryName} />
                <input type="hidden" name="SubIndustryName" value={this.state.SubIndustryName} />
                <Row
                    items={[
                        <FormElement
                            label={t('businessDetailsContainer.businessPhoneNumberLabel')}
                            for="PhoneNumber"
                            feild={
                                <PhoneNumberInput required={true} id="PhoneNumber" name="PhoneNumber" value={BusinessData.PhoneNumber} HandleChange={this.ContextChanged} />
                            }
                            visible={true}
                        />,
                        <></>
                    ]}
                />
                <Row
                    items={[
                        <FormElement
                            label={t('businessDetailsContainer.businessWebsiteLabel')}
                            for="Website"
                            feild={
                                <Input
                                    required={true}
                                    id="Website"
                                    name="Website"
                                    maxLength={1024}
                                    pattern="^\s*((http|ftp|https)\:\/\/)?([a-z\d\-]{1,63}\.)*[a-z\d\-]{1,255}\.[a-z]{2,6}\s*$"
                                    placeholder={t('businessDetailsContainer.websitePlaceholder')}
                                    value={BusinessData.Website}
                                    HandleChange={this.ContextChanged}
                                />
                            }
                            visible={true}
                        />,
                        <></>
                    ]}
                />
                <FormElement
                    label={this.state.OrganizationalType === "Registered Charity" ? t('businessDetailsContainer.entityPurposeLabel') : t('businessDetailsContainer.productDescriptionLabel')}
                    for="ProductDescription"
                    feild={
                        <Textarea required={true} maxLength="500" value={BusinessData.ProductDescription} HandleChange={this.ContextChanged} id="ProductDescription" name="ProductDescription" />
                    }
                    visible={true}
                />
                <div className="form__section">
                    <p className="form__sectionHeader">{t('businessDetailsContainer.registeredAddressHeader')}</p>
                    <Row
                        items={[
                            <FormElement
                                label={t('businessDetailsContainer.addressLabel')}
                                for="Address"
                                feild={
                                    <Input
                                        required={true}
                                        name="Address"
                                        onFocusOut={this.onFocusOutAddress}
                                        id="Address"
                                        placeholder={t('businessDetailsContainer.addressPlaceholder')}
                                        value={BusinessData.Address}
                                        HandleChange={this.ContextChanged}
                                    />
                                }
                                visible={true}
                            />,
                            <FormElement
                                label={t('businessDetailsContainer.cityLabel')}
                                for="City"
                                feild={
                                    <Input
                                        required={true}
                                        name="City"
                                        id="City"
                                        placeholder={t('businessDetailsContainer.cityPlaceholder')}
                                        value={BusinessData.City}
                                        HandleChange={this.ContextChanged}
                                    />
                                }
                                visible={true}
                            />,
                        ]}
                    />
                    <Row
                        items={[
                            <FormElement
                                label={t('businessDetailsContainer.provinceLabel')}
                                for="Province"
                                feild={
                                    <Input
                                        required={true}
                                        name="Province"
                                        id="Province"
                                        placeholder={t('businessDetailsContainer.provincePlaceholder')}
                                        value={BusinessData.Province}
                                        HandleChange={this.ContextChanged}
                                    />
                                }
                                visible={true}
                            />,
                            <FormElement
                                label={t('businessDetailsContainer.postalCodeLabel')}
                                for="PostalCode"
                                feild={
                                    <Input
                                        required={true}
                                        name="PostalCode"
                                        id="PostalCode"
                                        placeholder={t('businessDetailsContainer.postalCodePlaceholder')}
                                        value={BusinessData.PostalCode}
                                        HandleChange={this.ContextChanged}
                                    />
                                }
                                visible={true}
                            />,
                        ]}
                    />
                    <Row
                        items={[
                            <FormElement
                                label={t('businessDetailsContainer.countryLabel')}
                                for="Country"
                                feild={
                                    <>
                                        <Input
                                            required={true}
                                            disabled="disabled"
                                            name="Country"
                                            id="Country"
                                            placeholder={t('businessDetailsContainer.countryPlaceholder')}
                                            value={BusinessData.Country}
                                            HandleChange={this.ContextChanged}
                                        />
                                        <input value={BusinessData.Country} name="Country" type="hidden" />
                                    </>
                                }
                                visible={true}
                            />,
                            <></>,
                        ]}
                    />
                </div>
                {this.props.IsFinancials && (
                    <div className="form__section">
                        <p className="form__sectionHeader">{t('businessDetailsContainer.financialDetailsHeader')}</p>
                        <Row
                            items={[
                                <FormElement
                                    label={t('businessDetailsContainer.averagePaychequeAmountLabel')}
                                    for="AvgPaychequeAmount"
                                    feild={
                                        <Input
                                            type="number"
                                            step="0.01"
                                            required={true}
                                            name="AvgPaychequeAmount"
                                            id="AvgPaychequeAmount"
                                            placeholder={t('businessDetailsContainer.averagePaychequeAmountPlaceholder')}
                                            value={BusinessData.AvgPaychequeAmount}
                                            HandleChange={this.ContextChanged}
                                        />
                                    }
                                    visible={true}
                                />,
                                <FormElement
                                    label={t('businessDetailsContainer.numberOfEmployeesLabel')}
                                    for="NumberOfEmployees"
                                    feild={
                                        <Input
                                            required={true}
                                            type="number"
                                            name="NumberOfEmployees"
                                            id="NumberOfEmployees"
                                            placeholder={t('businessDetailsContainer.numberOfEmployeesPlaceholder')}
                                            value={BusinessData.NumberOfEmployees}
                                            HandleChange={this.ContextChanged}
                                        />
                                    }
                                    visible={true}
                                />,
                            ]}
                        />
                        <Row
                            items={[
                                <FormElement
                                    label={t('businessDetailsContainer.payrollFrequencyLabel')}
                                    for="PayrollFrequency"
                                    feild={
                                        <select
                                            name="PayrollFrequency"
                                            id="PayrollFrequency"
                                            placeholder={t('businessDetailsContainer.payrollFrequencyPlaceholder')}
                                            value={BusinessData.FrequencyofPayroll}
                                            onChange={(e) => this.ContextChanged(e)}
                                            required={true}
                                        >
                                            <option value="weekly">{t('businessDetailsContainer.weeklyOption')}</option>
                                            <option value="bi-weekly">{t('businessDetailsContainer.biWeeklyOption')}</option>
                                            <option value="monthly">{t('businessDetailsContainer.monthlyOption')}</option>
                                            <option value="other">{t('businessDetailsContainer.otherOption')}</option>
                                        </select>
                                    }
                                    visible={true}
                                />,
                                <></>

                            ]}
                        />
                    </div>
                )}

                <div className="form__section">
                    <p className="form__sectionHeader">{t('businessDetailsContainer.supportingDocumentationHeader')}</p>
                    <FormElement
                        feild={
                            <div className={styles.fileContainer}>
                                <p className="description__Inside">{t('businessDetailsContainer.constitutionLabel')}</p>
                                <FileUpload name="Constitution" FileName={this.context.Documents["Constitution"]?.DocumentName || ""} required={true} />
                            </div>
                        }
                        visible={this.state.OrganizationalType === "Registered Charity"}
                    />
                    <FormElement
                        feild={
                            <>
                                <div className={styles.fileContainer}>
                                    <p className="description__Inside">{t('businessDetailsContainer.byLawsLabel')}</p>
                                    <div style={{ paddingBottom: "15px" }}>
                                        <Checkbox onChangeCheck={this.PrimaryChange} id="DocumentBRequiredCheckBox" checked={!this.state.DocumentBRequired} />
                                        <label htmlFor="DocumentBRequiredCheckBox" className={styles.cheboxText}>{t('businessDetailsContainer.byLawsCheckboxLabel')}</label>
                                    </div>
                                    {this.state.DocumentBRequired && <FileUpload name="ByLaws" id="ByLaws" FileName={this.context.Documents["ByLaws"]?.DocumentName || ""} />}
                                </div>
                            </>
                        }
                        visible={this.state.OrganizationalType === "Registered Charity"}
                    />
                    <FormElement
                        feild={
                            <div className={styles.fileContainer}>
                                <p className="description__Inside">{t('businessDetailsContainer.certificateOfIncorporationLabel')}</p>
                                <FileUpload name="CertificateOfIncorporation" FileName={this.context.Documents["CertificateOfIncorporation"]?.DocumentName || ""} required={true} />
                            </div>
                        }
                        visible={this.state.OrganizationalType !== "Registered Charity"}
                    />
                    <FormElement
                        feild={
                            <div className={styles.fileContainer}>
                                <p className="description__Inside">{t('businessDetailsContainer.centralSecuritiesRegisterLabel')}</p>
                                <FileUpload name="RegisterCentralSecurities" FileName={this.context.Documents["RegisterCentralSecurities"]?.DocumentName || ""} required={true} />
                            </div>
                        }
                        visible={this.state.OrganizationalType !== "Registered Charity"}
                    />
                    <FormElement
                        feild={
                            <div className={styles.fileContainer}>
                                <p className="description__Inside">{t('businessDetailsContainer.articlesOfMemorandumLabel')}</p>
                                <FileUpload name="ArticlesOfMemrandum" FileName={this.context.Documents["ArticlesOfMemrandum"]?.DocumentName || ""} required={true} />
                            </div>
                        }
                        visible={this.state.OrganizationalType !== "Registered Charity"}
                    />
                    <FormElement
                        feild={
                            <div className={styles.fileContainer}>
                                <p className="description__Inside">{t('businessDetailsContainer.statementOfAccountLabel')}</p>
                                <FileUpload name="PD7A" FileName={this.context.Documents["PD7A"]?.DocumentName || ""} required={true} />
                            </div>
                        }
                        visible={this.props.IsFinancials}
                    />
                </div>
                <div className="buttonBar">
                    <Button
                        title={t("businessDetailsContainer.backButtonTitle")}
                        width="50Left"
                        backgroundColor={this.props.PrimaryColor}
                        onClickHandler={this.props.prevHandler}
                    />
                    <Button
                        title={t("businessDetailsContainer.nextButtonTitle")}
                        width="50Right"
                        backgroundColor={this.props.PrimaryColor}
                        onClickHandler={this.props.nextHandler}
                    />
                </div>
            </div>
        );
    }
}
BusinessDetailsContainer.contextType = FormContext;
export default withTranslation()(BusinessDetailsContainer);