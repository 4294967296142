import React from 'react';
import $ from 'jquery';
/* eslint-disable no-unused-vars */
import parsley from 'parsleyjs';
/* eslint-disable no-unused-vars */
import ReactModal from 'react-modal';
import Menu from './../../components/Menu/Menu';
import LegalTermsContainer from "./../LegalTermsContainer/LegalTermsContainer";

import styles from './TermsAndPricing.module.css';
import Button from './../../components/Button/Button';
import PricingContainer from '../PricingContainer/PricingContainer';
import OrderFormOverviewContainer from '../OrderFormOverviewContainer/OrderFormOverviewContainer';
import ThirdPartyStatement from '../ThirdPartyStatementContainer/ThirdPartySatement';
import AgreementDetailsContainer from '../AgreementDetailsContainer/AgreementDetailsContainer';
import Footer from'./../../components/Footer/Footer';
import Header from'./../../components/Header/Header';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {absolutePath, title} from "./../../service/tools";
import {generateEmbedUrl,getTokenInfo,GetBillingPackages, AcceptAgreement,PrintAgreement} from "./../../service/api";
import MenuItem from '../../components/MenuItem/MenuItem';
import SubMenuItem from '../../components/SubMenuItem/SubMenuItem'
import TermsAndPricingPrint from '../TermsAndPricingPrint/TermsAndPricingPrint';
import {setUser} from '../../service/datadog';
export default class TermsAndPricing extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            IsDetails:false,
            IsOwner:false,
            IsDirector:false,
            IsSupport:false,
            IsBank:false,
            IsLegal:false,
            IsOpen:false,
            IsOverview:true,
            IsPricing: false,
            IsThirdParty: false,
            IsAgreement:false,
            Description:"",
            Name: "",
            IsPartnerToken:false,
            Isloaded: false,
            SignerName:"",
            IPAddress: "192.168.111.1",
            Location: "Vancouver, British Columbia, CA",
            TimeStamp: "2021-11-15 13:58:13",
            AssignedBillingPackage: {}
        }
      
    }
    
    
    
    
   
    CloseModal = (e) => {
        e.preventDefault();
        this.setState({
            IsOpen:false
           
        })
    }
   
    MoveToLegalTerms = (e) => {
        e.preventDefault();
        $("#ThirdPartyStatement").parsley(
            {
                successClass: " ",
                errorClass: "input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            }
        );
        if($("#ThirdPartyStatement").parsley().validate()){
        this.setState({
            IsDetails:false,
            IsOwner:false,
            IsDirector:false,
            IsSupport:false,
            IsBank:false,
            IsLegal:true,
            IsOpen:false,
            IsOverview:false,
            IsPricing: false,
            IsThirdParty: false,
            IsAgreement:false,
            Description:""
        })
        var $a = $("#ThirdPartyStatement").clone();
       
        $("#ThirdPartyStatmentPrint").empty()
        $a.appendTo("#ThirdPartyStatmentPrint")


        $("#ContactInformationContainer").fadeOut();
        $("#SigningAuthoritiesContainer").fadeOut();
        $("#BankAccountInformation").fadeOut();
        $("#ShareHolderInformationContainer").fadeOut();
        $("#BusinessDetailsContainer").fadeOut();
        $("#AgreementDetailsContainer").fadeOut();
        $("#ThirdPartyStatement").fadeOut();
        $("#OverviewContainer").fadeOut();
        $("#PricingContainer").fadeOut();
        $("#LegalTermsContainer").delay(400).fadeIn();
        }
        
    }
    MoveToPricingInfo = (e) => {
       

        e.preventDefault();
       
       
            this.setState({
                IsDetails:false,
                IsOwner:false,
                IsDirector:false,
                IsSupport:false,
                IsBank:false,
                IsLegal:false,
                IsOpen:false,
                IsOverview:false,
                IsPricing: true,
                IsThirdParty: false,
                IsAgreement:false,
                Description:""
            })
            $("#ContactInformationContainer").fadeOut();
            $("#SigningAuthoritiesContainer").fadeOut();
            $("#BankAccountInformation").fadeOut();
            $("#ShareHolderInformationContainer").fadeOut();
            $("#BusinessDetailsContainer").fadeOut();
            $("#LegalTermsContainer").fadeOut();
            $("#ThirdPartyStatement").fadeOut();
            $("#OverviewContainer").fadeOut();
            $("#AgreementDetailsContainer").fadeOut();
            $("#PricingContainer").delay(400).fadeIn();
            
            
            
    }
    MoveToOverview = (e) => {
        e.preventDefault();
       
       
            this.setState({
                IsDetails:false,
                IsOwner:false,
                IsDirector:false,
                IsSupport:false,
                IsBank:false,
                IsLegal:false,
                IsOpen:false,
                IsOverview:true,
                IsPricing: false,
                IsThirdParty: false,
                IsAgreement:false,
                Description:""
            })
            $("#ContactInformationContainer").fadeOut();
            $("#SigningAuthoritiesContainer").fadeOut();
            $("#BankAccountInformation").fadeOut();
            $("#ShareHolderInformationContainer").fadeOut();
            $("#BusinessDetailsContainer").fadeOut();
            $("#LegalTermsContainer").fadeOut();
            $("#ThirdPartyStatement").fadeOut();
            $("#AgreementDetailsContainer").fadeOut();
            $("#OverviewContainer").delay(400).fadeIn();
            $("#PricingContainer").fadeOut();
    }
    MoveToThirdParty = (e) => {
        e.preventDefault();
       
       
            this.setState({
                IsDetails:false,
                IsOwner:false,
                IsDirector:false,
                IsSupport:false,
                IsBank:false,
                IsLegal:false,
                IsOpen:false,
                IsOverview:false,
                IsPricing: false,
                IsThirdParty: true,
                IsAgreement:false,
                Description:""
            })
            $("#ContactInformationContainer").fadeOut();
            $("#SigningAuthoritiesContainer").fadeOut();
            $("#BankAccountInformation").fadeOut();
            $("#ShareHolderInformationContainer").fadeOut();
            $("#BusinessDetailsContainer").fadeOut();
            $("#LegalTermsContainer").fadeOut();
            $("#ThirdPartyStatement").delay(400).fadeIn();
            $("#OverviewContainer").fadeOut();
            $("#AgreementDetailsContainer").fadeOut();
            $("#PricingContainer").fadeOut();
    }
    MoveToAgreement = (e) => {
        e.preventDefault();
       
        this.setState({
            IsDetails:false,
            IsOwner:false,
            IsDirector:false,
            IsSupport:false,
            IsBank:false,
            IsLegal:false,
            IsOpen:false,
            IsOverview:false,
            IsPricing: false,
            IsThirdParty: false,
            IsAgreement:true,
            Description:""
        })
        $("#ContactInformationContainer").fadeOut();
        $("#SigningAuthoritiesContainer").fadeOut();
        $("#BankAccountInformation").fadeOut();
        $("#ShareHolderInformationContainer").fadeOut();
        $("#BusinessDetailsContainer").fadeOut();
        $("#LegalTermsContainer").fadeOut();
        $("#ThirdPartyStatement").fadeOut();
        $("#AgreementDetailsContainer").delay(400).fadeIn();;
        $("#OverviewContainer").fadeOut();
        $("#PricingContainer").fadeOut();
    }
   
    
    MoveToComplete = (e) => {
        e.preventDefault();
        $("#submitting").fadeIn();
       
        let self = this;
       
        AcceptAgreement().then(
            dataAgreement =>{
                self.setState({
                    IPAddress: dataAgreement.DigitalSignature.IPAddress,
                    Location: dataAgreement.DigitalSignature.Location,
                    TimeStamp: dataAgreement.DigitalSignature.DateAdded,
                    SignerName: dataAgreement.DigitalSignature.SignerName
                })

                setTimeout(function(){
                    var html = self.getPrintHTML();
                    
                    var options = {
                        HtmlContent:html
                    }
                    PrintAgreement(options).then(
                        dataPrint =>{
                            $("#complete").fadeIn();
                        },
                        error =>{
                            self.setState({
                            
                                IsOpen:true,
                                Description:error
                            })
                        });
                },500);
            },
            error =>{
                self.setState({
                   
                    IsOpen:true,
                    Description:error
                })
            });

        //$("#complete").fadeIn();
    }
    componentWillMount(){
        var path = new URL(window.location.href).pathname;
        var paths = path.split("/")
        var token = paths[paths.length-1];
        title({
            title: 'Order Form'
           
          });
        localStorage.setItem("Token", token);
        let self = this;
        var options = {
            ApplicationType: "agreement"
        }

        setUser({
            ApplicationType: "agreement",
            Token: token
        })
        getTokenInfo(options).then(
            data =>{
                window.IsPartnerToken = data.IsPartnerToken
                self.setState({
                   
                    Name: data.Name,
                    IsPartnerToken:data.IsPartnerToken,
                    ApplicationType: "registration"
                 
                })
                GetBillingPackages().then(
                    data =>{
 
                        self.setState({
                            AssignedBillingPackage: data.AssignedBillingPackage,
                            Isloaded: true
                        })
                    },
                    error =>{
                      
                    });
            },
            error =>{
                self.setState({
                   
                    IsOpen:true,
                    Description:error
                })
                
            });
        generateEmbedUrl({PaymentSelectionMethod:"bank"}).then(
            data =>{
                self.setState({
                   
                    IframeUrl:data.EmbedURL,
                })
            },
            error =>{
              
               
            });
           
        
    }
    componentDidMount(){
        window.Parsley
        .addValidator('filemimetypes', {
            requirementType: 'string',
            validateString: function (value, requirement, parsleyInstance) {
                var file = parsleyInstance.$element[0].files;

                if (file.length === 0) {
                    return true;
                }

                var allowedMimeTypes = requirement.replace(/\s/g, "").split(',');
                return allowedMimeTypes.indexOf(file[0].type) !== -1;

            },
            messages: {
                en: 'File mime type not allowed'
            }
        });
        window.Parsley.addValidator('validphonemumber', {
            requirementType: 'string',
            validateString: function (value, requirement, parsleyInstance) {
                var value2 = parsleyInstance.$element.val();
                var isvalid = isValidPhoneNumber("+"+value2)
                return isvalid;
            },
            messages: {
                en: 'Invalid Phone Number'
            }
        });
        $('body').on('input', '.PhoneInputInput', function() {
            if($(this).parent().parent().find("input").last().parsley()._failedOnce){
                $(this).parent().parent().find("input").last().parsley().validate()
                if( $(this).parent().parent().find("input").last().parsley().isValid()){
                    $(this).parent().parent().removeClass("input--errorPhone");
                }
                else{
                    $(this).parent().parent().addClass("input--errorPhone");
                }
            }
            //do something
          });

          $('body').on('blur', '.PhoneInputInput', function() {
            $(this).parent().parent().find("input").last().parsley().validate()
            if($(this).parent().parent().find("input").last().parsley().isValid()){
              
                $(this).parent().parent().removeClass("input--errorPhone");
            }
            else{

                $(this).parent().parent().addClass("input--errorPhone");
            }
            //do something
          });
          
    
    }
    
    getPrintHTML(){
        var htmlString = "<html>" + $("html").html() + "</html>";
        var doc = new DOMParser().parseFromString(htmlString, "text/html");
        
       
        var link = doc.getElementsByTagName("link");
        var scripts = doc.getElementsByTagName("script");
        var imgs = doc.getElementsByTagName("img");
        doc.getElementById("PriceingPrint").style.display = "block";
        doc.getElementById("TermsAndPricing").style.display = "none";
        doc.getElementById("complete").style.display = "none";
        doc.getElementById("submitting").style.display = "none";
        for(var i = 0; i < link.length; i++) 
        {
            link[i].href= absolutePath( link[i].href);
            
        }
        for(var j = 0; j <  scripts.length; j++) 
        {
            scripts[j].remove();
            
        }
        for(var k = 0; k < imgs.length; k++) 
        {
            imgs[k].src= absolutePath(imgs[k].src);
            
        }

        var htmlNode = doc.documentElement.innerHTML;
        
        
        return  "<html>" + htmlNode + "</html>";
    
       
       
    }
    
    render() {
        var IsBillingPackage = false;
        if(this.state.AssignedBillingPackage.hasOwnProperty("BillingSubscriptions") ){
            IsBillingPackage = true;
        }
        var IsTerms = false;
        if(this.state.IsLegal || this.state.IsThirdParty || this.state.IsTerms){
            IsTerms = true;

        }
        if ( !this.state.Isloaded ) {
            return  (
                <div className={styles.ContentContainer}> 
                    <ReactModal
                    isOpen={this.state.IsOpen}>
                    <h1 id="heading">Alert</h1>
                    <div id="full_description">
                        <p>
                            {this.state.Description}
                        </p>
                        <Button title="Close"  onClickHandler={this.CloseModal}/>
                    </div>
                    </ReactModal>
                </div>
            )
        }
        else{
        return (
            <div>
                <div id="TermsAndPricing">
                    <Header title="Order Form"/>
                    <div className={styles.ContentContainer}> 
                            <ReactModal
                            isOpen={this.state.IsOpen}>
                            <h1 id="heading">Alert</h1>
                            <div id="full_description">
                                <p>
                                    {this.state.Description}
                                </p>
                                <Button title="Close"  onClickHandler={this.CloseModal}/>
                            </div>
                            </ReactModal>
                            <Menu >
                                
                                <MenuItem Active={this.state.IsOverview}>
                                <i className="icon-detials"></i>
                                            Overview
                                </MenuItem>
                                {(() =>{
                                    if(IsBillingPackage){
                                        return (   
                                            <MenuItem Active={this.state.IsPricing}>
                                            
                                                <i className="icon-pricing"></i>
                                                Pricing
                                            
                                            </MenuItem>
                                        );
                                    }
                                })()}
                                <MenuItem Active={IsTerms}>
                                
                                    <i className="icon-legal"></i>
                                        Legal Terms and Agreement
                            
                                </MenuItem>
                                {(() =>{
                                    if(IsBillingPackage){
                                        return(
                                        <SubMenuItem Active={this.state.IsAgreement }>
                                        
                                        <i className="icon-sub-square"></i>
                                                            Agreement Details
                                        
                                        </SubMenuItem>
                                        );
                                    }
                                })()}
                                <SubMenuItem Active={this.state.IsThirdParty }>
                                
                                <i className="icon-sub-square"></i>
                                            Third Party Statement
                                
                                </SubMenuItem>
                                <SubMenuItem Active={this.state.IsLegal }>
                                
                                <i className="icon-sub-square"></i>
                                            Legal Terms
                                
                                </SubMenuItem>
                                
                            
                                
                            </Menu>
                            <div className={styles.ContentArea}>
                                {(() =>{
                                    if(IsBillingPackage){
                                        return(
                                            <div id="OverviewContainer">
                                                <OrderFormOverviewContainer IsParentAccount={this.state.IsPartnerToken} nextHandler={this.MoveToPricingInfo} Name={this.state.Name} />
                                            </div>
                                        )
                                    }
                                    else{
                                        return(
                                            <div id="OverviewContainer">
                                                <OrderFormOverviewContainer IsParentAccount={this.state.IsPartnerToken} nextHandler={this.MoveToThirdParty} Name={this.state.Name} />
                                            </div>
                                        )
                                    }
                                    
                                })()}
                            
                            
                                <div id="PricingContainer">
                                    <PricingContainer nextHandler={this.MoveToAgreement} AssignedBillingPackage={this.state.AssignedBillingPackage} prevHandler={this.MoveToOverview} />
                                </div>
                            
                            
                            
                                
                                <div id="AgreementDetailsContainer">
                                    <AgreementDetailsContainer Name={this.state.Name} prevHandler={this.MoveToPricingInfo} AssignedBillingPackage={this.state.AssignedBillingPackage} nextHandler={this.MoveToThirdParty}/>
                                </div>
                                {(() =>{
                                    if(IsBillingPackage){
                                        return(
                                        <form id="ThirdPartyStatement">
                                            <ThirdPartyStatement nextHandler={this.MoveToLegalTerms} prevHandler={this.MoveToAgreement}/>
                                        </form>
                                        )
                                    }
                                    else{
                                        return(
                                        <form id="ThirdPartyStatement">
                                            <ThirdPartyStatement nextHandler={this.MoveToLegalTerms} prevHandler={this.MoveToOverview}/>
                                        </form>
                                        )
                                    }
                                })()}
                                
                                <div id="LegalTermsContainer">
                                    <LegalTermsContainer nextHandler={this.MoveToComplete}  prevHandler={this.MoveToThirdParty} />
                                </div>
                                <div id="submitting" className={styles.Complete}>
                                    <div className={styles.CompleteContainer}>
                                        <h2 className={styles.CompleteHeader}>Submitting form please don't navigate away from this page.</h2>
                                        <div className={styles.loader}></div>
                                    </div>  
                                </div>
                                <div id="complete" className={styles.Complete}>
                                    <div className={styles.CompleteContainer}>
                                        <h2 className={styles.CompleteHeader}>Thank you! You have successfully signed and submitted a document to VoPay. <br/>
                                                                        A copy of the document will be sent to your email address along with the next steps.</h2>
                                    </div>   
                                </div>
                            </div>
                    </div>
                    <Footer/>
                </div>
                <TermsAndPricingPrint Name={this.state.Name} SignerName={this.state.SignerName} IPAddress={this.state.IPAddress} Location={this.state.Location} TimeStamp={this.state.TimeStamp}/>
            </div>
        );
         }
    }
  }