import React from 'react';
import styles from './Footer.module.css';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
class Footer extends React.Component {
    setLanguage(language){
        const {i18n} = this.props;
        i18n.changeLanguage(language);
        window.Parsley.setLocale(language);
        this.triggerValidationForFieldsWithErrors()
    }
    getActiveClass(language){
        const {i18n} = this.props;
        if(language === i18n.language){
            return styles.footerButtonActive;
        }
        else{
            return "";
        }
    }
    triggerValidationForFieldsWithErrors() {
        var fieldsWithErrors = $("body").find('.input--error');
      
        fieldsWithErrors.each(function() {
          var field = $(this);
          var parsleyInstance = field.parsley();
          parsleyInstance.validate();
        });
    }
    render() {
        const { t } = this.props;
        return (
            <footer className={styles.footer}>
                <p className={styles.footerText}>
                    {t('footerComponent.poweredBy')}
                    <br />
                    <a target="_blank" rel="noreferrer" href="https://vopay.com/ca/legal/services/privacy-policy/">
                        {t('footerComponent.vopayPrivacyPolicy')}
                    </a>
                </p>
                <div className={styles.footerButtons}>
                    <button className={[styles.footerButton, this.getActiveClass("en")].join(" ")} type='button' onClick={() =>{this.setLanguage("en")}}>English</button>
                    <button className={[styles.footerButton,this.getActiveClass("fr")].join(" ")} type='button' onClick={() =>{this.setLanguage("fr")}}>Français</button>
                </div>
            </footer>
        );
    }
}

export default withTranslation()(Footer);
