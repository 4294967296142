import React from 'react';
import Button from './../../components/Button/Button';
import ShareholderForm from './../ShareHolderForm/ShareHolderForm';
import { withTranslation } from 'react-i18next';

class ShareHolderInformationContainer extends React.Component {
    constructor(props) {
        super(props);
        if (Object.keys(props.Shareholders || {}).length > 0) {
            this.state = {
                FormNum: Object.keys(props.Shareholders || {}).length,
                FormIds: [...Array(Object.keys(props.Shareholders || {}).length).keys()],
                Shareholders: { ...props.Shareholders },
                RemovedIds: [],
            }
        }
        else {
            this.state = {
                FormNum: 1,
                FormIds: [0],
                Shareholders: { 0: { ID: '', FullName: '', DOB: '', Ownership: '', Occupation: '', HomeAddress: '' } },
                RemovedIds: [],
            }
        }

    }
    componentWillReceiveProps = (nextProps) => {

        if (nextProps.Shareholders !== this.props.Shareholders) {
            if (Object.keys(nextProps.Shareholders || {}).length > 0) {
                this.setState({
                    FormNum: Object.keys(nextProps.Shareholders || {}).length,
                    Shareholders: { ...nextProps.Shareholders },
                    FormIds: [...Array(Object.keys(nextProps.Shareholders || {}).length).keys()]
                });
            }
            else {
                this.setState({
                    FormNum: 1,
                    Shareholders: { 0: { ID: '', FullName: '', DOB: '', Ownership: '', Occupation: '', HomeAddress: '', OwnerType: 'Individual' } },
                    FormIds: [0]
                })
            }
            this.props.updateRemovedShareHolders([]);
        }
    }
    filterObject(obj) {
        const asArray = Object.entries(obj);
        const filtered = asArray.filter(([key, value]) => !this.state.RemovedIds.includes(value.ID));
        return Object.fromEntries(filtered);
    }
    DataChanged = (Itemkey, ObjectKey, value) => {
        const Shareholders = { ...this.state.Shareholders };
        Shareholders[Itemkey][ObjectKey] = value;
        this.setState({
            Shareholders: Shareholders
        })
    }
    render() {
        var self = this;
        // function AddAnotherForm(e){
        //     e.preventDefault();
        //     self.setState({FormNum: (self.state.FormNum+1)})
        //     return false;
        //  }
        //  function RemoveAnotherForm(e){
        //     e.preventDefault();
        //     self.setState({FormNum: (self.state.FormNum-1)})
        //     return false;
        //  }
        function AddAnotherForm(e) {
            e.preventDefault();
            const Shareholders = { ...self.state.Shareholders }
            Shareholders[self.state.FormNum] = { ID: '', FullName: '', DOB: '', Ownership: '', Occupation: '', HomeAddress: '' };
            self.setState({
                FormNum: (self.state.FormNum + 1),
                FormIds: [...self.state.FormIds, self.state.FormNum],
                Shareholders: Shareholders
            })

            return false;
        }
        function RemoveAnotherForm(key, Id) {
            const splicedArray = [...self.state.FormIds].filter(v => v !== parseInt(key))
            const removedArray = Id ? [...self.state.RemovedIds, Id] : [...self.state.RemovedIds];
            const Shareholders = { ...self.state.Shareholders };
            delete Shareholders[key]
            self.props.updateRemovedShareHolders(removedArray);
            self.setState({
                FormIds: splicedArray,
                RemovedIds: removedArray,
                Shareholders: Shareholders,
                FormNum: (self.state.FormNum - 1),
            })
            return false;
        }
        let rows = [];
        let Shareholders = this.state.Shareholders;
        for (var i = 0; i < this.state.FormNum; i++) {
            // note: we are adding a key prop here to allow react to uniquely identify each
            // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
            rows.push(<ShareholderForm IsBusiness={false} ContactNumber={i} ItemKey={this.state.FormIds[i]} Total={this.state.FormNum} DataChanged={this.DataChanged} RemoveAnotherForm={RemoveAnotherForm} AddAnotherForm={AddAnotherForm} length={this.state.FormIds.length} ShareholderData={Shareholders ? (Shareholders[this.state.FormIds[i]] ? Shareholders[this.state.FormIds[i]] : {}) : {}} />);
        }
        const { t } = this.props;
        return (
            <div>
                <h1 className="title">{t('shareHolderInformationContainer.title')}</h1>
                <p className="description">{t('shareHolderInformationContainer.description1')}</p>
                <p className="description">{t('shareHolderInformationContainer.description2')}</p>
                <p className="description">{t('shareHolderInformationContainer.description3')}</p>
                {rows}

                <div className="buttonBar">
                    <Button title={t('button.back')} width="50Left" backgroundColor={this.props.PrimaryColor} onClickHandler={this.props.prevHandler} />
                    <Button title={t('button.next')} width="50Right" backgroundColor={this.props.PrimaryColor} onClickHandler={this.props.nextHandler} />
                </div>
            </div>
        );
    }
}
export default withTranslation()(ShareHolderInformationContainer);