import React from 'react';
import styles from './Textarea.module.css';
export default class Textarea extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
        value:this.props.value,
    }
    
    
  }
  componentWillReceiveProps = (nextProps) => {
      if(nextProps.value !== this.props.value){
        this.setState({
          value:nextProps.value,
        });
      }
  }
  handleChange = (event) => {
      if(this.props.HandleChange){
        this.props.HandleChange(event)
      }
      this.setState({
        value:event.target.value,
      });
  }
    render() {
      return (
          <textarea className={styles.textarea} 
            data-parsley-trigger="focusout" 
            data-parsley-maxlength={this.props.maxLength}  
            name={this.props.name} 
            id={this.props.id} 
            onChange={(e)=>this.handleChange(e) } 
            required={this.props.required} 
            placeholder={this.props.placeholder}
            value={this.state.value}
             ></textarea>
      );
    }
  }