import React from 'react';
import Button from './../../components/Button/Button';
import FormElement from './../../components/FormElement/FormElement';
import FileUpload from './../../components/FileUpload/FileUpload';
import ReactModal from 'react-modal';
import {generateEmbedUrl, deleteCreditCard, deleteBank, submitFiles} from "./../../service/api";
import { convertBase64  } from "./../../service/tools";
import styles from './LinkBankAccountContainer.module.css';
import $ from 'jquery';
import Select from 'react-select'
import {FormContext} from '../../context/FormContext';
import Checkbox from '../../components/Checkbox/Checkbox';
import { withTranslation } from 'react-i18next';
import { translateText } from '../../service/translation';
class LinkBankAccountContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state ={
            IframeUrl:"about:blank",
            IQ11Token:"",
            institution:"VOPAY TESTING BANK",
            AccountNumber:"***12332",
            BankId: 1894,
            InstitutionNumber:9999,
            IQ11TokenCreditCard:"",
            CreditCardMaskedNumber:"5576",
            CreditCardBrand:"visa",
            CreditCardHolderName:"",
            IsCreditCard: false,
            IsRemoveBankAccount: false,
            IsRemoveCreditCard:false,
            showIframe:false,
            DefaultPaymentMethod: "bank"
        }
        
    }
    componentWillReceiveProps(nextProps) {

        // this.setState({
        //     IframeUrl:nextProps.IframeUrl,
          
        // });
     }
    componentDidMount(){
        var self = this;
        this.setState({
            IframeUrl: this.props.IframeUrl
        })
        window.addEventListener('message', function(e) {
			if(e.data.Step === "LINK"){
                if(self.state.IsCreditCard){
                    self.setState({
                        CreditCardBrand:e.data.CreditCardBrand.toLowerCase(),
                        CreditCardMaskedNumber: e.data.CreditCardNumber,
                        CreditCardHolderName: e.data.CreditCardHolderName,
                        IQ11TokenCreditCard: e.data.Token,
                        DefaultPaymentMethod: "credit",
                        IframeUrl: "about:blank",
                        showIframe: false
                    })
                    self.context.UpdateBankingInformation({
                        CreditCardBrand:e.data.CreditCardBrand.toLowerCase(),
                        CreditCardMaskedNumber: e.data.CreditCardNumber,
                        CreditCardHolderName: e.data.CreditCardHolderName,
                        IQ11TokenCreditCard: e.data.Token,
                        DefaultPaymentMethod: "credit"
                    })
                   
                    $("#LinkAccount").hide();
                    $("#Complete").show();
                }
                else{
                    if(self.context.BankingInformation.IQ11Token){
                        deleteBank({
                            Iq11Token: self.context.BankingInformation.IQ11Token
                        })
                    }
    
                    self.setState({
                        institution:e.data.Institution,
                        AccountNumber: e.data.AccountNumber || "",
                        BankId: e.data.BankId,
                        InstitutionNumber:e.data.InstitutionNumber,
                        IQ11Token: e.data.Token,
                        IframeUrl: "about:blank",
                        showIframe: false
                    })
                    self.context.UpdateBankingInformation({
                        institution:e.data.Institution,
                        AccountNumber: e.data.AccountNumber || "",
                        BankId: e.data.BankId,
                        InstitutionNumber:e.data.InstitutionNumber,
                        IQ11Token: e.data.Token,
                        ConnectionType: e.data.ConnectionType,
                        DefaultPaymentMethod: self.context.BankingInformation.DefaultPaymentMethod !== "vopay" ? self.context.BankingInformation.DefaultPaymentMethod :"bank"
                    })
       
                    $("#LinkAccount").hide();
                    $("#Complete").show();
                }
              
                self.props.SaveFormProgress()
			}
	    });
    }
    backToLink(e){
        var self = this;
        e.preventDefault()
        self.setState({
            IQ11Token:"",
            institution:"VOPAY TESTING BANK",
            AccountNumber:"***12332",
            BankId: 1894,
            InstitutionNumber:9999,
        })
        self.context.UpdateBankingInformation({
            IQ11Token:"",
            institution:"VOPAY TESTING BANK",
            AccountNumber:"***12332",
            BankId: 1894,
            InstitutionNumber:9999,
        })
        $("#Complete").hide();
        $("#LinkAccount").show();
    }
    ConnectBank(e){
        var self = this;
        e.preventDefault()
        this.setState({
            IsCreditCard: false,
            IsRemoveBankAccount:false,
        })
        const { i18n } = this.props;
        generateEmbedUrl({PaymentSelectionMethod:"bank", Language: i18n.language}).then(
            data =>{
              
                self.setState({
                    IframeUrl:data.EmbedURL,
                    showIframe: true,
                })
               
            },
            error =>{
                
            }
        );
       
    }
    ConnectCreditCard(e){
        var self = this;
        e.preventDefault();
        this.setState({
            IsCreditCard: true
        })
        const { i18n } = this.props;
        generateEmbedUrl({PaymentSelectionMethod:"credit", Language: i18n.language}).then(
            data =>{
            
                self.setState({
                    IframeUrl:data.EmbedURL,
                    showIframe: true
                })
                
            },
            error =>{
                
            }
        );
       
    }
    CloseFrame(e){
        e.preventDefault();

        this.setState({
            IframeUrl: "about:blank",
            showIframe: false
        });
    }
    CloseRemove(e){
        e.preventDefault();
        this.setState({
            IsRemoveCreditCard:false
        })
    }
    ShowDeleteCreditCardRemove(e){
        e.preventDefault();
        this.setState({
            IsRemoveCreditCard:true
        })
    }
    ShowDeleteBankAccount(e){
        e.preventDefault();
        this.setState({
            IsRemoveBankAccount:true
        })
    }
    SelectDefualt(newValue){
        this.setState({
            DefaultPaymentMethod: newValue.value
        })
        this.context.UpdateBankingInformation({
            DefaultPaymentMethod: newValue.value
        })
        let self = this;
        setTimeout(function(){
            self.props.SaveFormProgress()
        },300)
       
    }
    DeleteCreditCard(e){
        e.preventDefault();
        deleteCreditCard({
            CreditCardToken: this.context.BankingInformation.IQ11TokenCreditCard
        })
        this.setState({
            DefaultPaymentMethod: "bank",
            IQ11TokenCreditCard: "",
            IsRemoveCreditCard:false
        })
        this.context.UpdateBankingInformation({
            DefaultPaymentMethod: "bank",
            IQ11TokenCreditCard: "",
        })
        this.props.SaveFormProgress()
    }
    imgError(e) {
        // this will step up to get the parent of the image's parent
        var image = e.target;
        image.remove();
        return true;
    }
    getLast4(value){
        return;
    }
    FileChange(e){
        let self = this;
        e.preventDefault();
        if(e.target.files[0] ){
            var fileExtension = e.target.files[0].name.split(".")[1];
    
            if(fileExtension.toLowerCase() === "png" || fileExtension.toLowerCase() === "pdf" || fileExtension.toLowerCase() === "jpeg" || fileExtension.toLowerCase() === "jpg" ){
           
                convertBase64(e.target).then(
                    data =>{
                        var options ={
                            DocumentName: (e.target.files[0].name),
                            DocumentContent: data,
                            DocumentType: "bankinfo"
                        }
                    
                        //self.context.UpdateDocumentsBank([...self.context.DocumentsBanks, options.DocumentName]);
                        submitFiles(options).then(
                            () =>{
                                self.context.UpdateDocumentsBank([...self.context.DocumentsBanks, options.DocumentName]);
                            }
                        )
                        $(e.target).val("");
                    }
                );
            }
            else{
                $(e.target).val("");
            }
        }
    }
    getLuminance(color) {
        // Remove "#" if present
        color = color.replace("#", "");
    
        // Convert color to RGB
        const r = parseInt(color.substr(0, 2), 16);
        const g = parseInt(color.substr(2, 2), 16);
        const b = parseInt(color.substr(4, 2), 16);
    
        // Calculate relative luminance
        const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
    
        return luminance;
    }
    getContrastColor(background) {
        const luminance = this.getLuminance(background);
        return luminance > 0.5 ? "#000000" : "#ffffff"; // Use black or white depending on luminance threshold
      }
    render() {
        const { t, i18n } = this.props; // Access the translation function
        var image = "https://embed-dev.vopay.com/images/logos-banks/1835.svg";
        if(this.context.BankingInformation.BankId >= 1681 && this.context.BankingInformation.BankId <= 1729){
            image = "https://embed-dev.vopay.com/images/logos-banks/uni.svg"

        }
        else if(this.context.BankingInformation.BankId >= 1823 && this.context.BankingInformation.BankId <= 1835){
            image = "https://embed-dev.vopay.com/images/logos-banks/1835.svg"
        }
        else{
            image = "https://embed-dev.vopay.com/images/logos-banks/"+this.context.BankingInformation.BankId+".svg"
        }
        const imageCreditCard = "https://embed-dev.vopay.com/images/"+this.context.BankingInformation.CreditCardBrand+".png"
        const reactOptions = [
            { value: 'bank', label: <div><img className={styles.greyBoxLogo} onError={e => this.imgError(e)} src={image} alt="logo" />{translateText(this.context.BankingInformation.institution, i18n.language)} {this.context.BankingInformation.AccountNumber.slice(-4)} </div>},
            { value: 'credit', label: <div><img className={styles.greyBoxLogo} onError={e => this.imgError(e)} src={imageCreditCard} alt="logo" /> ****{this.context.BankingInformation.CreditCardMaskedNumber.slice(-4)}</div> }];
        let inputRef;

        return (
            <div>
                
                <ReactModal
                isOpen={this.state.IsRemoveBankAccount}
                className="confermation__modal"
                overlayClassName="confermation__modalOverlay"
                >
                <h1 id="heading">{t('linkBankAccountContainer.bankAccountCannotBeRemoved')}</h1>
                <div>
                    <p>{t('linkBankAccountContainer.addAnotherBankAccount')}</p>
                    <div className='buttonBar--center'>
                    <Button
                        title={t('linkBankAccountContainer.understood')}
                        containerDisplay="inline"
                        buttonStyle="round"
                        onClickHandler={(e) => { this.ConnectBank(e) }}
                    />
                    </div>
                </div>
                </ReactModal>
                <ReactModal
                isOpen={this.state.IsRemoveCreditCard}
                className="confermation__modal"
                overlayClassName="confermation__modalOverlay"
                >
                <h1 id="heading">{t('linkBankAccountContainer.confirmCreditCardRemoval')}</h1>
                <div>
                    <div className='buttonBar--center'>
                    <Button
                        title={t('linkBankAccountContainer.no')}
                        containerDisplay="inline"
                        buttonStyle="round"
                        buttonColor="white"
                        onClickHandler={(e) => { this.CloseRemove(e) }}
                    />
                    <Button
                        title={t('linkBankAccountContainer.yes')}
                        containerDisplay="inline"
                        buttonStyle="round"
                        onClickHandler={(e) => { this.DeleteCreditCard(e) }}
                    />
                    </div>
                </div>
                </ReactModal>
                {this.state.showIframe && (
                <div className={styles.Iframe}>
                    <button onClick={e => this.CloseFrame(e)} className={styles.cross}>
                    <img className={styles.crossImg} alt="cross" src="/images/cross.png" />
                    </button>
                    <iframe seamless title={t('linkBankAccountContainer.loginBanking')} src={this.state.IframeUrl}></iframe>
                </div>
                )}
                <input type="hidden" name="Iq11Token" required value={this.context.BankingInformation.IQ11Token}></input>
                <input type="hidden" name="AccountNumber" value={this.context.BankingInformation.AccountNumber}></input>
                <input type="hidden" name="BankId" value={this.context.BankingInformation.BankId}></input>
                <input type="hidden" name="institution" value={translateText(this.context.BankingInformation.institution , i18n.language)}></input>

                <input type="hidden" name="IQ11TokenCreditCard" value={this.context.BankingInformation.IQ11TokenCreditCard}></input>
                <input type="hidden" name="CreditCardMaskedNumber" value={this.context.BankingInformation.CreditCardMaskedNumber}></input>
                <input type="hidden" name="CreditCardBrand" value={this.context.BankingInformation.CreditCardBrand}></input>

                <input type="hidden" name="ConnectionType" value={this.context.BankingInformation.ConnectionType}></input>
                <input type="hidden" name="DefaultPaymentMethod" value={this.context.BankingInformation.DefaultPaymentMethod}></input>

                <h1 className="title">{t('linkBankAccountContainer.connectYourAccount')}</h1>
                <p className="description">
                {t('linkBankAccountContainer.connectAccountDescription')}
                </p>
                {this.context.BankingInformation.IQ11Token === "" && (
                <p className='description'>
                    {t('linkBankAccountContainer.bankTransactionRequirement1')}
                </p>
                )}
                {this.context.BankingInformation.IQ11Token !== "" && this.context.BankingInformation.ConnectionType === "manual" && (
                <p className='description'>
                    {t('linkBankAccountContainer.bankTransactionRequirement2')}
                </p>
                )}
                {this.context.BankingInformation.IQ11Token === "" && (
                <div id="LinkAccount" className={styles.ContainerLink}>
                    <div>
                    <button onClick={e => this.ConnectBank(e)}
                            style={
                                this.props.PrimaryColor ? {
                                    "background-color": this.props.PrimaryColor,
                                    border: `${this.props.PrimaryColor} 1px solid`,
                                    color: this.getContrastColor(this.props.PrimaryColor)
                                }: {}
                            }
                            type="button" className={styles.ConnectButton}>
                        {t('linkBankAccountContainer.connectPrimaryBankAccount')}
                    </button>
                    <p className={styles.connectDescription}>{t('linkBankAccountContainer.redirectNotice')}</p>
                    </div>
                </div>
                )}
              
              {this.context.BankingInformation.IQ11Token !== "" && 
                <div id="Complete">  
                    <div>
                    <h2 className={styles.CardBankSubHeader}>{t('linkBankAccountContainer.paymentMethods')}</h2>
                    <div className={styles.Card}>
                        <div className={styles.CardBankingInfo}>
                        <img className={styles.CardLogo} onError={e => this.imgError(e)} src={image} alt="logo" />
                        { translateText(this.context.BankingInformation.institution, i18n.language )} {this.context.BankingInformation.AccountNumber}
                        <button className={styles.CardRemove} onClick={e => this.ShowDeleteBankAccount(e)}>
                            <img className={styles.crossImgBank} src='/images/cross-grey.svg' alt="Delete Icon"/>
                        </button>
                        </div>
                    </div>
                    { this.context.BankingInformation.IQ11TokenCreditCard !== "" && 
                        <div className={styles.Card}>
                        <div className={styles.CardBankingInfo}>
                            <img className={styles.CardLogo} onError={e => this.imgError(e)} src={imageCreditCard} alt="logo" />
                            {t('linkBankAccountContainer.endingIn')} {this.context.BankingInformation.CreditCardMaskedNumber.slice(-4)}
                            <button className={styles.CardRemove} onClick={e => this.ShowDeleteCreditCardRemove(e)}>
                            <img className={styles.crossImgBank} src='/images/cross-grey.svg' alt="Delete Icon"/>
                            </button>
                        </div>
                        </div>
                    }
                    { this.context.BankingInformation.IQ11TokenCreditCard === "" && 
                        <div>
                        <button className={styles.AddPaymentMethod} onClick={e => this.ConnectCreditCard(e)}>
                            {t('linkBankAccountContainer.addPaymentMethod')}
                        </button>
                        </div>
                    }
                    </div>
                    {this.context.BankingInformation.ConnectionType === "manual" &&
                    <div className={styles.StatementsFilesContainer}>
                        <h2 className={styles.CardBankSubHeader}>{t('linkBankAccountContainer.uploadBankStatement')}</h2>
                        <p className="description">{t('linkBankAccountContainer.uploadBankStatementDescription')}</p>
                        {this.context.DocumentsBanks.length === 0 && <FormElement
                        feild={
                            <div className={styles.fileContainer}>
                            <p className="description__Inside description__Inside--bold">
                                { translateText(this.context.BankingInformation.institution, i18n.language)} *** **** {this.context.BankingInformation.AccountNumber.slice(-4)}
                            </p>
                            <FileUpload name="BankStatment" handleChange={e => this.FileChange(e)} documentType="bank" required={true} />
                            </div>
                        }
                        visible={this.context.BankingInformation.ConnectionType === "manual"}
                        />}
                        {this.context.DocumentsBanks.length !== 0 && 
                        <div className={styles.UploadedFilesContainer}>
                            <h2 className={styles.CardBankTertiaryHeader}>{t('linkBankAccountContainer.uploadedFiles')}</h2>
                            <ul className={styles.BankStatmentList}>
                            {this.context.DocumentsBanks.map(function(item){
                                return <li>{item}</li>
                            })}
                            </ul>
                            <button className={styles.AddPaymentMethod} type='button' onClick={() => inputRef.click()}>
                            {t('linkBankAccountContainer.uploadAdditionalBankStatement')}
                            </button>
                            <input className={styles.BankStatmentHiddenFileInput} type="file" ref={refParam => inputRef = refParam} data-parsley-filemimetypes="image/jpeg,image/png,application/pdf"  onChange={e => this.FileChange(e)}  accept="image/jpeg,image/png,application/pdf" />
                        </div>
                        }
                    </div>
                    }
                    <div className={styles.BillingSection}>
                    <h2 className={styles.CardBankSubHeader}>{t('linkBankAccountContainer.billingSettings')}</h2>
                    <div className={styles.BillingOption}>
                        <span className={styles.BillingOptionLeft}>{t('linkBankAccountContainer.securityDeposit')}</span>
                        <span className={styles.BillingOptionRight}>
                        <div className={styles.greyBox}>
                            <img className={styles.greyBoxLogo} onError={e => this.imgError(e)} src={image} alt="logo" />
                            { translateText(this.context.BankingInformation.institution, i18n.language)} {this.context.BankingInformation.AccountNumber.slice(-4)}
                        </div>
                        </span>
                    </div>
                    <div className={styles.BillingOption}>
                        { this.context.BankingInformation.IQ11TokenCreditCard === "" && <span className={styles.BillingOptionLeft}>{t('linkBankAccountContainer.monthlySetUpFee')}</span>}
                        { this.context.BankingInformation.IQ11TokenCreditCard !== "" && <label htmlFor="DefaultPaymentMethodSelect" className={styles.BillingOptionLeft}>{t('linkBankAccountContainer.monthlySetUpFee')}</label>}
                        <span className={styles.BillingOptionRight}>
                        { this.context.BankingInformation.IQ11TokenCreditCard === "" && 
                            <div className={styles.greyBox}>
                            <img className={styles.greyBoxLogo} onError={e => this.imgError(e)} src={image} alt="logo" />
                            { translateText(this.context.BankingInformation.institution, i18n.language)} {this.context.BankingInformation.AccountNumber.slice(-4)}
                            </div>
                        }
                        {this.context.BankingInformation.IQ11TokenCreditCard !== "" && 
                            <Select
                            className="select--grey"
                            classNamePrefix="react-select"
                            options={reactOptions}
                            onChange={(newValue) => this.SelectDefualt(newValue)}
                            inputId="DefaultPaymentMethodSelect"
                            value={reactOptions.filter(option => option.value === this.context.BankingInformation.DefaultPaymentMethod)}
                            />
                        }
                        </span>
                    </div>
                    </div>
                    <div className={styles.checkboxValues}>
                        <Checkbox name="Proceed" id="padAgreeCheckbox" required={true} onChangeCheck={() => {}} data-parsley-errors-container="#parsley-id-multiple-Proceed-container" />
                        <label for="padAgreeCheckbox" className={styles.text}>
                            {t('linkBankAccountContainer.proceedText')}{' '}
                            <a href='https://vopay.com/ca/legal/pad/' rel="noreferrer" target={"_blank"}>
                                {t('linkBankAccountContainer.preAuthorizedDebitAgreement')}
                            </a>{' '}
                            {t('linkBankAccountContainer.authorizeText')}
                        </label>
                    </div>
                    <p id="parsley-id-multiple-Proceed-container" className='filled'></p>
                </div>
                }
                <div className="buttonBar">
                    <Button title={t('linkBankAccountContainer.back')} width="50Left" backgroundColor={this.props.PrimaryColor} onClickHandler={this.props.prevHandler} />
                    <Button title={t('linkBankAccountContainer.next')} width="50Right" backgroundColor={this.props.PrimaryColor} onClickHandler={this.props.nextHandler} />
                </div>
            </div>
        );
    }
  }
  LinkBankAccountContainer.contextType = FormContext;
  export default withTranslation()(LinkBankAccountContainer);