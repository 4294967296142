import React from 'react';
import styles from './Checkbox.module.css';
export default class Checkbox extends React.Component {
    render() {
      return (
        <div className={styles.main}>
              <input type="checkbox" onChange={e => this.props.onChangeCheck(e)} checked={this.props.checked} 
              disabled={this.props.Disabled} value={this.props.value} name={this.props.name} id={this.props.id} required={this.props.required} 
              pattern={this.props.pattern} placeholder={this.props.placeholder} data-parsley-errors-container={this.props["data-parsley-errors-container"]} />
           
            <span className={styles.box}></span>
        </div>
       
      );
    }
  }