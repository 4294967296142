import React from 'react';
import Button from '../../components/Button/Button';
import RadioButton from '../../components/RadioButton/RadioButton';
import styles from '../LegalTermsContainer/LegalTermsContainer.module.css';
import $ from 'jquery';
export default class ThirdPartyStatement extends React.Component {
    constructor(props) {
        super(props);
        this.state ={Yes: true}
        
    }
    CheckboxOnchangeYes = (e) => {
    
        $(e.target).parent().parent().find("input[checked]")[0].removeAttribute("checked")
        e.target.setAttribute("checked", "checked"); 
        this.setState({
            Yes: true
        });
    }
    CheckboxOnchangeNo = (e) => {
        $(e.target).parent().parent().find("input[checked]")[0].removeAttribute("checked")
        e.target.setAttribute("checked", ""); 
        this.setState({
            Yes: false
        });
    }
    render() {
        return (
            <div>
             <h1 className="title">Third Party Determination Statement</h1>

                <p className="description">A ‘Third Party’ is defined as anyone other than the designated signing officer(s) for the account, who will make frequent deposits or provide direction on behalf of the main account holder(s) or control account assets held by VoPay, on behalf of the main account holder(s). </p>
                <div className={styles.LegalRadioContainer}>
                    <p className="question__header">Will Your VoPay Account be used by, or on behalf of a Third Party?</p>
                  
                    {(() =>{
                            if(this.state.Yes){
                                return(
                                    <div className={styles.RadioButtonContainer}>
                                        <RadioButton onChange={this.CheckboxOnchangeYes} name="ThirdPartyUse" value={true} checked  label="Yes"></RadioButton>
                                        <RadioButton  onChange={this.CheckboxOnchangeNo}  name="ThirdPartyUse" value={false}   label="No"></RadioButton>
                                    </div>
                                )
                            }
                            else{
                                return(
                                    <div className={styles.RadioButtonContainer}>
                                        <RadioButton onChange={this.CheckboxOnchangeYes} name="ThirdPartyUse" value={true}  label="Yes"></RadioButton>
                                        <RadioButton  onChange={this.CheckboxOnchangeNo}  name="ThirdPartyUse" value={false} checked label="No"></RadioButton>
                                    </div>
                                )
                            }
                            
                        })()}
                          
                            
                            
                  
                </div>
               
                
                <div className="buttonBar">
                <Button title="Prev" width="50Left" onClickHandler={this.props.prevHandler}/>
                <Button title="Next" width="50Right"  onClickHandler={this.props.nextHandler}/>
                </div>
            </div>
        );
    }
  }