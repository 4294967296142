import React from 'react';
import { withTranslation } from 'react-i18next';
import InfoTable from '../../components/InfoTable/InfoTable';
import InfoTableItem from '../../components/InfoTableItem/InfoTableItem';

class ShareholderDetailsInfo extends React.Component {
    render() {
        const { t } = this.props; // Access the translation function

        return (
            <div>
                <h3 className="title__minor">{t('shareholderDetailsInfo.title')}</h3>

                <InfoTable>
                    <InfoTableItem Label={t('shareholderDetailsInfo.fullNameLabel')} Value={this.props.Data.ShareholderFullName} />
                    <InfoTableItem Label={t('shareholderDetailsInfo.ownershipLabel')} Value={this.props.Data.ShareholderOwnership} />
                    <InfoTableItem Label={t('shareholderDetailsInfo.occupationLabel')} Value={this.props.Data.ShareholderOccupation} />

                    <InfoTableItem Label={t('shareholderDetailsInfo.addressLabel')} Value={this.props.Data.ShareholderAddress1} />
                    <InfoTableItem Label={t('shareholderDetailsInfo.cityLabel')} Value={this.props.Data.ShareholderCity} />
                    <InfoTableItem Label={t('shareholderDetailsInfo.provinceLabel')} Value={this.props.Data.ShareholderProvince} />
                    <InfoTableItem Label={t('shareholderDetailsInfo.countryLabel')} Value={this.props.Data.ShareholderCountry} />
                    <InfoTableItem Label={t('shareholderDetailsInfo.postalCodeLabel')} Value={this.props.Data.ShareholderPostalCode} />
                    <InfoTableItem Label={t('shareholderDetailsInfo.countryLabel')} Value={this.props.Data.ShareholderCountry} />
                    <InfoTableItem Label={t('shareholderDetailsInfo.postalCodeLabel')} Value={this.props.Data.ShareholderPostalCode} />

                    {this.props.Data.ShareholderOwnerType !== "Company" &&
                        <InfoTableItem Label={t('signingAuthoritiesInfo.idFrontLabel')} Value={this.props.Data.IdFrontShareholder} />}
                    {this.props.Data.ShareholderOwnerType !== "Company" &&
                        <InfoTableItem Label={t('signingAuthoritiesInfo.idBackLabel')} Value={this.props.Data.IdBackShareholder} />}
                </InfoTable>
            </div>
        );
    }
}

export default withTranslation()(ShareholderDetailsInfo);
