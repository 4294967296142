import React from 'react';
import { withTranslation } from 'react-i18next';
import InfoTable from '../../components/InfoTable/InfoTable';
import InfoTableItem from '../../components/InfoTableItem/InfoTableItem';

class SigningAuthoritiesInfo extends React.Component {
    render() {
        const { t } = this.props; // Access the translation function
        
        return (
            <div>
                <h3 className="title__minor">{t('signingAuthoritiesInfo.title')}</h3>
                
                <InfoTable>
                    <InfoTableItem Label={t('signingAuthoritiesInfo.fullNameLabel')} Value={this.props.Data.AuthorizedFullLegalName} />
                    <InfoTableItem Label={t('signingAuthoritiesInfo.dateOfBirthLabel')} Value={this.props.Data.AuthorizedDOB} />
                    <InfoTableItem Label={t('signingAuthoritiesInfo.occupationLabel')} Value={this.props.Data.AuthorizedOccupation} />
                    <InfoTableItem Label={t('signingAuthoritiesInfo.emailAddressLabel')} Value={this.props.Data.AuthorizedEmailAddress} />

                    <InfoTableItem Label={t('signingAuthoritiesInfo.addressLabel')} Value={this.props.Data.AuthorizedAddress1} />
                    <InfoTableItem Label={t('signingAuthoritiesInfo.cityLabel')} Value={this.props.Data.AuthorizedCity} />
                    <InfoTableItem Label={t('signingAuthoritiesInfo.provinceLabel')} Value={this.props.Data.AuthorizedProvince} />
                    <InfoTableItem Label={t('signingAuthoritiesInfo.countryLabel')} Value={this.props.Data.AuthorizedCountry} />
                    <InfoTableItem Label={t('signingAuthoritiesInfo.postalCodeLabel')} Value={this.props.Data.AuthorizedPostalCode} />

                    <InfoTableItem Label={t('signingAuthoritiesInfo.idFrontLabel')} Value={this.props.Data.IdFront} />
                    <InfoTableItem Label={t('signingAuthoritiesInfo.idBackLabel')} Value={this.props.Data.IdBack} />
                </InfoTable>
            </div>
        );
    }
}

export default withTranslation()(SigningAuthoritiesInfo);
