import $ from 'jquery';
var earthnode = "https://dashboard-api-dev.vopay.com/api";
if (process.env.REACT_APP_STAGE.trim(" ") === 'local') {
    earthnode = "https://2.vd.vopay.dev/api";
}
if (process.env.REACT_APP_STAGE.trim(" ") === 'prod') {
    earthnode = "https://dashboard-api.vopay.com/api";
}

export function submitExtendedInfo(options, saveOnly) {
    if (options.CustomerSupportPhoneNumber) {
        options.CustomerSupportPhoneNumber.replace(/[^\d]/g, "")
    }
    if (options.PrimaryContactPhoneNumber) {
        options.PrimaryContactPhoneNumber.replace(/[^\d]/g, "")
    }
    if (options.BillingContactPhoneNumber) {
        options.BillingContactPhoneNumber.replace(/[^\d]/g, "")
    }
    if (options.TechnicalContactPhoneNumber) {
        options.TechnicalContactPhoneNumber.replace(/[^\d]/g, "")
    }
    if (options.SupportContactPhoneNumber) {
        options.SupportContactPhoneNumber.replace(/[^\d]/g, "")
    }
    if (options.Website.indexOf("http://") !== 0 && options.Website.indexOf("https://") !== 0 && options.Website !== "") {
        options.Website = "https://" + options.Website
    }
    return new Promise((resolve, reject) => {
        let url = `${earthnode}/account/submit-extended-info`

        if (window.IsPartnerToken) {
            url = `${earthnode}/partner/submit-extended-info`
        }
        if (saveOnly) {
            url = `${earthnode}/account/submit-extended-info/save`
        }

        var validatorPattern = /^(?!.*[,]).{1,30}$/;
        $.ajax({
            url: url,
            method: "POST",
            data: {
                Token: sessionStorage.getItem("Token"),
                Iq11Token: options.Iq11Token,
                Address: options.Address.replace(/[^a-zA-Z0-9\s]+/g, ''),
                City: options.City.replace(/[^a-zA-Z0-9\s]+/g, ''),
                Province: options.Province.replace(/[^a-zA-Z0-9\s]+/g, ''),
                PostalCode: options.PostalCode,
                Country: options.Country.replace(/[^a-zA-Z0-9\s]+/g, ''),
                PhoneNumber: options.PhoneNumber.replace(/[^\d]/g, ""),
                NatureofBusiness: options.NatureofBusiness,
                LegalBusinessName: options.LegalBusinessName.replace(/[.$#/[\]]/g, '').trim(),
                OrganizationalType: options.OrganizationalType,
                RegistrationNumber: options.RegistrationNumber,
                RegistrationProvince: options.RegistrationProvince,
                DateofIncorporation: options.DateofIncorporation,
                TaxID: options.TaxID,
                Website: options.Website,
                BusinessTypeID: options.BusinessTypeID,
                ProductDescription: options.ProductDescription,
                IDVerificationConsent: true,
                ThirdPartyUse: options.ThirdPartyUse,
                BusinessTypeCategoryID: options.BusinessTypeCategoryID,
                StatementDescriptor: validatorPattern.test(options.StatementDescriptor) ? options.StatementDescriptor : "",
                StatementDescriptorShortName: options.StatementDescriptorShortName,
                CustomerSupportPhoneNumber: options.CustomerSupportPhoneNumber,
                PrimaryContactFullName: options.PrimaryContactFullName,
                PrimaryContactTitle: options.PrimaryContactTitle,
                PrimaryContactEmail: options.PrimaryContactEmail,
                PrimaryContactPhoneNumber: options.PrimaryContactPhoneNumber,
                VopayAccountID: options.VopayAccountID,
                BillingContactFullName: options.BillingContactFullName,
                BillingContactTitle: options.BillingContactTitle,
                BillingContactEmail: options.BillingContactEmail,
                BillingContactPhoneNumber: options.BillingContactPhoneNumber,
                TechnicalContactFullName: options.TechnicalContactFullName,
                TechnicalContactTitle: options.TechnicalContactTitle,
                TechnicalContactEmail: options.TechnicalContactEmail,
                TechnicalContactPhoneNumber: options.TechnicalContactPhoneNumber,
                SupportContactFullName: options.SupportContactFullName,
                SupportContactTitle: options.SupportContactTitle,
                SupportContactEmail: options.SupportContactEmail,
                SupportContactPhoneNumber: options.SupportContactPhoneNumber,
                AcceptDonations: options.AcceptDonations,
                DonationLimitNonCash: options.DonationLimitNonCash,
                AcceptDonationsCash: options.AcceptDonationsCash,
                DonationLimitCash: options.DonationLimitCash,
                DonationPercentageCash: options.DonationPercentageCash,
                DonationCashProcess: options.DonationCashProcess,
                LenderFundsSource: options.LenderFundsSource,
                LenderApr: options.LenderApr,
                AvgPaychequeAmount: options.AvgPaychequeAmount,
                NumberOfEmployees: options.NumberOfEmployees,
                PayrollFrequency: options.PayrollFrequency
            }
        })
            .done(data => {
                if (data.Success === true) {
                    resolve(data);
                } else {

                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {

                reject(err);
            });
    })
}
export function getFiles() {
    return new Promise((resolve, reject) => {
        let url = `${earthnode}/document`
        $.ajax({
            url: url,
            method: "GET",
            data: {
                Token: sessionStorage.getItem("Token"),

            }
        })
            .done(data => {
                resolve(data);
            })
            .fail(err => {

                reject(err);
            });
    })
}
export function getShareholderInfo() {
    return new Promise((resolve, reject) => {
        let url = `${earthnode}/account/submit-extended-info/shareholder-info`
        if (window.IsPartnerToken) {
            url = `${earthnode}/partner/submit-extended-info/shareholder-info`
        }
        $.ajax({
            url: url,
            method: "GET",
            data: {
                Token: sessionStorage.getItem("Token"),
            }
        })
            .done(data => {
                resolve(data);

            })
            .fail(err => {

                reject(err);
            });
    })
}
export function brandingDetials() {
    return new Promise((resolve, reject) => {
        let url = `${earthnode}/account/branding-details`
        $.ajax({
            url: url,
            method: "GET",
            data: {
                Token: sessionStorage.getItem("Token"),
                Product: 'onboarding'
            }
        })
            .done(data => {
                if (data.Success) {
                    resolve(data);
                }
                else {
                    reject(data);
                }


            })
            .fail(err => {

                reject(err);
            });
    })
}
export function submitShareholderInfo(options, isSave) {
    return new Promise((resolve, reject) => {
        let url = `${earthnode}/account/submit-extended-info/shareholder-info/add`
        if (isSave) {
            url = `${earthnode}/account/submit-extended-info/shareholder-info/save`
        }
        if (options.Id !== null && options.Id !== "") {

            if (isSave) {
                url = `${earthnode}/account/submit-extended-info/shareholder-info/save`
            }
            else {
                url = `${earthnode}/account/submit-extended-info/shareholder-info/edit`
            }
            if (window.IsPartnerToken) {
                url = `${earthnode}/partner/submit-extended-info/shareholder-info/${options.Id}`
            }
        }
        else {
            if (window.IsPartnerToken) {
                url = `${earthnode}/partner/submit-extended-info/shareholder-info`
            }
        }

        $.ajax({
            url: url,
            method: "POST",
            data: {
                Token: sessionStorage.getItem("Token"),
                ShareHolderID: options.Id,
                ShareholderFullName: options.ShareholderFullName,
                ShareholderOwnership: options.ShareholderOwnership,
                ShareholderOccupation: options.ShareholderOccupation,
                ShareholderOwnerType: options.ShareholderOwnerType,
                ShareholderDOB: options.ShareholderDOB ? options.ShareholderDOB : "",
                ShareholderAddress1: options.ShareholderAddress1.replace(/[^a-zA-Z0-9\s]+/g, ''),
                ShareholderAddress2: options.ShareholderAddress2.replace(/[^a-zA-Z0-9\s]+/g, ''),
                ShareholderCity: options.ShareholderCity.replace(/[^a-zA-Z0-9\s]+/g, ''),
                ShareholderProvince: options.ShareholderProvince.replace(/[^a-zA-Z0-9\s]+/g, ''),
                ShareholderCountry: options.ShareholderCountry.replace(/[^a-zA-Z0-9\s]+/g, ''),
                ShareholderPostalCode: options.ShareholderPostalCode.replace(/[^a-zA-Z0-9\s]+/g, ''),
            }
        })
            .done(data => {
                data.Key = options.Key;
                data.Type = "Shareholder";
                resolve(data);
            })
            .fail(err => {

                reject(err);
            });
    })
}
export function deleteShareholderInfo(options) {
    return new Promise((resolve, reject) => {
        let url = `${earthnode}/account/submit-extended-info/shareholder-info/delete`

        $.ajax({
            url: url,
            method: "POST",
            data: {
                Token: sessionStorage.getItem("Token"),
                ShareHolderID: options.ShareholderID
            }
        })
            .done(data => {
                resolve(data);

            })
            .fail(err => {

                reject(err);
            });
    })
}
export function getSigningAuthoritesInfo() {
    return new Promise((resolve, reject) => {
        let url = `${earthnode}/account/submit-extended-info/signing-authority-info`
        if (window.IsPartnerToken) {
            url = `${earthnode}/partner/submit-extended-info/shareholder-info`
        }
        $.ajax({
            url: url,
            method: "GET",
            data: {
                Token: sessionStorage.getItem("Token"),
            }
        })
            .done(data => {
                resolve(data);

            })
            .fail(err => {

                reject(err);
            });
    })
}
export function submitSigningAuthoritesInfo(options, isSave) {
    return new Promise((resolve, reject) => {
        let url = `${earthnode}/account/submit-extended-info/signing-authority-info/add`
        if (isSave) {
            url = `${earthnode}/account/submit-extended-info/signing-authority-info/save`
        }
        if (options.Id !== null && options.Id !== "") {
            if (isSave) {
                url = `${earthnode}/account/submit-extended-info/signing-authority-info/save`
            }
            else {
                url = `${earthnode}/account/submit-extended-info/signing-authority-info/edit`
            }
            if (window.IsPartnerToken) {
                url = `${earthnode}/partner/submit-extended-info/signing-authority-info/${options.Id}`
            }
        }
        else {
            if (window.IsPartnerToken) {
                url = `${earthnode}/partner/submit-extended-info/signing-authority-info`
            }
        }

        $.ajax({
            url: url,
            method: "POST",
            data: {
                Token: sessionStorage.getItem("Token"),
                AuthorizedFullLegalName: options.AuthorizedFullLegalName,
                AuthorizedOccupation: options.AuthorizedOccupation,
                AuthorizedEmailAddress: options.AuthorizedEmailAddress,
                AuthorizedDOB: options.AuthorizedDOB,
                AuthorizedAddress1: options.AuthorizedAddress1.replace(/[^a-zA-Z0-9\s]+/g, ''),
                AuthorizedAddress2: options.AuthorizedAddress2.replace(/[^a-zA-Z0-9\s]+/g, ''),
                AuthorizedCity: options.AuthorizedCity.replace(/[^a-zA-Z0-9\s]+/g, ''),
                AuthorizedProvince: options.AuthorizedProvince.replace(/[^a-zA-Z0-9\s]+/g, ''),
                AuthorizedCountry: options.AuthorizedCountry.replace(/[^a-zA-Z0-9\s]+/g, ''),
                AuthorizedPostalCode: options.AuthorizedPostalCode.replace(/[^a-zA-Z0-9\s]+/g, ''),
                SigningAuthorityID: options.Id
            }
        })
            .done(data => {
                data.Type = "SigningAuthority";
                data.Key = options.Key;
                resolve(data);

            })
            .fail(err => {

                reject(err);
            });
    })
}

export function deleteSigningAuthoritesInfo(options) {
    return new Promise((resolve, reject) => {
        let url = `${earthnode}/account/submit-extended-info/signing-authority-info/delete`

        $.ajax({
            url: url,
            method: "POST",
            data: {
                Token: sessionStorage.getItem("Token"),
                SigningAuthorityID: options.SigningAuthorityID
            }
        })
            .done(data => {
                resolve(data);

            })
            .fail(err => {

                reject(err);
            });
    })
}
export function PrintAgreement(options) {
    let url = `${earthnode}/account/print-agreement`;
    return new Promise((resolve, reject) => {
        $.ajax({
            url: url,
            method: 'POST',
            data: {
                Token: sessionStorage.getItem("Token"),
                HtmlContent: options.HtmlContent

            }
        })
            .done(data => {

                if (data.Success === true) {
                    resolve(data);
                } else {

                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {

                reject(err);
            });
    });
}
export function AcceptAgreement() {
    let url = `${earthnode}/account/accept-agreement`;
    return new Promise((resolve, reject) => {
        $.ajax({
            url: url,
            method: 'POST',
            data: {
                Token: sessionStorage.getItem("Token"),

            }
        })
            .done(data => {

                if (data.Success === true) {
                    resolve(data);
                } else {

                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {

                reject(err);
            });
    });
}

export function GetBillingPackages() {

    return new Promise((resolve, reject) => {
        let url = `${earthnode}/account/assigned-billing-package`
        if (window.IsPartnerToken) {
            url = `${earthnode}/partner/assigned-billing-package`
        }
        $.ajax({
            url: url,
            method: 'GET',
            data: {
                Token: sessionStorage.getItem("Token")
            }
        })
            .done(data => {

                if (data.Success === true) {
                    resolve(data);
                } else {

                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {

                reject(err);
            });
    })
}

export function submitFiles(options) {
    return new Promise((resolve, reject) => {
        let url = `${earthnode}/document`
        $.ajax({
            url: url,
            method: "POST",
            data: {
                Token: sessionStorage.getItem("Token"),
                DocumentName: options.DocumentName,
                DocumentContent: options.DocumentContent,
                DocumentType: options.DocumentType
            }
        })
            .done(data => {

                resolve(data);



            })
            .fail(err => {

                reject(err);
            });
    })
}
export function getTokenInfo(options) {

    return new Promise((resolve, reject) => {
        let url = `${earthnode}/portal/retrieve-single-use-token`;
        $.ajax({
            url: url,
            method: 'GET',
            data: {
                Token: sessionStorage.getItem("Token"),
                ApplicationType: options.ApplicationType
            }
        })
            .done(data => {

                if (data.Success === true) {
                    resolve(data);
                } else {

                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {

                reject(err);
            });
    })
}
export function getExtendedInfo() {

    return new Promise((resolve, reject) => {
        let url = `${earthnode}/account/submit-extended-info`;
        $.ajax({
            url: url,
            method: 'GET',
            data: {
                Token: sessionStorage.getItem("Token"),
            }
        })
            .done(data => {

                if (data.Success === true) {
                    resolve(data);
                } else {

                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {

                reject(err);
            });
    })
}

export function getBusnessTypes() {

    return new Promise((resolve, reject) => {
        let url = `${earthnode}/account/business-types`;
        $.ajax({
            url: url,
            method: 'GET',
            data: {
                Token: sessionStorage.getItem("Token"),
            }
        })
            .done(data => {
                if (data.Success === true) {
                    resolve(data);
                } else {

                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {

                reject(err);
            });
    })
}
export function generateEmbedUrl(options) {

    return new Promise((resolve, reject) => {

        let url = `${earthnode}/iq11/generate-embed-url`;
        let RedirectMethod = "JavascriptMessage";
        let Version = "v2";
        $.ajax({
            url: url,
            method: 'POST',
            data: {
                Token: sessionStorage.getItem("Token"),
                RedirectURL: window.location.href,
                Version: Version,
                PaymentSelectionMethod: options.PaymentSelectionMethod,
                RedirectMethod: RedirectMethod,
                Language: options.Language,
                WithTransactions: true,
                ForOnboarding: true,
                ClientControlled: true
            }
        })
            .done(data => {
                if (data.Success === true) {
                    resolve(data);
                } else {
                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {
                reject(err);
            });
    })
}
export function generateEmbedUrlCreditCard() {

    return new Promise((resolve, reject) => {

        let url = `${earthnode}/credit-card/generate-embed-url`;
        let RedirectMethod = "JavascriptMessage";
        $.ajax({
            url: url,
            method: 'POST',
            data: {
                Token: sessionStorage.getItem("Token"),
                RedirectURL: window.location.href,
                RedirectMethod: RedirectMethod,
                ForOnboarding: true,
                ClientControlled: true
            }
        })
            .done(data => {
                if (data.Success === true) {
                    resolve(data);
                } else {
                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {
                reject(err);
            });
    })
}
export function setDefaultPaymentMethod(options) {
    return new Promise((resolve, reject) => { // jshint ignore:line

        let url = `${earthnode}/account/set-default-payment-method`;
        $.ajax({
            url: url,
            method: 'POST',
            data: {
                Token: sessionStorage.getItem("Token"),
                PaymentMethod: options.PaymentMethod,
            }
        })
            .done(data => {
                if (data.Success === true) {

                    resolve(data);
                } else {

                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {

                reject(err);
            });
    });
}
export function setDefaultBankAccount(options) {
    let url = `${earthnode}/bank-account/set-my-bank-account`;
    return new Promise((resolve, reject) => {
        $.ajax({
            url: url,
            method: 'POST',
            data: {
                SingleUseToken: sessionStorage.getItem("Token"),
                Token: options.Iq11Token,
                Purpose: 'billing',
                SetAsDefault: true
            }
        })
            .done(data => {
                if (data.Success === true) {

                    $.ajax({
                        url: url,
                        method: 'POST',
                        data: {
                            SingleUseToken: sessionStorage.getItem("Token"),
                            Token: options.Iq11Token,
                            Purpose: 'operational',
                            SetAsDefault: true
                        }
                    })
                        .done(data => {
                            if (data.Success === true) {

                                resolve(data);
                            } else {

                                reject(data.ErrorMessage);
                            }
                        })
                        .fail(err => {

                            reject(err);

                        });

                } else {

                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {

                reject(err);
            });
    });
}
export function setDefaultCreditCard(options) {
    let url = `${earthnode}/credit-card/set-my-credit-card`;
    return new Promise((resolve, reject) => {
        $.ajax({
            url: url,
            method: 'POST',
            data: {
                Token: sessionStorage.getItem("Token"),
                CreditCardToken: options.CreditCardToken,
                SetAsDefault: true
            }
        })
            .done(data => {
                if (data.Success === true) {

                    resolve(data);
                } else {

                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {

                reject(err);
            });
    });
}
export function getDefaultPaymentMethod() {
    let url = `${earthnode}/account/default-payment-method`;
    return new Promise((resolve, reject) => {
        $.ajax({
            url: url,
            method: 'GET',
            data: {
                Token: sessionStorage.getItem("Token"),

            }
        })
            .done(data => {
                if (data.Success === true) {

                    resolve(data);
                } else {

                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {

                reject(err);
            });
    });
}
export function deleteCreditCard(options) {
    let url = `${earthnode}/credit-card/delete`;
    return new Promise((resolve, reject) => {
        $.ajax({
            url: url,
            method: 'POST',
            data: {
                Token: sessionStorage.getItem("Token"),
                CreditCardToken: options.CreditCardToken
            }
        })
            .done(data => {
                if (data.Success === true) {

                    resolve(data);
                } else {

                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {

                reject(err);
            });
    });
}
export function deleteBank(options) {
    let url = `${earthnode}/bank-account/delete`;
    return new Promise((resolve, reject) => {
        $.ajax({
            url: url,
            method: 'POST',
            data: {
                SingleUseToken: sessionStorage.getItem("Token"),
                Token: options.Iq11Token,
            }
        })
            .done(data => {
                if (data.Success === true) {

                    resolve(data);
                } else {

                    reject(data.ErrorMessage);
                }
            })
            .fail(err => {

                reject(err);
            });
    });
}