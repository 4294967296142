import React from 'react';
import Button from './../../components/Button/Button';
import Checkbox from './../../components/Checkbox/Checkbox';
import styles from './PricingContainer.module.css';
import {GetBillingPackages} from "./../../service/api";
export default class PricingContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state ={AssignedBillingPackage: {}}
        
    }
    componentWillMount(){
        let self = this;
        GetBillingPackages().then(
            data =>{
                self.setState({
                    AssignedBillingPackage: data.AssignedBillingPackage,
        
                })
            },
            error =>{
              
            });
    }
    render() {
        var BillingSubscriptions = this.state.AssignedBillingPackage.BillingSubscriptions;

        var isEftCollect = false;
        var isEFTSendEnabled = false;
        var isInteracMoneyRequestEnabled = false;
        var isInteracBulkPayoutEnabled = false;
        var isIQ11 = false;
        var isSubaccount = false; 
        var isVisaDirect = false;
        var isInboundEtransfer = false;

        var isACHWithdraw = false; 
        var isACHFund = false;
        var isCreditCard = false;
        var isVopayInstantWithdraw = false;


        var LimitItems = [];
        var FeesRow = [];
        var ServiceCheckboxes = [];
       
        if(BillingSubscriptions){
            if(BillingSubscriptions.DailyLimitAmount !== "0.00" && BillingSubscriptions.DailyLimitAmount !== null){
                LimitItems.push(<li>Maximum Daily Amount: <span className={styles.AccountLimitAmount}>${BillingSubscriptions.DailyLimitAmount}</span></li>);
            }
            else{
                LimitItems.push(<li>Maximum Daily Amount: <span className={styles.AccountLimitAmount}>N/A</span></li>);
            }

            if(BillingSubscriptions.WeeklyLimitAmount !== "0.00" && BillingSubscriptions.WeeklyLimitAmount !== null){
                LimitItems.push(<li>Maximum Weekly Amount: <span className={styles.AccountLimitAmount}>${BillingSubscriptions.WeeklyLimitAmount}</span></li>);
            }
            else{
                LimitItems.push(<li>Maximum Weekly Amount: <span className={styles.AccountLimitAmount}>N/A</span></li>);
            }
            
            if(BillingSubscriptions.MonthlyLimitAmount !== "0.00" && BillingSubscriptions.MonthlyLimitAmount !== null){
                LimitItems.push(<li>Maximum Monthly Amount: <span className={styles.AccountLimitAmount}>${BillingSubscriptions.MonthlyLimitAmount}</span></li>);
            }
            else{
                LimitItems.push(<li>Maximum Monthly Amount: <span className={styles.AccountLimitAmount}>N/A</span></li>);
            }
            if(BillingSubscriptions.MonthlyTransactionLimit !== "0" && BillingSubscriptions.MonthlyTransactionLimit !== null){
                LimitItems.push(<li>Maximum Monthly Transactions: <span className={styles.AccountLimitAmount}>{BillingSubscriptions.MonthlyTransactionLimit}</span></li>);
            }
            else{
                LimitItems.push(<li>Maximum Monthly Transactions: <span className={styles.AccountLimitAmount}>N/A</span></li>);
            }
            if(BillingSubscriptions.IQ11StandardEnabled === "1" || BillingSubscriptions.IQ11BoostEnabled === "1" || BillingSubscriptions.IQ11PlusEnabled === "1" || BillingSubscriptions.IQ11AdvantageEnabled === "1" ){
                isIQ11 = true;
               
                if(BillingSubscriptions.IQ11StandardEnabled === "1"){
                    if(BillingSubscriptions.IQ11TokenStandard !== "0.00"){
                        FeesRow.push(<tr><td>iQ11 Standard Per Transaction</td><td>${BillingSubscriptions.IQ11TokenStandard}</td><td></td><td></td> </tr>);
                    }
                    if(BillingSubscriptions.IQ11TokenPercentage && BillingSubscriptions.IQ11TokenPercentage !== "0.00"){
                       
                        FeesRow.push(<tr><td>iQ11 Standard Percentage</td><td>{BillingSubscriptions.IQ11TokenPercentage}%</td><td>{BillingSubscriptions.IQ11TokenStandardMinimumAmount}</td> <td>{BillingSubscriptions.IQ11TokenStandardMaximumAmount}</td></tr>);
                    }
                }
                if(BillingSubscriptions.IQ11BoostEnabled === "1"){
                    if(BillingSubscriptions.IQ11TokenBoost !== "0.00"){
                    FeesRow.push(<tr><td>iQ11 Boost Per Transaction</td><td>${BillingSubscriptions.IQ11TokenBoost}</td><td></td><td></td> </tr>);
                    }
                    if(BillingSubscriptions.IQ11TokenPercentage && BillingSubscriptions.IQ11TokenPercentage !== "0.00"){
                       
                        FeesRow.push(<tr><td>iQ11 Boost Percentage</td><td>{BillingSubscriptions.IQ11TokenPercentage}%</td><td>{BillingSubscriptions.IQ11TokenBoostMinimumAmount}</td><td>{BillingSubscriptions.IQ11TokenBoostMaximumAmount}</td> </tr>);
                    }
                }
                if(BillingSubscriptions.IQ11PlusEnabled === "1"){
                    if(BillingSubscriptions.IQ11TokenPlus !== "0.00"){
                    FeesRow.push(<tr><td>iQ11 Plus Per Transaction</td><td>${BillingSubscriptions.IQ11TokenPlus}</td><td></td><td></td> </tr>);
                    }
                    if(BillingSubscriptions.IQ11TokenPercentage && BillingSubscriptions.IQ11TokenPercentage !== "0.00"){
                        
                        FeesRow.push(<tr><td>iQ11 Plus Percentage</td><td>{BillingSubscriptions.IQ11TokenPercentage}%</td><td>{BillingSubscriptions.IQ11TokenPlusMinimumAmount}</td><td>{BillingSubscriptions.IQ11TokenPlusMaximumAmount}</td> </tr>);
                    }
                }
                if(BillingSubscriptions.IQ11AdvantageEnabled === "1"){
                    if(BillingSubscriptions.IQ11TokenAdvantage !== "0.00"){
                    FeesRow.push(<tr><td>iQ11 Advantage Per Transaction</td><td>${BillingSubscriptions.IQ11TokenAdvantage}</td><td></td><td></td> </tr>);
                    }
                    if(BillingSubscriptions.IQ11TokenPercentage && BillingSubscriptions.IQ11TokenPercentage !== "0.00"){
                       
                        FeesRow.push(<tr><td>iQ11 Advantage Percentage</td><td>{BillingSubscriptions.IQ11TokenPercentage}%</td><td>{BillingSubscriptions.IQ11TokenAdvantageMinimumAmount}</td><td>{BillingSubscriptions.IQ11TokenAdvantageMaximumAmount}</td> </tr>);
                    }
                }
               
                if(BillingSubscriptions.IQ11TokenFundingProcessingFee && BillingSubscriptions.IQ11TokenFundingProcessingFee !== "0.00"){
                    FeesRow.push(<tr><td>iQ11 Funding Processing Fee</td><td>{BillingSubscriptions.IQ11TokenFundingProcessingFee}%</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.IQ11TokenWithdrawProcessingFee && BillingSubscriptions.IQ11TokenWithdrawProcessingFee !== "0.00"){
                    FeesRow.push(<tr><td>iQ11 Withdraw Processing Fee</td><td>{BillingSubscriptions.IQ11TokenWithdrawProcessingFee}%</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.IQ11TokenFailedTransaction && BillingSubscriptions.IQ11TokenFailedTransaction !== "0.00"){
                    FeesRow.push(<tr><td>iQ11 Failed Transaction</td><td>${BillingSubscriptions.IQ11TokenFailedTransaction}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.IQ11TokenNSF  && BillingSubscriptions.IQ11TokenNSF !== "0.00"){
                    FeesRow.push(<tr><td>iQ11 NSF</td><td>${BillingSubscriptions.IQ11TokenNSF}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.IQ11TokenLinkedAccount && BillingSubscriptions.IQ11TokenLinkedAccount !== "0.00"){
                    FeesRow.push(<tr><td>iQ11 Link Account</td><td>${BillingSubscriptions.IQ11TokenLinkedAccount}</td><td></td><td></td> </tr>);
                }

              


                if(BillingSubscriptions.IQ11StandardEnabled === "1"){
                    if(BillingSubscriptions.IQ11ThirdPartyStandard !== "0.00"){
                    FeesRow.push(<tr><td>iQ11 Third Party Standard Per Transaction</td><td>${BillingSubscriptions.IQ11ThirdPartyStandard}</td><td></td><td></td> </tr>);
                    }
                    if(BillingSubscriptions.IQ11ThirdPartyPercentage && BillingSubscriptions.IQ11ThirdPartyPercentage !== "0.00"){
                       
                        FeesRow.push(<tr><td>iQ11 Third Party Standard Percentage</td><td>{BillingSubscriptions.IQ11ThirdPartyPercentage}%</td><td>{BillingSubscriptions.IQ11ThirdPartyStandardMinimumAmount}</td><td>{BillingSubscriptions.IQ11ThirdPartyStandardMaximumAmount}</td> </tr>);
                    }
                }
                if(BillingSubscriptions.IQ11BoostEnabled === "1"){
                    if(BillingSubscriptions.IQ11ThirdPartyBoost !== "0.00"){
                        FeesRow.push(<tr><td>iQ11 Third Party Boost Per Transaction</td><td>${BillingSubscriptions.IQ11ThirdPartyBoost}</td><td></td><td></td> </tr>);
                    }
                    if(BillingSubscriptions.IQ11ThirdPartyPercentage && BillingSubscriptions.IQ11ThirdPartyPercentage !== "0.00"){
                       
                        FeesRow.push(<tr><td>iQ11 Third Party Boost Percentage</td><td>{BillingSubscriptions.IQ11ThirdPartyPercentage}%</td><td>{BillingSubscriptions.IQ11ThirdPartyBoostMinimumAmount}</td><td>{BillingSubscriptions.IQ11ThirdPartyBoostMaximumAmount}</td> </tr>);
                    }
                }
                if(BillingSubscriptions.IQ11PlusEnabled === "1"){
                    if(BillingSubscriptions.IQ11ThirdPartyPlus !== "0.00"){
                        FeesRow.push(<tr><td>iQ11 Third Party Plus Per Transaction</td><td>${BillingSubscriptions.IQ11ThirdPartyPlus}</td><td></td><td></td> </tr>);
                    }
                    if(BillingSubscriptions.IQ11ThirdPartyPercentage && BillingSubscriptions.IQ11ThirdPartyPercentage !== "0.00"){
                        
                        FeesRow.push(<tr><td>iQ11 Third Party Plus Percentage</td><td>{BillingSubscriptions.IQ11ThirdPartyPercentage}%</td><td>{BillingSubscriptions.IQ11ThirdPartyPlusMinimumAmount}</td><td>{BillingSubscriptions.IQ11ThirdPartyPlusMaximumAmount}</td> </tr>);
                    }
                }
                if(BillingSubscriptions.IQ11AdvantageEnabled === "1"){
                    if(BillingSubscriptions.IQ11ThirdPartyAdvantage !== "0.00"){
                        FeesRow.push(<tr><td>iQ11 Third Party Advantage Per Transaction</td><td>${BillingSubscriptions.IQ11ThirdPartyAdvantage}</td><td></td><td></td> </tr>);
                    }
                    if(BillingSubscriptions.IQ11ThirdPartyPercentage && BillingSubscriptions.IQ11ThirdPartyPercentage !== "0.00"){
                       
                        FeesRow.push(<tr><td>iQ11 Third Party Advantage Percentage</td><td>{BillingSubscriptions.IQ11ThirdPartyPercentage}%</td><td>{BillingSubscriptions.IQ11ThirdPartyAdvantageMinimumAmount}</td><td>{BillingSubscriptions.IQ11ThirdPartyAdvantageMaximumAmount}</td> </tr>);
                    }
                }
                
                if(BillingSubscriptions.IQ11ThirdPartyFundingProcessingFee && BillingSubscriptions.IQ11ThirdPartyFundingProcessingFee !== "0.00"){
                    FeesRow.push(<tr><td>iQ11 Third Party Funding Processing Fee</td><td>{BillingSubscriptions.IQ11ThirdPartyFundingProcessingFee}%</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.IQ11ThirdPartyWithdrawProcessingFee && BillingSubscriptions.IQ11ThirdPartyWithdrawProcessingFee !== "0.00"){
                    FeesRow.push(<tr><td>iQ11 Third Party Withdraw Processing Fee</td><td>{BillingSubscriptions.IQ11ThirdPartyWithdrawProcessingFee}%</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.IQ11ThirdPartyFailedTransaction && BillingSubscriptions.IQ11ThirdPartyFailedTransaction !== "0.00"){
                    FeesRow.push(<tr><td>iQ11 Third Party Failed Transaction</td><td>${BillingSubscriptions.IQ11ThirdPartyFailedTransaction}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.IQ11ThirdPartyNSF && BillingSubscriptions.IQ11ThirdPartyNSF !== "0.00"){
                    FeesRow.push(<tr><td>iQ11 Third Party NSF</td><td>${BillingSubscriptions.IQ11ThirdPartyNSF}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.IQ11ThirdPartyLinkedAccount && BillingSubscriptions.IQ11ThirdPartyLinkedAccount !== "0.00"){
                    FeesRow.push(<tr><td>iQ11 Third Party Link Account</td><td>${BillingSubscriptions.IQ11ThirdPartyLinkedAccount}</td><td></td><td></td> </tr>);
                }

            }

            if(BillingSubscriptions.EFTCollectEnabled === "1" ||  BillingSubscriptions.EFTCollectEnabled === "1"){
                isEftCollect = true;
                if(BillingSubscriptions.MaxFundEFTAmount !== "0.00"){
                    LimitItems.push(<li>Maximum Fund EFT Amount Per Transaction: <span className={styles.AccountLimitAmount}>${BillingSubscriptions.MaxFundEFTAmount}</span></li>);
                }
                else{
                    LimitItems.push(<li>Maximum Fund EFT Amount Per Transaction: <span className={styles.AccountLimitAmount}>N/A</span></li>);
                }
                if(BillingSubscriptions.EFTFundingPerTransaction !== "0.00"){
                    FeesRow.push(<tr><td>EFT Funding Per Transaction</td><td>${BillingSubscriptions.EFTFundingPerTransaction}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.EFTFundingProcessingFee !== "0.00"){
                    FeesRow.push(<tr><td>EFT Funding Processing Fee</td><td>{BillingSubscriptions.EFTFundingProcessingFee}%</td><td></td><td></td> </tr>);
                }
               
                if(BillingSubscriptions.EFTFundingPercentage !== "0.00"){
                    FeesRow.push(<tr><td>EFT Funding Percentage</td><td>{BillingSubscriptions.EFTFundingPercentage}%</td><td>${BillingSubscriptions.EFTFundingMinimumAmount}</td> <td>${BillingSubscriptions.EFTFundingMaximumAmount}</td></tr>);
                }
                if(BillingSubscriptions.EFTFundingFailedTransaction !== "0.00"){
                    FeesRow.push(<tr><td>EFT Funding Failed Transaction</td><td>${BillingSubscriptions.EFTFundingFailedTransaction}</td><td></td><td></td> </tr>);
                }
            }

            if(BillingSubscriptions.EFTSendEnabled === "1" ||  BillingSubscriptions.USDEFTSendEnabled === "1"){
                isEFTSendEnabled = true;
              
                if(BillingSubscriptions.MaxWithdrawEFTAmount !== "0.00"){
                    LimitItems.push(<li>Maximum Withdraw EFT Amount Per Transaction: <span className={styles.AccountLimitAmount}>${BillingSubscriptions.MaxWithdrawEFTAmount}</span></li>);
                }
                else{
                    LimitItems.push(<li>Maximum Withdraw EFT Amount Per Transaction: <span className={styles.AccountLimitAmount}>N/A</span></li>);
                }
                if(BillingSubscriptions.EFTWithdrawPerTransaction !== "0.00"){
                    FeesRow.push(<tr><td>EFT Withdraw Per Transaction</td><td>${BillingSubscriptions.EFTWithdrawPerTransaction}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.EFTWithdrawProcessingFee !== "0.00"){
                    FeesRow.push(<tr><td>EFT Withdraw Processing Fee</td><td>{BillingSubscriptions.EFTWithdrawProcessingFee}%</td><td></td><td></td> </tr>);
                }
            
                if(BillingSubscriptions.EFTWithdrawPercentage !== "0.00"){
                    FeesRow.push(<tr><td>EFT Withdraw Percentage</td><td>{BillingSubscriptions.EFTWithdrawPercentage}%</td><td>${BillingSubscriptions.EFTWithdrawMinimumAmount}</td><td>${BillingSubscriptions.EFTWithdrawMaximumAmount}</td> </tr>);
                }
                if(BillingSubscriptions.EFTWithdrawFailedTransaction !== "0.00"){
                    FeesRow.push(<tr><td>EFT Withdraw Failed Transaction</td><td>${BillingSubscriptions.EFTWithdrawFailedTransaction}</td><td></td><td></td> </tr>);
                }
            }
            if(BillingSubscriptions.ACHFundEnabled === "1" ){
                isACHFund = true;
                if(BillingSubscriptions.ACHFundingPerTransaction !== "0.00"){
                    FeesRow.push(<tr><td>ACH Funding Per Transaction</td><td>${BillingSubscriptions.ACHFundingPerTransaction}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.ACHFundingProcessingFee !== "0.00"){
                    FeesRow.push(<tr><td>ACH Funding Processing Fee</td><td>{BillingSubscriptions.ACHFundingProcessingFee}%</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.ACHFundingPercentage !== "0.00"){
                    FeesRow.push(<tr><td>ACH Funding Percentage</td><td>{BillingSubscriptions.ACHFundingPercentage}%</td><td>${BillingSubscriptions.ACHFundingMinimumAmount}</td><td>${BillingSubscriptions.ACHFundingMaximumAmount}</td> </tr>);
                }
                if(BillingSubscriptions.ACHFundingFailedTransaction !== "0.00"){
                    FeesRow.push(<tr><td>ACH Funding Failed Transaction</td><td>${BillingSubscriptions.ACHFundingFailedTransaction}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.MaxACHFundAmount !== "0.00"){
                    LimitItems.push(<li>Maximum Fund ACH Amount Per Transaction: <span className={styles.AccountLimitAmount}>${BillingSubscriptions.MaxACHFundAmount}</span></li>);
                }
                else{
                    LimitItems.push(<li>Maximum Fund ACH Amount Per Transaction: <span className={styles.AccountLimitAmount}>N/A</span></li>);
                }
            }
            if(BillingSubscriptions.ACHWithdrawEnabled === "1" ){
                isACHWithdraw = true;
                if(BillingSubscriptions.ACHWithdrawPerTransaction !== "0.00"){
                    FeesRow.push(<tr><td>ACH Withdraw Per Transaction</td><td>${BillingSubscriptions.ACHWithdrawPerTransaction}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.ACHWithdrawProcessingFee !== "0.00"){
                    FeesRow.push(<tr><td>ACH Withdraw Processing Fee</td><td>{BillingSubscriptions.ACHWithdrawProcessingFee}%</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.ACHWithdrawPercentage !== "0.00"){
                    FeesRow.push(<tr><td>ACH Withdraw Percentage</td><td>{BillingSubscriptions.ACHWithdrawPercentage}%</td><td>${BillingSubscriptions.ACHWithdrawMinimumAmount}</td><td>${BillingSubscriptions.ACHWithdrawMaximumAmount}</td> </tr>);
                }
                if(BillingSubscriptions.ACHWithdrawFailedTransaction !== "0.00"){
                    FeesRow.push(<tr><td>ACH Withdraw Failed Transaction</td><td>${BillingSubscriptions.ACHWithdrawFailedTransaction}</td><td></td><td></td> </tr>);
                }
                
                if(BillingSubscriptions.MaxACHWithdrawAmount !== "0.00"){
                    LimitItems.push(<li>Maximum Withdraw ACH Amount Per Transaction: <span className={styles.AccountLimitAmount}>${BillingSubscriptions.MaxACHWithdrawAmount}</span></li>);
                }
                else{
                    LimitItems.push(<li>Maximum Withdraw ACH Amount Per Transaction: <span className={styles.AccountLimitAmount}>N/A</span></li>);
                }
            }

            if(BillingSubscriptions.InteracMoneyRequestEnabled === "1" ){
                isInteracMoneyRequestEnabled = true;
              
                if(BillingSubscriptions.MaxInteracMoneyAmount !== "0.00"){
                    LimitItems.push(<li>Maximum Interac Money Amount Per Transaction: <span className={styles.AccountLimitAmount}>${BillingSubscriptions.MaxInteracMoneyAmount}</span></li>);
                }
                else{
                    LimitItems.push(<li>Maximum Interac Money Amount Per Transaction: <span className={styles.AccountLimitAmount}>N/A</span></li>);
                }
                if(BillingSubscriptions.InteracMoneyRequestPerTransaction !== "0.00"){
                    FeesRow.push(<tr><td>Interac Money Request Per Transaction</td><td>${BillingSubscriptions.InteracMoneyRequestPerTransaction}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.InteracMoneyRequestProcessingFee !== "0.00"){
                    FeesRow.push(<tr><td>Interac Money Request Processing Fee</td><td>{BillingSubscriptions.InteracMoneyRequestProcessingFee}%</td><td></td><td></td> </tr>);
                }
                
                if(BillingSubscriptions.InteracMoneyRequestPercentage !== "0.00"){
                    FeesRow.push(<tr><td>Interac Money Request Percentage</td><td>{BillingSubscriptions.InteracMoneyRequestPercentage}%</td><td>${BillingSubscriptions.InteracMoneyRequestMinimumAmount}</td><td>${BillingSubscriptions.InteracMoneyRequestMaximumAmount}</td> </tr>);
                }
                if(BillingSubscriptions.InteracMoneyRequestFailedTransaction !== "0.00"){
                    FeesRow.push(<tr><td>Interac Money Request Failed Transaction</td><td>${BillingSubscriptions.InteracMoneyRequestFailedTransaction}</td><td></td><td></td> </tr>);
                }
            }

            if(BillingSubscriptions.InteracBulkPayoutEnabled === "1" ){
                isInteracBulkPayoutEnabled = true;
            
                if(BillingSubscriptions.MaxInteracBulkPayoutAmount !== "0.00"){
                    LimitItems.push(<li>Maximum Interac Bulk Payout Amount Per Transaction: <span className={styles.AccountLimitAmount}>${BillingSubscriptions.MaxInteracBulkPayoutAmount}</span></li>);
                }
                else{
                    LimitItems.push(<li>Maximum Interac Bulk Payout Amount Per Transaction: <span className={styles.AccountLimitAmount}>N/A</span></li>);
                }
                if(BillingSubscriptions.InteracBulkPayoutPerTransaction !== "0.00"){
                    FeesRow.push(<tr><td>Interac Bulk Payout Per Transaction</td><td>${BillingSubscriptions.InteracBulkPayoutPerTransaction}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.InteracBulkPayoutProcessingFee !== "0.00"){
                    FeesRow.push(<tr><td>Interac Bulk Payout Processing Fee</td><td>{BillingSubscriptions.InteracBulkPayoutProcessingFee}%</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.InteracBulkPayoutPercentage !== "0.00"){
                    FeesRow.push(<tr><td>Interac Bulk Payout Percentage</td><td>{BillingSubscriptions.InteracBulkPayoutPercentage}%</td><td>${BillingSubscriptions.InteracBulkPayoutMinimumAmount}</td><td>${BillingSubscriptions.InteracBulkPayoutMaximumAmount}</td> </tr>);
                }
                if(BillingSubscriptions.InteracBulkPayoutFailedTransaction !== "0.00"){
                    FeesRow.push(<tr><td>Interac Bulk Payout Failed Transaction</td><td>${BillingSubscriptions.InteracBulkPayoutFailedTransaction}</td><td></td><td></td> </tr>);
                }
            }

            if(BillingSubscriptions.InboundEtransferPerTransaction !== "0.00" || BillingSubscriptions.InboundEtransferProcessingFee !== "0.00" || BillingSubscriptions.InboundEtransferPercentage !== "0.00"){
                isInboundEtransfer = true;
                if(BillingSubscriptions.InboundEtransferPerTransaction && BillingSubscriptions.InboundEtransferPerTransaction !== "0.00"){
                    FeesRow.push(<tr><td>Inbound E-Transfer Per Transaction</td><td>${BillingSubscriptions.InboundEtransferPerTransaction}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.InboundEtransferProcessingFee && BillingSubscriptions.InboundEtransferProcessingFee !== "0.00"){
                    FeesRow.push(<tr><td>Inbound E-Transfer Processing Fee</td><td>{BillingSubscriptions.InboundEtransferProcessingFee}%</td><td></td><td></td> </tr>);
                }
                
                
                if(BillingSubscriptions.InboundEtransferPercentage && BillingSubscriptions.InboundEtransferPercentage !== "0.00"){
                    FeesRow.push(<tr><td>Inbound E-Transfer Percentage</td><td>{BillingSubscriptions.InboundEtransferPercentage}%</td><td>${BillingSubscriptions.InboundEtransferMinimumAmount}</td><td>${BillingSubscriptions.InboundEtransferMaximumAmount}</td> </tr>);
                }
               
               
                
            }
            if(BillingSubscriptions.RTRWithdrawEnabled === "1"){
                isVopayInstantWithdraw = true;
                if(BillingSubscriptions.MaxRTRWithdrawAmount !== "0.00"){
                    LimitItems.push(<li>Maximum VoPayInstant Withdraw Amount Per Transaction: <span className={styles.AccountLimitAmount}>${BillingSubscriptions.MaxRTRWithdrawAmount}</span></li>);
                }
                else{
                    LimitItems.push(<li>Maximum VoPayInstant Withdraw Amount Per Transaction: <span className={styles.AccountLimitAmount}>N/A</span></li>);
                }
                if(BillingSubscriptions.RTRWithdrawPerTransaction !== "0.00"){
                    FeesRow.push(<tr><td>VoPayInstant Withdraw Per Transaction</td><td>${BillingSubscriptions.RTRWithdrawPerTransaction}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.RTRWithdrawProcessingFee !== "0.00"){
                    FeesRow.push(<tr><td>VoPayInstant Withdraw Processing Fee</td><td>{BillingSubscriptions.RTRWithdrawProcessingFee}%</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.RTRWithdrawPercentage !== "0.00"){
                    FeesRow.push(<tr><td>VoPayInstant Withdraw Percentage</td><td>{BillingSubscriptions.RTRWithdrawPercentage}%</td><td>${BillingSubscriptions.RTRWithdrawMinimumAmount}</td><td>${BillingSubscriptions.RTRWithdrawMaximumAmount}</td> </tr>);
                }
                if(BillingSubscriptions.RTRWithdrawFailedTransaction !== "0.00"){
                    FeesRow.push(<tr><td>VoPayInstant Withdraw Failed Transaction</td><td>${BillingSubscriptions.RTRWithdrawFailedTransaction}</td><td></td><td></td> </tr>);
                }
            }

            if(BillingSubscriptions.VisaDirectEnabled === "1" ){
                isVisaDirect = true;
               
                if(BillingSubscriptions.MaxVisaDirectAmount !== "0.00"){
                    LimitItems.push(<li>Maximum Visa Direct Amount Per Transaction: <span className={styles.AccountLimitAmount}>${BillingSubscriptions.MaxVisaDirectAmount}</span></li>);
                }
                else{
                    LimitItems.push(<li>Maximum Visa Direct Amount Per Transaction: <span className={styles.AccountLimitAmount}>N/A</span></li>);
                }
                if(BillingSubscriptions.CardVisaDirectPerTransaction !== "0.00"){
                    FeesRow.push(<tr><td>Visa Direct Per Transaction</td><td>${BillingSubscriptions.CardVisaDirectPerTransaction}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.CardVisaDirectProcessingFee !== "0.00"){
                    FeesRow.push(<tr><td>Visa Direct Processing Fee</td><td>{BillingSubscriptions.CardVisaDirectProcessingFee}%</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.CardVisaDirectPercentage !== "0.00"){
                    FeesRow.push(<tr><td>Visa Direct Percentage</td><td>{BillingSubscriptions.CardVisaDirectPercentage}%</td><td>${BillingSubscriptions.CardVisaDirectMinimumAmount}</td><td>${BillingSubscriptions.CardVisaDirectMaximumAmount}</td> </tr>);
                }
                if(BillingSubscriptions.CardVisaDirectFailedTransaction !== "0.00"){
                    FeesRow.push(<tr><td>Visa Direct Payout Failed Transaction</td><td>${BillingSubscriptions.CardVisaDirectFailedTransaction}</td><td></td><td></td> </tr>);
                }
            
            }
            
            if(BillingSubscriptions.CreditCardEnabled === "1" ){
                isCreditCard = true;
                if(BillingSubscriptions.CreditCardPerTransaction !== "0.00"){
                    FeesRow.push(<tr><td>Credit Card Per Transaction</td><td>${BillingSubscriptions.CreditCardPerTransaction}</td><td></td><td></td> </tr>);
                }
                if(BillingSubscriptions.CreditCardProcessingFee !== "0.00"){
                    FeesRow.push(<tr><td>Credit Card Processing Fee</td><td>{BillingSubscriptions.CreditCardProcessingFee}%</td><td></td><td></td> </tr>);
                }
               
            }
            
            if(this.state.AssignedBillingPackage.SubAccountPermissions === "1"){
                isSubaccount = true;
            }
            if(BillingSubscriptions.InternationalFundingPerTransaction !== "0.00"){
                FeesRow.push(<tr><td>International Funding Per Transaction</td><td>${BillingSubscriptions.InternationalFundingPerTransaction}</td><td></td><td></td> </tr>);
            }
            if(BillingSubscriptions.InternationalFundingProcessingFee !== "0.00"){
                FeesRow.push(<tr><td>International Funding Processing Fee</td><td>{BillingSubscriptions.InternationalFundingProcessingFee}%</td><td></td><td></td> </tr>);
            }
            if(BillingSubscriptions.InternationalWithdrawPerTransaction !== "0.00"){
                FeesRow.push(<tr><td>International Withdraw Per Transaction</td><td>${BillingSubscriptions.InternationalWithdrawPerTransaction}</td><td></td><td></td> </tr>);
            }
            if(BillingSubscriptions.InternationalWithdrawProcessingFee !== "0.00"){
                FeesRow.push(<tr><td>International Withdraw Processing Fee</td><td>{BillingSubscriptions.InternationalWithdrawProcessingFee}%</td><td></td><td></td> </tr>);
            }
            if(BillingSubscriptions.OtherFundingPerTransaction !== "0.00"){
                FeesRow.push(<tr><td>Other Funding Per Transaction</td><td>${BillingSubscriptions.OtherFundingPerTransaction}</td><td></td><td></td> </tr>);
            }
            if(BillingSubscriptions.OtherFundingProcessingFee !== "0.00"){
                FeesRow.push(<tr><td>Other Funding Processing Fee</td><td>{BillingSubscriptions.OtherFundingProcessingFee}%</td><td></td><td></td> </tr>);
            }
            if(BillingSubscriptions.OtherWithdrawPerTransaction !== "0.00"){
                FeesRow.push(<tr><td>Other Withdraw Per Transaction</td><td>${BillingSubscriptions.OtherWithdrawPerTransaction}</td><td></td><td></td> </tr>);
            }
            if(BillingSubscriptions.OtherWithdrawProcessingFee !== "0.00"){
                FeesRow.push(<tr><td>Other Withdraw Processing Fee</td><td>{BillingSubscriptions.OtherWithdrawProcessingFee}%</td><td></td><td></td> </tr>);
            }
            
            if(isIQ11){
                ServiceCheckboxes.push(<li><Checkbox checked="checked" Disabled="disabled"/> iQ11 </li>);
            }
            else{
                ServiceCheckboxes.push(<li><Checkbox Disabled="disabled"/> iQ11 </li>);
            }
            if(isInteracMoneyRequestEnabled){
                ServiceCheckboxes.push(<li><Checkbox checked="checked" Disabled="disabled"/> Interac Money Request </li>);
            }
            else{
                ServiceCheckboxes.push(<li><Checkbox Disabled="disabled" /> Interac Money Request </li>);
            }
            
            if(isEftCollect){
                ServiceCheckboxes.push(<li><Checkbox checked="checked" Disabled="disabled"/> EFT Fund </li>);
            }
            else{
                ServiceCheckboxes.push(<li><Checkbox Disabled="disabled"/> EFT Fund </li>);
            }
            if(isInteracBulkPayoutEnabled){
                ServiceCheckboxes.push(<li><Checkbox checked="checked" Disabled="disabled"/> Interac Bulk Payout</li>);
            }
            else{
                ServiceCheckboxes.push(<li><Checkbox  Disabled="disabled"/> Interac Bulk Payout</li>);
            }

            if(isEFTSendEnabled){
                ServiceCheckboxes.push(<li><Checkbox checked="checked" Disabled="disabled"/> EFT Withdraw </li>);
            }
            else{
                ServiceCheckboxes.push(<li><Checkbox Disabled="disabled"/> EFT Withdraw </li>);
            }
            if(isInboundEtransfer){
                ServiceCheckboxes.push(<li><Checkbox checked="checked" Disabled="disabled"/> Inbound E-Transfer </li>);
            }
            else{
                ServiceCheckboxes.push(<li><Checkbox  Disabled="disabled"/> Inbound E-Transfer</li>);
            }

            if(isACHFund){
                ServiceCheckboxes.push(<li><Checkbox checked="checked" Disabled="disabled"/> ACH Fund </li>);
            }
            else{
                ServiceCheckboxes.push( <li><Checkbox  Disabled="disabled"/>  ACH Fund </li>);
            }
            if(isVopayInstantWithdraw){
                ServiceCheckboxes.push(<li><Checkbox checked="checked" Disabled="disabled"/> VoPayInstant Withdraw </li>);
            }
            else{
                ServiceCheckboxes.push( <li><Checkbox  Disabled="disabled"/>  VoPayInstant Withdraw </li>);
            }
            
            if(isACHWithdraw){
                ServiceCheckboxes.push(<li><Checkbox checked="checked" Disabled="disabled"/> ACH Withdraw </li>);
            }
            else{
                ServiceCheckboxes.push( <li><Checkbox  Disabled="disabled"/>  ACH Withdraw </li>);
            }
            if(isVisaDirect){
                ServiceCheckboxes.push(<li><Checkbox checked="checked" Disabled="disabled"/> Visa Direct </li>);
            }
            else{
                ServiceCheckboxes.push( <li><Checkbox  Disabled="disabled"/> Visa Direct </li>);
            }
           
            if(isSubaccount){
                ServiceCheckboxes.push(<li><Checkbox checked="checked" Disabled="disabled"/> Sub-accounts </li>);
            }
            else{
                ServiceCheckboxes.push(<li><Checkbox  Disabled="disabled"/> Sub-accounts </li>);
            }
            if(isCreditCard){
                ServiceCheckboxes.push(<li><Checkbox checked="checked" Disabled="disabled"/> Credit Card </li>);
            }
            else{
                ServiceCheckboxes.push( <li><Checkbox  Disabled="disabled"/> Credit Card </li>);
            }

            
            
        }
        return (
            <div>
                <h1 className="title">Pricing</h1>
               <h2 className={styles.PricingSubHeader}>Services Description</h2>
               <ul className={styles.ServiceCheckboxes}>
                    {ServiceCheckboxes}
               </ul>
               <h2 className={styles.PricingSubHeader}>Fees</h2>
                <table className={styles.FeesTable}>
                    <thead>
                        <tr>
                            <th>Component</th>
                            <th>Amount</th>
                            <th>Min</th>
                            <th>Max</th>
                       
                        </tr>
                    </thead>
                    <tbody>
                      {FeesRow}
                    </tbody>
                </table>
               
                {(() =>{
                    if(this.props.isPrint){
                       
                        return( <div className="newPage"></div>)
                    }
                    
                })()}
                {(() =>{
                    if(this.props.isPrint){
                       
                        return(<h2  className="title">Account Limits</h2>)
                    }
                    else{
                        return(<h2  className={styles.PricingSubHeader}>Account Limits</h2>)
                    }
                })()}
            
                <ul className={styles.AccountLimits}>
                   {LimitItems}
                   
                </ul>
                
                
                <div className="buttonBar">
                <Button title="Back" width="50Left" backgroundColor={this.props.PrimaryColor} onClickHandler={this.props.prevHandler}/>
                <Button title="Next" width="50Right" backgroundColor={this.props.PrimaryColor}  onClickHandler={this.props.nextHandler}/>
                </div>
            </div>
        );
    }
  }