import React from 'react';
import InfoTable from '../../components/InfoTable/InfoTable';
import InfoTableItem from '../../components/InfoTableItem/InfoTableItem';
import { withTranslation } from 'react-i18next';

class ContactDetailsInfo extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div>
                <h3 className="title__minor">{t('contactDetailsInfo.statementDescriptor')}</h3>
                <InfoTable>
                    <InfoTableItem Label={t('contactDetailsInfo.statementDescriptor')} Value={this.props.Data.StatementDescriptor}/>
                    <InfoTableItem Label={t('contactDetailsInfo.shortenedDescriptor')} Value={this.props.Data.StatementDescriptorShortName}/>
                </InfoTable>
                {(() => {
                    if (this.props.PartnerAccountType !== 'PayFac') {
                        return (
                            <div>
                                <h3 className="title__minor">{t('contactDetailsInfo.customerSupportPhoneNumber')}</h3>
                                <InfoTable>
                                    <InfoTableItem Label={t('contactDetailsInfo.phoneNumber')} Value={this.props.Data.CustomerSupportPhoneNumber}/>
                                </InfoTable>
                            </div>
                        );
                    }
                })()}
                <h3 className="title__minor">{t('contactDetailsInfo.primaryContact')}</h3>
                <InfoTable>
                    <InfoTableItem Label={t('contactDetailsInfo.fullName')} Value={this.props.Data.PrimaryContactFullName}/>
                    <InfoTableItem Label={t('contactDetailsInfo.title')} Value={this.props.Data.PrimaryContactTitle}/>
                    <InfoTableItem Label={t('contactDetailsInfo.emailAddress')} Value={this.props.Data.PrimaryContactEmail}/>
                    <InfoTableItem Label={t('contactDetailsInfo.phoneNumber')} Value={this.props.Data.PrimaryContactPhoneNumber}/>
                </InfoTable>
                {(() => {
                    if (this.props.PartnerAccountType !== 'PayFac' && this.props.SubAccountType !== 'member') {
                        return (
                            <div>
                                <h3 className="title__minor">{t('contactDetailsInfo.billingContact')}</h3>
                                {(() => {
                                    if (!this.props.Data.BillingContactFullName) {
                                        return (
                                            <InfoTable>
                                                <InfoTableItem Label={t('contactDetailsInfo.fullName')} Value={this.props.Data.PrimaryContactFullName}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.title')} Value={this.props.Data.PrimaryContactTitle}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.emailAddress')} Value={this.props.Data.PrimaryContactEmail}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.phoneNumber')} Value={this.props.Data.PrimaryContactPhoneNumber}/>
                                            </InfoTable>
                                        );
                                    } else {
                                        return (
                                            <InfoTable>
                                                <InfoTableItem Label={t('contactDetailsInfo.fullName')} Value={this.props.Data.BillingContactFullName}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.title')} Value={this.props.Data.BillingContactTitle}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.emailAddress')} Value={this.props.Data.BillingContactEmail}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.phoneNumber')} Value={this.props.Data.BillingContactPhoneNumber}/>
                                            </InfoTable>
                                        );
                                    }
                                })()}
                                <h3 className="title__minor">{t('contactDetailsInfo.technicalContact')}</h3>
                                {(() => {
                                    if (!this.props.Data.TechnicalContactFullName) {
                                        return (
                                            <InfoTable>
                                                <InfoTableItem Label={t('contactDetailsInfo.fullName')} Value={this.props.Data.PrimaryContactFullName}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.title')} Value={this.props.Data.PrimaryContactTitle}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.emailAddress')} Value={this.props.Data.PrimaryContactEmail}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.phoneNumber')} Value={this.props.Data.PrimaryContactPhoneNumber}/>
                                            </InfoTable>
                                        );
                                    } else {
                                        return (
                                            <InfoTable>
                                                <InfoTableItem Label={t('contactDetailsInfo.fullName')} Value={this.props.Data.TechnicalContactFullName}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.title')} Value={this.props.Data.TechnicalContactTitle}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.emailAddress')} Value={this.props.Data.TechnicalContactEmail}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.phoneNumber')} Value={this.props.Data.TechnicalContactPhoneNumber}/>
                                            </InfoTable>
                                        );
                                    }
                                })()}
                                <h3 className="title__minor">{t('contactDetailsInfo.supportContact')}</h3>
                                {(() => {
                                    if (!this.props.Data.SupportContactFullName) {
                                        return (
                                            <InfoTable>
                                                <InfoTableItem Label={t('contactDetailsInfo.fullName')} Value={this.props.Data.PrimaryContactFullName}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.title')} Value={this.props.Data.PrimaryContactTitle}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.emailAddress')} Value={this.props.Data.PrimaryContactEmail}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.phoneNumber')} Value={this.props.Data.PrimaryContactPhoneNumber}/>
                                            </InfoTable>
                                        );
                                    } else {
                                        return (
                                            <InfoTable>
                                                <InfoTableItem Label={t('contactDetailsInfo.fullName')} Value={this.props.Data.SupportContactFullName}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.title')} Value={this.props.Data.SupportContactTitle}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.emailAddress')} Value={this.props.Data.SupportContactEmail}/>
                                                <InfoTableItem Label={t('contactDetailsInfo.phoneNumber')} Value={this.props.Data.SupportContactPhoneNumber}/>
                                            </InfoTable>
                                        );
                                    }
                                })()}
                            </div>
                        );
                    }
                })()}
            </div>
        );
    }
}

export default withTranslation()(ContactDetailsInfo);
