import React from 'react';
import { withTranslation } from 'react-i18next';
import InfoTable from '../../components/InfoTable/InfoTable';
import InfoTableItem from '../../components/InfoTableItem/InfoTableItem';
import { translateText } from '../../service/translation';
class BusinessDetailsInfo extends React.Component {
    capitalize(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        else {
            return "";
        }
    }
    render() {
        const { t, i18n } = this.props;
        return (
            <InfoTable>
                <InfoTableItem Label={t('businessDetailsInfo.legalBusinessName')} Value={this.props.Data.LegalBusinessName} />
                <InfoTableItem Label={t('businessDetailsInfo.businessRegistrationProvince')} Value={this.props.Data.RegistrationProvince} />
                <InfoTableItem Label={t('businessDetailsInfo.businessRegistrationNumber')} Value={this.props.Data.RegistrationNumber} />
                <InfoTableItem Label={t('businessDetailsInfo.dateOfIncorporation')} Value={this.props.Data.DateofIncorporation} />
                <InfoTableItem Label={t('businessDetailsInfo.taxID')} Value={this.props.Data.TaxID} />

                <InfoTableItem Label={t('businessDetailsInfo.businessType')} Value={translateText(this.props.Data.OrganizationalTypeName, i18n.language)} />
                <InfoTableItem Label={t('businessDetailsInfo.industry')} Value={translateText(this.props.Data.IndustryName, i18n.language)} />
                <InfoTableItem Label={t('businessDetailsInfo.industrySubCategory')} Value={translateText(this.props.Data.SubIndustryName, i18n.language)} />
                <InfoTableItem Label={t('businessDetailsInfo.businessPhoneNumber')} Value={this.props.Data.PhoneNumber} />
                <InfoTableItem Label={t('businessDetailsInfo.businessWebsite')} Value={this.props.Data.Website} />
                <InfoTableItem Label={t('businessDetailsInfo.productDescription')} Value={this.props.Data.ProductDescription} />

                {this.props.IsFinancials && <InfoTableItem Label={t('businessDetailsInfo.averagePaychequeAmount')} Value={this.props.Data.AvgPaychequeAmount} />}
                {this.props.IsFinancials && <InfoTableItem Label={t('businessDetailsInfo.numberOfEmployees')} Value={this.props.Data.NumberOfEmployees} />}
                {this.props.IsFinancials && <InfoTableItem Label={t('businessDetailsInfo.payrollFrequency')} Value={translateText(this.capitalize(this.props.Data.PayrollFrequency), i18n.language)} />}

                <InfoTableItem Label={t('businessDetailsInfo.registeredBusinessAddress')} Value={this.props.Data.Address} />
                <InfoTableItem Label={t('businessDetailsInfo.registeredBusinessCity')} Value={this.props.Data.City} />
                <InfoTableItem Label={t('businessDetailsInfo.registeredBusinessProvince')} Value={this.props.Data.Province} />
                <InfoTableItem Label={t('businessDetailsInfo.registeredBusinessPostalCode')} Value={this.props.Data.PostalCode} />
                <InfoTableItem Label={t('businessDetailsInfo.registeredBusinessCountry')} Value="Canada" />
                <InfoTableItem Label={t('businessDetailsInfo.constitution')} Value={this.props.Data.Constitution} />
                <InfoTableItem Label={t('businessDetailsInfo.byLaws')} Value={this.props.Data.ByLaws} />
                <InfoTableItem Label={t('businessDetailsInfo.certificateOfIncorporation')} Value={this.props.Data.CertificateOfIncorporation} />
                <InfoTableItem Label={t('businessDetailsInfo.registerCentralSecurities')} Value={this.props.Data.RegisterCentralSecurities} />
                <InfoTableItem Label={t('businessDetailsInfo.articlesOfMemorandum')} Value={this.props.Data.ArticlesOfMemrandum} />
                <InfoTableItem Label={t('businessDetailsInfo.pd7a')} Value={this.props.Data.PD7A} />
            </InfoTable>
        );
    }
}

export default withTranslation()(BusinessDetailsInfo);
