import React from 'react';
import styles from './Button.module.css';
export default class Button extends React.Component {
    getButtonStyle(){
      if(this.props.buttonStyle === "round"){
        return styles.buttonStyleRound
      }
      return "";
    }
    getButtonColor(){
      if(this.props.buttonColor === "white"){
        return styles.buttonColorWhite
      }
      return "";
    }
    getContainerMethod(){
      if(this.props.containerDisplay === "inline"){
        return styles.inlineContainer
      }
      else if(this.props.containerDisplay === "minor"){
        return styles.minorContainer
      }
      return ""
    }
    getLuminance(color) {
      // Remove "#" if present
      color = color.replace("#", "");
  
      // Convert color to RGB
      const r = parseInt(color.substr(0, 2), 16);
      const g = parseInt(color.substr(2, 2), 16);
      const b = parseInt(color.substr(4, 2), 16);
  
      // Calculate relative luminance
      const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
  
      return luminance;
    }
  
    getContrastColor(background) {
      const luminance = this.getLuminance(background);
      return luminance > 0.5 ? "#000000" : "#ffffff"; // Use black or white depending on luminance threshold
    }
    render() {
      var stylesNew = ""
      if(this.props.width ==="50Left"){
        stylesNew = `${styles.ButtonContainer} ${styles.Left50}`;
      }
      else if(this.props.width ==="Button100"){
        stylesNew = `${styles.ButtonContainer} ${styles.Button100}`;
      }
      else if(this.props.width ==="50Right"){
        stylesNew = `${styles.ButtonContainer} ${styles.Right50}`;
      }
      else{
        stylesNew = `${styles.ButtonContainer}`;
      }
      return (
        <div className={[stylesNew, this.getContainerMethod()].join(' ')}>
          <button style={
            this.props.backgroundColor ? {
              backgroundColor: this.props.backgroundColor,
              border: `${this.props.backgroundColor} 1px solid`,
              color: this.getContrastColor(this.props.backgroundColor)
            }: {}
            } className={[styles.button, this.getButtonStyle(), this.getButtonColor()].join(' ')} 
            onClick={e => this.props.onClickHandler(e)}
            type='button'
            >{this.props.title}</button>
        </div>

      );
    }
  }