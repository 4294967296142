import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
var env = "Test";
if (process.env.REACT_APP_STAGE.trim(" ") === 'local') {
    env = "LocalDev";
}
if (process.env.REACT_APP_STAGE.trim(" ") === 'prod') {
    env= "Production";
}
export function initialize(){
    datadogLogs.init({
        clientToken: "pubde9158ac235c89ec82be4f391f7ac2a4",
        site: "datadoghq.com",
        env: env,
        forwardErrorsToLogs: true,
        silentMultipleInit: true,
        service: "account-onboarding-form",
        sampleRate: 100,
    });
    datadogRum.init({
        applicationId:"ff16bf4d-891b-46fb-a6f4-a3f6305a2cc3",
        clientToken: "pubde9158ac235c89ec82be4f391f7ac2a4",
        site: "datadoghq.com",
        env: env,
        service: "account-onboarding-form",
        trackResources: true,
        trackLongTasks: true,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100, // if not included, the default is 100
        silentMultipleInit: true,
        sampleRate: 100,
    });
    datadogRum.startSessionReplayRecording();
}
export function  setUser(user){
    datadogLogs.setUser(user);
    datadogRum.setUser(user);
}
export function clearUser(){
    datadogLogs.clearUser();
    datadogRum.clearUser();
}
export function getUser(){
    return datadogLogs.getUser();
}