import React from 'react';
import { withTranslation } from 'react-i18next';
import InfoTable from '../../components/InfoTable/InfoTable';
import InfoTableItem from '../../components/InfoTableItem/InfoTableItem';

class DonationsInfo extends React.Component {
  render() {
    const { t, Data } = this.props; // withTranslation hoc injected the t function
    console.log(t)
    return (
      <InfoTable>
        <InfoTableItem Label={t('donationsInfo.acceptDonationsLabel')} Value={Data.AcceptDonations === "true" ? t('donationsInfo.yes') : t('donationsInfo.no')} />
        <InfoTableItem Label={t('donationsInfo.nonCashDonationLimitLabel')} Value={Data.DonationLimitNonCash} />
        {Data.AcceptDonations === "true" && <InfoTableItem Label={t('donationsInfo.acceptCashDonationsLabel')} Value={Data.AcceptDonationsCash === "true" ? t('donationsInfo.yes') : t('donationsInfo.no')} />}
        <InfoTableItem Label={t('donationsInfo.cashDonationLimitLabel')} Value={Data.DonationLimitCash} />
        <InfoTableItem Label={t('donationsInfo.cashDonationPercentageLabel')} Value={Data.DonationPercentageCash} />
        <InfoTableItem Label={t('donationsInfo.cashDonationSourceLabel')} Value={Data.DonationCashProcess} />
      </InfoTable>
    );
  }
}

export default withTranslation()(DonationsInfo);
