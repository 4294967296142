import React from 'react';
import { withTranslation } from 'react-i18next';
import BankingDetailsInfo from '../BankingDetailsInfo/BankingDetailsInfo';
import BusinessDetailsInfo from '../BusinessDetailsInfo/BusinessDetailsInfo';
import ShareholderDetailsInfo from '../ShareholderDetailsInfo/ShareholderDetailsInfo';
import SigningAuthoritiesInfo from '../SigningAuthoritiesInfo/SigningAuthoritiesInfo';
import ContactDetailsInfo from '../ContactDetailsInfo/ContactDetailsInfo';
import DonationsInfo from '../DonationsInfo/DonationsInfo';
import LenderInfo from '../LenderInfo/LenderInfo';

import Button from '../../components/Button/Button';

class ConfermationContainer extends React.Component {
  render() {
    var SigningAuthorities = [];
    var Shareholders = [];

    for (var i2 = 0; i2 < this.props.Shareholders.length; i2++) {
      var holder = this.props.Shareholders[i2];
      Shareholders.push(<ShareholderDetailsInfo Data={holder} />);
    }
    for (var i = 0; i < this.props.SigningAuthorities.length; i++) {
      var holder2 = this.props.SigningAuthorities[i];
      SigningAuthorities.push(<SigningAuthoritiesInfo Data={holder2} />);
    }

    const { t } = this.props; // Translation function

    return (
      <div>
        <h2 className="title">{t('confermationContainer.review')}</h2>
        <h2 className="title__secondary">{t('confermationContainer.businessDetails')}</h2>
        <BusinessDetailsInfo
          IsFinancials={this.props.IsFinancials}
          Data={this.props.BusinessDetails}
        />
        {(() => {
          if (Object.keys(this.props.LenderInformation).length > 0) {
            return (
              <div>
                <h2 className="title__secondary">{t('confermationContainer.lenderInformation')}</h2>
                <LenderInfo Data={this.props.LenderInformation} />
              </div>
            );
          }
        })()}
        {(() => {
          if (Object.keys(this.props.DonationsDetails).length > 0) {
            return (
              <div>
                <h2 className="title__secondary">{t('confermationContainer.antiMoneyLaundering')}</h2>
                <DonationsInfo Data={this.props.DonationsDetails} />
              </div>
            );
          }
        })()}
        {(() => {
          if (Shareholders.length > 0) {
            return (
              <div>
                <h2 className="title__secondary">{t('confermationContainer.shareholderDetails')}</h2>
                {Shareholders}
              </div>
            );
          }
        })()}

        <h2 className="title__secondary">{t('confermationContainer.signingAuthoritiesDetails')}</h2>
        {SigningAuthorities}
        <h2 className="title__secondary">{t('confermationContainer.contactDetails')}</h2>
        <ContactDetailsInfo
          Data={this.props.ContactDetails}
          PartnerAccountType={this.props.PartnerAccountType}
          SubAccountType={this.props.SubAccountType}
        />
        {(() => {
          return (
            <div>
              <h2 className="title__secondary">{t('confermationContainer.bankAccountBillingDetails')}</h2>
              <BankingDetailsInfo Data={this.props.BankingDetails} />
            </div>
          );
        })()}
        {(() => {
          if (this.props.SubAccountType === 'member') {
            return (
              <div>
                <h2 className="title__secondary">{t('confermationContainer.termsOfService')}</h2>
                <p>
                  {t('confermationContainer.termsOfServiceText')}{' '}
                  <a href="https://vopay.com/ca/legal/tos/" rel="noreferrer" target="_blank">
                    {t('confermationContainer.termsOfServiceLink')}
                  </a>
                </p>
              </div>
            );
          }
        })()}
        <div className="buttonBar">
          <Button
            title={t('confermationContainer.back')}
            width="50Left"
            backgroundColor={this.props.PrimaryColor}
            onClickHandler={this.props.prevHandler}
          />
          <Button
            title={t('confermationContainer.submit')}
            width="50Right"
            backgroundColor={this.props.PrimaryColor}
            onClickHandler={this.props.nextHandler}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(ConfermationContainer);
